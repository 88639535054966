import React, { useState } from 'react'
import Header from './shared/Header';
import Spinner from './shared/Spinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage'
import { db, storage } from '../utils/firebase';
import { doc, updateDoc } from "firebase/firestore";
import Template from './shared/Template';
import ViewDocuments from './ViewDocuments';
import jsPDF from 'jspdf';
import Logo from '../img/Coat_of_arms_of_Ghana.svg.png'
// import Taskbar from './shared/Taskbar';
import Footer from './shared/Footer';
import { BsPencilSquare } from 'react-icons/bs'
import { MdArrowBack, MdEmail } from 'react-icons/md'
import { GiFiles } from 'react-icons/gi'
import { RiUploadCloudFill } from 'react-icons/ri';
import { AiOutlineComment } from 'react-icons/ai'
import Rodal from 'rodal';
import _ from 'lodash';
import { renderToString } from "react-dom/server";
import 'rodal/lib/rodal.css';
import emailjs from '@emailjs/browser';

let globalLocation;

function ViewRefusal(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const [showSpinner, setShowSpinner] = useState(false)
    const [showDocuments, setShowDocuments] = useState(false)
    const [showLetterRodal, setShowLetterRodal] = useState(false)
    const [denialReason, setDenialReason] = useState('')
    const [showMessageRodal, setShowMessageRodal] = useState(true)
    globalLocation = location;


    const imageLinks = JSON.parse(location.state.selected.data.Link_To_Files)
    // console.log(imageLinks)

    return (
        <div className='viewaccepted'>

            <Spinner showSpinner={showSpinner} />
            <MessageRodal
                visible={showMessageRodal}
                setVisible={setShowMessageRodal}
                headerText='OFFICER REMARKS'
                message={location.state.selected.data.Refused_Notes}

            />
            <LetterRodal
                visible={showLetterRodal}
                setVisible={setShowLetterRodal}
                denialReason={denialReason}
                setDenialReason={setDenialReason}
                showSpinner={showSpinner}
                setShowSpinner={setShowSpinner}

            />
            <Header officerData={location.state.officerData} />

            <div className='viewaccepted__mainview' style={showSpinner ? { display: 'none', width: '0' } : {}}>
                <div className="viewaccepted__mainview__template">
                    <Template location={location.state.selected} showSpinner={showSpinner} />
                </div>
                <div className="viewaccepted__mainview__taskbar">
                    <Taskbar
                        showDocuments={showDocuments}
                        setShowDocuments={setShowDocuments}
                        showSpinner={showSpinner}
                        setShowSpinner={setShowSpinner}
                        setVisible={setShowLetterRodal}
                        visible={showLetterRodal}
                        setMessageVisible={setShowMessageRodal}

                    />
                </div>
            </div>

            {showDocuments && !showSpinner && <ViewDocuments fileSources={imageLinks} />}
            <Footer />

        </div>
    )
}





const LetterRodal = (props) => {
    const location = useLocation();
    const navigate = useNavigate()
    return (
        <Rodal visible={props.visible} onClose={() => props.setVisible(false)} width={500} height={350}>
            <div className="visaform">

                <h2 className='visaform__header'> State Refusal Reason</h2>

                <div className="visaform__form">

                    <textarea
                        name="note"
                        id="note"
                        cols="30"
                        rows="10"
                        className="visaform__form__input"
                        value={props.denialReason}
                        onChange={(e) => props.setDenialReason(e.target.value)}
                    />

                </div>

                <div className="visaform__btnbox">

                    <button
                        className='visaform__btn'
                        onClick={() => handleSendDenialLetter(location, navigate, props)}
                    >
                        Send & Archive
                    </button>
                </div>
            </div>
        </Rodal>
    )
}

const Taskbar = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className='taskbar'>


            <div
                className="taskbar__item__box"
                onClick={() => props.setVisible(true)}


            >
                <BsPencilSquare className='taskbar__item__icon' color='white' />
                <span>Send Refusal Letter</span>
            </div>

            <div
                className="taskbar__item__box"
                onClick={() => props.setMessageVisible(true)}
            >
                <AiOutlineComment className='taskbar__item__icon' color='white' />
                <span>View Remarks</span>
            </div>


            <div
                className="taskbar__item__box"
                onClick={() => props.setShowDocuments(!props.showDocuments)}

            >
                <GiFiles className='taskbar__item__icon' color='white' />
                <span>{props.showDocuments === true ? 'Hide Documents' : 'Show Documents'}</span>
            </div>

            <div
                className="taskbar__item__box"
                onClick={() => window.open(`mailto:${location.state.selected.data.Email}`)}
            >
                <MdEmail className='taskbar__item__icon' color='white' />
                <span>Send Email</span>
            </div>

            <div
                className="taskbar__item__box"
                onClick={() => navigate(-1)}
            >
                <MdArrowBack className='taskbar__item__icon' color='white' />
                <span>Back</span>
            </div>

        </div>
    )
}


const MessageRodal = (props) => {
    const location = useLocation();
    const navigate = useNavigate()
    return (
        <Rodal visible={props.visible} onClose={() => props.setVisible(false)} width={500} height={350}>
            <div className="visaform">

                <h2 className='visaform__header'> {props.headerText}</h2>

                <div className="visaform__form">

                    <textarea
                        name="note"
                        id="note"
                        cols="30"
                        rows="10"
                        className="visaform__form__input"
                        value={props.message}
                        disabled
                    // onChange={(e) => props.setDenialReason(e.target.value)}
                    />

                </div>

                <div className="visaform__btnbox">

                    <button
                        className='visaform__btn'
                        onClick={() => props.setVisible(false)}
                    >
                        Close
                    </button>
                </div>
            </div>
        </Rodal>
    )
}


// This is the main handler when admin clicks send & archive
const handleSendDenialLetter = (location, navigate, allProps) => {
    if (allProps.denial === '') {
        alert('Please Complete the form before submitting')
    } else {
        allProps.setVisible(false)
        allProps.setShowSpinner(true)

        exportPDF(location, navigate, allProps)
        // // console.log(denial)

    }
}

const exportPDF = (location, navigate, allProps) => {

    const today = new Date();

    const DenialLetterTemplate = (

        <div className='denial'>


            <div className="denial__header">
                <div className="denial__header__logobox">
                    <img src={Logo} alt="Logo of Us" className="denial__header__logo" />
                </div>
                <div className="denial__header__titlebox">
                    <h2 className='denial__header__title'>Hon Consulate Of Bermuda</h2>
                </div>
            </div>

            <div className="denial__date">
                {today.toDateString()}
            </div>

            <div className="denial__salute">


                {`Dear ${location.state.selected.data.Firstnames} ${location.state.selected.data.Surname},`}
            </div>

            <div className="denial__intro">
                Based on the Documents you have submitted to us, and the information elicited from your application online, you do not appear to be qualified
                for a visa to Ghana.
            </div>

            <div className='denial__body'>
                {allProps.denialReason}
            </div>

            <div className="denial__closure">
                Thank you
            </div>
        </div>

    )

    const doc = new jsPDF("p", "pt", "a4");

    doc.setFont('Helvetica')
    try {

        // console.log('Inside try catch')
        doc.html(renderToString(DenialLetterTemplate), {

            callback: function (doc) {

                // console.log('Doc done converting')
                doc.save(`test.pdf`);
                // SAVE THIS ON THE FIREBASE SERVER BEFORE YOU ADD AS ATTACHMENT
                const blob = doc.output('blob');

                // Upload blob file to Firebase storage.
                uploadPDF(blob, location, navigate, allProps);
            }

        }
        )
    } catch (err) {
        // console.log('Error from ReactDomRenderer')
        // console.log(err)
    }
}



const uploadPDF = async (blobFile, location, navigate, allProps) => {
    const storageRef = ref(storage, `refusal/${location.state.selected.data.ID}.pdf`)
    const uploadTask = uploadBytesResumable(storageRef, blobFile);

    uploadTask.on(
        'state_changed', (snapshot) => {
            const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );

            // console.log(percent)
        }, (error) => // console.log(error),
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                // console.log(url)
                sendMail(location, navigate, allProps, url)

                /*
                try {


                    window.Email.send({
                        Host: "smtp.elasticemail.com",

                        Username: "fariescovington@gmail.com",
                        Password: "4BF2549F1B35E01ADBCDF9D6C313387BDA85",
                        To: location.state.selected.data.Email, // Use the email entered by user
                        From: "fariescovington@gmail.com",
                        Subject: "Application for Ghana Visa",

                        Attachments: [{
                            name: `${location.state.selected.data.ID}-review.pdf`,
                            path: url,
                        }],

                        Body: `<div>
                        Dear  ${location.state.selected.data.Firstnames} ${location.state.selected.data.Surname}<br /> <br />
                        
                        <br /><br />
                        
                        <font color="red">
                        Please findd attached the review of your visa application
                        </font>
                        <br />
                        
                        </div>
                      `
                    }).then(message => {
                        if (message.toLowerCase() === 'ok') {

                            // const deleteRef = ref(storageRef)
                            deleteObject(storageRef).then(() => {
                                // console.log('File deleted on server')
                                handleStatusChange(location, navigate, allProps)


                                alert('Email Sent Successfully')

                            }).catch((err) => {
                                // console.log('File NOT deleted')
                                allProps.setShowSpinner(false)
                                // console.log(err)

                            })


                        } else {
                            alert(message)
                            allProps.setShowSpinner(false)

                        }

                    });

                } catch (e) {
                    // console.log(e)

                }
                */

            })
        }
    )
}


const sendMail = (location, navigate, allProps, url) => {

    const mailParams = {
        to_email: location.state.selected.data.Email,
        to_name: `${location.state.selected.data.Firstnames} ${location.state.selected.data.Surname}`,
        to_code: ` Please click the link to view the review of your visa application ${url}`,
    }

    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, 'template_wttk045', mailParams, process.env.REACT_APP_EMAILJS_USER_ID).then(response => {
        // console.log(result.text);
        handleStatusChange(location, navigate, allProps, url)

    }, (error) => {
        alert(error.text)
        // console.log(error.text);
    });

}


const handleStatusChange = (location, navigate, allProps, url) => {

    const docRef = doc(db, 'applications', location.state.selected.id)

    let data;
    data = {
        STATUS: 5,
        Denial_Reason: allProps.denialReason,
        Refusal_Letter_Link: url,
    }


    updateDoc(docRef, data).then(() => {
        updateLocalDb(location, navigate, allProps, data)

        // console.log('done')

    }).catch((err) => {
        // console.log(err)
    })
}

const updateLocalDb = (location, navigate, allProps, data) => {

    // TAKE OUT APPROVED APPLICATION FROM  LIST OF APPROVED APPLICATIONS THEN PASS IT BACK TO THE FULL APPLICATION PAGE TO GET UPDATED LIST
    const newList = location.state.userList.filter(x => x.id !== location.state.selected.id)
    const allApplications = location.state.fullList;

    const selectedIndex = location.state.fullList.findIndex(x => x.id === location.state.selected.id)
    // console.log('ID Here ' + selectedIndex)
    // console.log(location.state.fullList[selectedIndex])

    const modifiedApplication = {
        id: location.state.fullList[selectedIndex].id,
        data: {
            ...location.state.fullList[selectedIndex].data,
            ...data
        }
    }
    // console.log(modifiedApplication)
    allApplications.splice(selectedIndex, 1, modifiedApplication)
    // console.log(allApplications)

    allProps.setShowSpinner(false);
    allProps.setVisible(false)
    alert('Email Sent')
    navigate('/refusal', {
        state: {
            userList: newList,
            fullList: allApplications,
            allFees: location.state.allFees,
            officerData: location.state.officerData,

            allOfficers: location.state.allOfficers,
            updateID: location.state.updateID,

            showNotif: location.state.showNotif,
            notifBody: location.state.notifBody,
        }
    })
}


export default ViewRefusal;