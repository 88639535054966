import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SimpleImageSlider from "react-simple-image-slider";
import { BrowserView, MobileView } from 'react-device-detect';

import user from '../img/user.png';
import pword from '../img/padlock.png';
import { db } from '../utils/firebase';
import { collection, getDocs } from "firebase/firestore";
import Spinner from './shared/Spinner';
import Back1 from '../img/Coat_of_arms_of_Ghana.svg.png';

// const imgList = [slide1, slide2, slide3, slide4, slide5, slide6, slide7, slide8]
let userList = [];
let utils = [];

const login = async (username, pword, setShowSpinner, navigate) => {

    setShowSpinner(true)

    userList = [];
    utils = []


    const feesQuerySnaphot = await getDocs(collection(db, 'utils'));
    feesQuerySnaphot.forEach((doc) => {
        // console.log(doc.id, " => ", doc.data());
        utils.push({ id: doc.id, data: doc.data() })
    })
    // console.log('UTILSS')
    // console.log(utils)
    const allOfficers = JSON.parse(utils[0].data.ALL_OFFICERS);

    let myData = utils[0].data
    
    // console.log(myData)

    // console.log(allOfficers)
    const OfficerIndex = allOfficers.findIndex(x => x.username === username && x.password === pword)

    if (OfficerIndex > -1) {
        // SET LOGGED-IN OFFICER DETAILS
        const officer = allOfficers[OfficerIndex]
        // console.log(officer)


        const applicationsQuerySnapshot = await getDocs(collection(db, "applications"));
        applicationsQuerySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            userList.push({ id: doc.id, data: doc.data() })
        });
        // console.log('We move')
        localStorage.setItem('LOGGED_IN', '1')

        const filteredData = userList.filter(x => x.data.STATUS === 0)
        // console.log('received applications array')
        // console.log(filteredData)
        setShowSpinner(false)
        navigate('/received',
            {
                state: {
                    fullList: userList,
                    userList: filteredData,
                    allFees: utils[0].data.ALL_FEES,
                    allOfficers: utils[0].data.ALL_OFFICERS,
                    updateID: utils[0].id,
                    officerData: allOfficers[OfficerIndex],
                    
                    showNotif: utils[0].data.SHOW_NOTIF,
                    notifBody: utils[0].data.APP_NOTIF,
                }
            }
        )
        // navigate('/task', { state: { userList: userList, feesList: utils[0].data.ALL_FEES, officersList: utils[0].data.ALL_OFFICERS, updateID: utils[0].id } })

    } else {
        // console.log('Hello Hello')
        setTimeout(() => {

            setShowSpinner(false)
            alert('Wrong Credentials')

        }, 1000);
    }

}


export default function Landing() {
    const navigate = useNavigate()
    // const [username, setUsername] = useState('fhella9999')
    // const [password, setPassword] = useState("admin@1234")

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')


    const [showSpinner, setShowSpinner] = useState(false)
    return (
        <div>
            <MobileView>
                <div style={{ fontSize: '2.78rem', display: 'flex', justifyContent: 'center', alignItems: 'center', height: window.innerHeight }}>
                    Please View the test pages on Laptop. Thank you
                </div>
            </MobileView>
            <BrowserView>
                <div className='landing'>

                    <div className="landing__slider">
                        {/*

                        <SimpleImageSlider
                            width={window.innerWidth * 0.5}
                            height={window.innerHeight}
                            images={imgList}
                            showBullets={true}
                            showNavs={true}
                            autoPlay
                        />

                        */}

                        <img src={Back1} className="landing__slider__img" alt="Ghana Coat Of Arms"/>
                        
                    </div>


                    <div className="landing__card">
                        <Spinner showSpinner={showSpinner} />
                        <div className="landing__cardbox" style={showSpinner ? { width: 0, display: 'none' } : {}}>

                            <p className='landing__card__title'>Welcome to Visa Administration Page</p>
                            <div className="landing__input">
                                <input
                                    type="text"
                                    placeholder='Username'
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    id="username"
                                    name="username"

                                />
                                <img src={user} alt="icon" className="landing__input__icon" />
                            </div>

                            <div className="landing__input">
                                <input
                                    type="password"
                                    placeholder='Password'
                                    id="password"
                                    name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <img src={pword} alt="icon" className="landing__input__icon" />
                            </div>

                            <div className="landing__btn">
                                <button onClick={() => login(username, password, setShowSpinner, navigate)}>Sign in</button>
                            </div>
                        </div>
                    </div>
                </div>
            </BrowserView>
        </div>
    )
}
