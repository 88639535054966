import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import task1 from '../../img/membership.png';
import task2 from '../../img/image-editing.png'
import task3 from '../../img/file.png';
import task4 from '../../img/stamp.png';
import task5 from '../../img/rejected.png';
import task6 from '../../img/archive.png';
import task7 from '../../img/recycle-bin.png';
import task8 from '../../img/home.png';
import task9 from '../../img/setting.png';
import task10 from '../../img/schedule.png'


let filteredData;

const handleSwitchTab = (pageIndex, users, fees, navigate, officerData, allOfficers, updateID, showNotif, notifBody) => {
    // console.log(updateID)
    // console.log(allOfficers)
    switch (pageIndex) {
        case 0:
            filteredData = users.filter(x => x.data.STATUS === 0)
            // console.log('received applications array')
            // console.log(filteredData)
            navigate('/received', { state: { userList: filteredData, fullList: users, allFees: fees, officerData, allOfficers, updateID, showNotif, notifBody } })
            break;

        case 1:
            filteredData = users.filter(x => x.data.STATUS === 1)
            // console.log('full applications array')
            // console.log(filteredData)
            navigate('/fullapp', { state: { userList: filteredData, fullList: users, allFees: fees, officerData, allOfficers, updateID, showNotif, notifBody } })
            break;

        case 2:
            filteredData = users.filter(x => x.data.STATUS === 2)
            // console.log('full applications array')
            // console.log(filteredData)
            navigate('/pending', { state: { userList: filteredData, fullList: users, allFees: fees, officerData, allOfficers, updateID, showNotif, notifBody } })
            break;

        case 3:
            filteredData = users.filter(x => x.data.STATUS === 3)
            // console.log(filteredData)
            navigate('/refusal', { state: { userList: filteredData, fullList: users, allFees: fees, officerData, allOfficers, updateID, showNotif, notifBody } })
            break;

        case 4:
            filteredData = users.filter(x => x.data.STATUS === 4)
            // console.log(filteredData)
            navigate('/processing', { state: { userList: filteredData, fullList: users, allFees: fees, officerData, allOfficers, updateID,  showNotif, notifBody } })
            break;

        case 5:
            filteredData = users.filter(x => x.data.STATUS === 5)
            // console.log(filteredData)
            navigate('/archives', { state: { userList: filteredData, fullList: users, allFees: fees, officerData, allOfficers, updateID,  showNotif, notifBody } })
            break;

        case 6:
            filteredData = users.filter(x => x.data.STATUS === 6)
            // console.log(filteredData)
            navigate('/disposed', { state: { userList: filteredData, fullList: users, allFees: fees, officerData, allOfficers, updateID, showNotif, notifBody } })
            break;

        case 7:
            navigate('/dashboard', { state: { userList: users, fullList: users, allFees: fees, officerData, allOfficers, updateID, showNotif, notifBody } })
            break;

        case 8:
            navigate('/settings', { state: { userList: users, fullList: users, allFees: fees, officerData, allOfficers, updateID, showNotif, notifBody } })
            break;

        case 9:
            filteredData = users.filter(x => x.data.HasAppointment)
            navigate('/viewappointments', { state: { userList: filteredData, fullList: users, allFees: fees, officerData, allOfficers, updateID, showNotif, notifBody } })
            break;


        default:
            break;
    }

}

function Sidebar(props) {
    const navigate = useNavigate();
    const route = window.location.pathname;
    // console.log(route)
    // console.log(props.officerData)
    return (
        <div className='sidebar'>
            <div className="sidebar__itembox">

                <div
                    className="sidebar__item"
                    onClick={() => handleSwitchTab(0, props.users, props.fees, navigate, props.officerData, props.officers, props.updateID, props.showNotif, props.notifBody)}
                >
                    <div className="sidebar__item__imgbox">
                        <img src={task1} alt="Icon for Received Applications tab" className="sidebar__img" />
                    </div>
                    <div
                        className="sidebar__text"
                        style={route === "/received" ? { backgroundColor: '#dc143c' } : {}}
                    >
                        Received Applications
                    </div>
                </div>

                <div className="sidebar__item"
                    onClick={() => handleSwitchTab(1, props.users, props.fees, navigate, props.officerData, props.officers, props.updateID, props.showNotif, props.notifBody)}
                >
                    <div className="sidebar__item__imgbox">
                        <img src={task2} alt="Icon for Received Applications tab" className="sidebar__img" />
                    </div>
                    <div
                        className="sidebar__text"
                        style={route === "/fullapp" ? { backgroundColor: '#dc143c' } : {}}
                    >
                        Full Applications
                    </div>
                </div>


                <div
                    className="sidebar__item"
                    onClick={() => handleSwitchTab(2, props.users, props.fees, navigate, props.officerData, props.officers, props.updateID, props.showNotif, props.notifBody)}
                >
                    <div className="sidebar__item__imgbox">
                        <img src={task3} alt="Icon for Received Applications tab" className="sidebar__img" />
                    </div>
                    <div
                        className="sidebar__text"
                        style={route === "/pending" ? { backgroundColor: '#dc143c' } : {}}
                    >
                        Pending Approvals
                    </div>
                </div>

                <div
                    className="sidebar__item"
                    onClick={() => handleSwitchTab(4, props.users, props.fees, navigate, props.officerData, props.officers, props.updateID,  props.showNotif, props.notifBody)}
                >
                    <div className="sidebar__item__imgbox">
                        <img src={task4} alt="Icon for Received Applications tab" className="sidebar__img" />
                    </div>
                    <div
                        className="sidebar__text"
                        style={route === "/processing" ? { backgroundColor: '#dc143c' } : {}}
                    >
                        Passport Processing
                    </div>
                </div>

                <div
                    className="sidebar__item"
                    onClick={() => handleSwitchTab(3, props.users, props.fees, navigate, props.officerData, props.officers, props.updateID,  props.showNotif, props.notifBody)}
                >
                    <div className="sidebar__item__imgbox">
                        <img src={task5} alt="Icon for Received Applications tab" className="sidebar__img" />
                    </div>
                    <div
                        className="sidebar__text"
                        style={route === "/refusal" ? { backgroundColor: '#dc143c' } : {}}
                    >
                        Refusals
                    </div>
                </div>

                <div
                    className="sidebar__item"
                    onClick={() => handleSwitchTab(5, props.users, props.fees, navigate, props.officerData, props.officers, props.updateID,  props.showNotif, props.notifBody)}
                >
                    <div className="sidebar__item__imgbox">
                        <img src={task6} alt="Icon for Received Applications tab" className="sidebar__img" />
                    </div>
                    <div
                        className="sidebar__text"
                        style={route === "/archives" ? { backgroundColor: '#dc143c' } : {}}
                    >
                        Archives
                    </div>
                </div>

                <div
                    className="sidebar__item"
                    onClick={() => handleSwitchTab(6, props.users, props.fees, navigate, props.officerData, props.officers, props.updateID,  props.showNotif, props.notifBody)}
                >
                    <div className="sidebar__item__imgbox">
                        <img src={task7} alt="Icon for Received Applications tab" className="sidebar__img" />
                    </div>
                    <div
                        className="sidebar__text"
                        style={route === "/disposed" ? { backgroundColor: '#dc143c' } : {}}
                    >
                        Deleted
                    </div>
                </div>

                <div
                    className="sidebar__item"
                    onClick={() => handleSwitchTab(7, props.users, props.fees, navigate, props.officerData, props.officers, props.updateID, props.showNotif, props.notifBody)}

                >
                    <div className="sidebar__item__imgbox">
                        <img src={task8} alt="Icon for Received Applications tab" className="sidebar__img" />
                    </div>
                    <div
                        className="sidebar__text"
                        style={route === "/dashboard" ? { backgroundColor: '#dc143c' } : {}}
                    >
                        Dashboard
                    </div>
                </div>

                <div className="sidebar__item"
                    onClick={() => handleSwitchTab(8, props.users, props.fees, navigate, props.officerData, props.officers, props.updateID,  props.showNotif, props.notifBody)}

                >
                    <div className="sidebar__item__imgbox">
                        <img src={task9} alt="Icon for Received Applications tab" className="sidebar__img" />
                    </div>
                    <div
                        className="sidebar__text"
                        style={route === "/settings" ? { backgroundColor: '#dc143c' } : {}}
                    >
                        Settings
                    </div>
                </div>

                <div className="sidebar__item"
                    onClick={() => handleSwitchTab(9, props.users, props.fees, navigate, props.officerData, props.officers, props.updateID, props.showNotif, props.notifBody)}

                >
                    <div className="sidebar__item__imgbox">
                        <img src={task10} alt="Icon for Received Applications tab" className="sidebar__img" />
                    </div>
                    <div
                        className="sidebar__text"
                        style={route === "/viewappointments" ? { backgroundColor: '#dc143c' } : {}}
                    >
                        Appointments
                    </div>
                </div>


            </div>
            <h2 className='sidebar__header'>Menu</h2>
        </div>
    )
}

export default Sidebar