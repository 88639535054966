import React, { useState, useEffect } from 'react'
import { RiUserSettingsFill, RiLogoutCircleRFill } from 'react-icons/ri'
import {useNavigate} from 'react-router-dom';
import _ from 'lodash';

const today = new Date()

const onSearch = (query, users, setQuery, setData) => {
    // console.log('run')
    let searchTerm = query.trim();
    const regTerm = new RegExp(searchTerm, 'g')
    // console.log(users)
    setQuery(searchTerm)
    const filteredUsers = users.filter(x => {
        let flag = false;
        for (let k in x.data) {
            if (regTerm.test(x.data[k])) {
                flag = true;
            }
                
        }
        return flag
    }
        
    )
    // console.log(filteredUsers)
    setData(filteredUsers)


}

const logout = (navigate) => {
    localStorage.setItem('LOGGED_IN', '0')
    navigate("/");
}

function Header(props) {
    const [query, setQuery] = useState('');
    const [formattedUserList, setformattedUserList] = useState([])
    const navigate = useNavigate();
    // // console.log('props.dataSet ---->')
    // // console.log(props.dataSet)

    /*

    useEffect(() => {
        // console.log('Here iam useeffect running in heaven bro')
        const dataset = props.dataSet;
        // // console.log(props.showSearch)
        // console.log('ShowList Changed')

        
        dataset.map((x) => {
            // // console.log(x)
            Object.keys(x.data).forEach((k) => {
                // // console.log(x)
                if (typeof(x.data[k]) !== 'string') {
                    // // console.log(k)
                    // // console.log(typeof(x.data[k]))
                    x.data[k] = '' + x.data[k];
                    // // console.log('Pretty')

                }
            })
        })
        // console.log(dataset)
        setformattedUserList(dataset)
    }, [props.dataSet])
    */

    return (
        <div className='header'>
            <div className="header__user">

                <div className="header__user__avatar">
                    <RiUserSettingsFill className="header__user__avatar__img" />
                </div>

                <div className="header__user__info">
                    <p className="header__user__info__name">{props.officerData.username}</p>
                    <p className="header__user__info__date">{today.toDateString()}</p>
                </div>
            </div>

            {props.showSearch ? (
                <div className="header__search">
                <input
                    className='header__search__input'
                    type="text"
                    placeholder='Enter Search Term'
                    value={query}
                    onChange={e => onSearch(e.target.value,props.dataSet, setQuery, props.setData)}
                />
            </div>

            ) : (
                <h2 className='heading-secondary'>{props.headerText || 'Applicant Data'}</h2>
            )

            }

            

            <div className="header__logout" onClick={() => logout(navigate)}>
                <RiLogoutCircleRFill className='header__logout__icon' />
                <p className="header__logout__text">Logout</p>
            </div>
        </div>
    )
}

export default Header