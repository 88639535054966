import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Landing from './features/Landing';
import Tasks from './features/Tasks';
import ReceivedApplications from './features/ReceivedApplications';
import ViewApplication from './features/ViewApplication';
import FullApplications from './features/FullApplications';
import ViewFullApplications from './features/ViewFullApplications';
import Accepted from './features/Accepted';
import ViewAccepted from './features/ViewAccepted';
import Processing from './features/Processing';
import Refusal from './features/Refusal';
import Archive from './features/Archive';
import Dashboard from './features/Dashboard';
import Settings from './features/Settings';
import ViewProcessing from './features/ViewProcessing';
import ViewRefusal from './features/ViewRefusal';
import ViewArchive from './features/ViewArchive';
import FeeSettings from './features/FeeSettings';
import UserSettings from './features/UserSettings';
import ProtectedRoute from './ProtectedRoute';
import Disposed from './features/Disposed';
import ViewDisposed from './features/ViewDisposed';
import UserAdminSettings from './features/UserAdminSettings';
import Appointments from './features/Appointments';
import ViewAppointments from './features/ViewAppointments';
import UploadDocs from './features/UploadDocs';
import Notifications from './features/Notifications';

export default function Navigator() {

  const logged = localStorage.getItem('LOGGED_IN')

  return (
    <Router>


      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}

      <Routes>

        <Route
          path="/adminsettings"
          element={
            <ProtectedRoute logged={logged}>
              <UserAdminSettings />
            </ProtectedRoute>
          } />

        <Route
          path="/upload"
          element={
            <ProtectedRoute logged={logged}>
              <UploadDocs />
            </ProtectedRoute>
          } />

        <Route
          path="/notifications"
          element={
            <ProtectedRoute logged={logged}>
              <Notifications />
            </ProtectedRoute>
          } />

        <Route
          path="/viewappointments"
          element={
            <ProtectedRoute logged={logged}>
              <ViewAppointments />
            </ProtectedRoute>
          } />


        <Route
          path="/bookappointments"
          element={
            <ProtectedRoute logged={logged}>
              <Appointments />
            </ProtectedRoute>
          } />

        <Route
          path="/disposed"
          element={
            <ProtectedRoute logged={logged}>
              <Disposed />
            </ProtectedRoute>
          } />
        <Route
          path="/viewdisposed"
          element={
            <ProtectedRoute logged={logged}>
              <ViewDisposed />
            </ProtectedRoute>
          } />

        <Route
          path="/usersettings"
          element={
            <ProtectedRoute logged={logged}>
              <UserSettings />
            </ProtectedRoute>
          } />
        <Route
          path="/feesettings"
          element={
            <ProtectedRoute logged={logged}>
              <FeeSettings />
            </ProtectedRoute>
          } />
        <Route
          path="/viewarchive"
          element={
            <ProtectedRoute logged={logged}>
              <ViewArchive />
            </ProtectedRoute>
          } />
        <Route
          path="/viewrefusal"
          element={
            <ProtectedRoute logged={logged}>
              <ViewRefusal />
            </ProtectedRoute>
          } />
        <Route
          path="/viewprocessing"
          element={
            <ProtectedRoute logged={logged}>
              <ViewProcessing />
            </ProtectedRoute>
          } />
        <Route
          path="/settings"
          element={
            <ProtectedRoute logged={logged}>
              <Settings />
            </ProtectedRoute>
          } />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute logged={logged}>
              <Dashboard />
            </ProtectedRoute>
          } />
        <Route
          path="/archives"
          element={
            <ProtectedRoute logged={logged}>
              <Archive />
            </ProtectedRoute>
          } />
        <Route
          path="/refusal"
          element={
            <ProtectedRoute logged={logged}>
              <Refusal />
            </ProtectedRoute>
          } />
        <Route
          path="/processing"
          element={
            <ProtectedRoute logged={logged}>
              <Processing />
            </ProtectedRoute>
          } />
        <Route
          path="/viewpending"
          element={
            <ProtectedRoute logged={logged}>
              <ViewAccepted />
            </ProtectedRoute>
          } />
        <Route
          path="/pending"
          element={
            <ProtectedRoute logged={logged}>
              <Accepted />
            </ProtectedRoute>
          } />
        <Route
          path="/viewfullapp"
          element={
            <ProtectedRoute logged={logged}>
              <ViewFullApplications />
            </ProtectedRoute>
          }
        />
        <Route
          path="/fullapp"
          element={
            <ProtectedRoute logged={logged}>
              <FullApplications />
            </ProtectedRoute>
          } />
        <Route
          path="/viewapp"
          element={
            <ProtectedRoute logged={logged}>
              <ViewApplication />
            </ProtectedRoute>
          }
        />
        <Route
          path="/received"
          element={
            <ProtectedRoute logged={logged}>
              <ReceivedApplications />
            </ProtectedRoute>
          }
        />
        <Route
          path="/task"
          element={
            <ProtectedRoute logged={logged}>
              <Tasks />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Landing />} />
      </Routes>

    </Router>
  )
}
