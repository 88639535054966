import React, { useState } from 'react'
import Header from './shared/Header'
import Footer from './shared/Footer'
import { useLocation, useNavigate } from 'react-router-dom'
import _ from 'lodash';
import { AiFillSave } from 'react-icons/ai'
import { MdArrowBack, MdNotifications, MdNotificationsOff } from 'react-icons/md'
import Spinner from './shared/Spinner';
import {db} from '../utils/firebase'
import {doc, updateDoc} from 'firebase/firestore'


function Notifications(props) {
    const location = useLocation();
    // console.log(location.state.notifBody)
    

    const [text, setText] = useState(location.state.notifBody)
    const [viewNotif, setViewNotif] = useState(location.state.showNotif)
    
    const [showSpinner, setShowSpinner] = useState(false)
    return (
        <div className='notif'>
            <Header
                headerText="APP NOTIFICATIONS"
                officerData={!_.isEmpty(location) ? location.state.officerData : []}
            />

            <Spinner showSpinner={showSpinner}/>

            <div className="notif__body"
                style={ showSpinner ? {display: 'none', width: '0'} : {}}
            >

                <textarea
                    placeholder='Enter Notification on visa application page'
                    className='notif__textarea'
                    style={{ width: '80%', height: '80%', }}
                    onChange={(e) => setText(e.target.value)}
                    value={text}
                />
            </div>

            <div 
                className="notif__taskbar"
                style={ showSpinner ? {display: 'none', width: '0'} : {}}
            >
                <Taskbar 

                    setShowSpinner={setShowSpinner}
                    notifText={text}
                    viewNotif={viewNotif}
                    setViewNotif={setViewNotif}
                />
            </div>

            <Footer />
        </div>
    )
}

export default Notifications


const Taskbar = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className='taskbar notif__taskbar__item'>


            <div
                className="taskbar__item__box"
                onClick={() => switchNotif(true, location, props.setShowSpinner, props.setViewNotif)}

            >
                <MdNotifications className='taskbar__item__icon' color='white' />
                <span>Turn on</span>
            </div>


            <div
                className="taskbar__item__box"
                onClick={() => switchNotif(false, location, props.setShowSpinner, props.setViewNotif)}

            >
                <MdNotificationsOff className='taskbar__item__icon' color='white' />
                <span>Turn Off</span>
            </div>

            <div
                className="taskbar__item__box"
                onClick={() => saveNotif(props.notifText, location, props.setShowSpinner)}
            >
                <AiFillSave className='taskbar__item__icon' color='white' />
                <span>Save</span>
            </div>

            <div
                className="taskbar__item__box"
                onClick={() => goBack(location, navigate, props.notifText, props.viewNotif)}
            >
                <MdArrowBack className='taskbar__item__icon' color='white' />
                <span>Back</span>
            </div>

        </div>
    )
}

const goBack = (location, navigate, text, viewNotif) => {
    
    navigate('/settings', {
        state: {

            allFees: location.state.allFees,

            updateID: location.state.key,

            allOfficers: location.state.allOfficers,
            userList: location.state.fullList,
            fullList: location.state.fullList,
            officerData: location.state.officerData,

            showNotif: viewNotif,
            notifBody: text,
        }
    })
}


const switchNotif = (status, location, setShowSpinner, setViewNotif) => {

    
    
    setShowSpinner(true)
    const docRef = doc(db, 'utils', location.state.key);

    const updateData = {
        SHOW_NOTIF: status,
    }

    updateDoc(docRef, updateData).then(() => {

        setViewNotif(status)

        setShowSpinner(false)

        // updateLocalFeesDb or refetch and update...

    }).catch((err) => {
        setShowSpinner(false)
        alert('Error when adding')
        // console.log(err)
    })

}

const saveNotif = (text, location, setShowSpinner) => {

    setShowSpinner(true)
    const docRef = doc(db, 'utils', location.state.key);

    const updateData = {
        APP_NOTIF: text,
    }

    updateDoc(docRef, updateData).then(() => {
        setShowSpinner(false)

        // updateLocalFeesDb or refetch and update...

    }).catch((err) => {
        setShowSpinner(false)
        alert('Error when adding')
        // console.log(err)
    })

}