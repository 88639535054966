import React, { Component } from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Spinner from './shared/Spinner';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import DefaultPreview from '../img/undraw_my_files_swob.svg'
import { db, storage } from '../utils/firebase'
import { FaPassport } from 'react-icons/fa'
import { TbVaccine, TbSignature, TbCameraSelfie } from 'react-icons/tb'
import { IoIosPaper } from 'react-icons/io'
import { RiHotelFill } from 'react-icons/ri'
import { GiMoneyStack } from 'react-icons/gi'
import { BsFilePdf } from 'react-icons/bs'
import { useLocation, Navigate } from 'react-router-dom'
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import Rodal from 'rodal';
import UploadSuccess from '../img/avatar.png';
import errorImg from '../img/delete.png';
import PDF from '../img/pdf.png';
import { isMobile } from 'react-device-detect'
import _ from 'lodash';
// include styles
import 'rodal/lib/rodal.css'
import emailjs from '@emailjs/browser';

let passportPicURL;
let passportURL;
let vaccinationCardURL;
let referenceURL;
let bookingURL;
let regformURL;
let visapayURL;
let othersURL;

let location = {};
let linkArray = [];
let newList = [];
let allApplications = []

const UseLocation = () => {
    location = useLocation();
    // console.log(location.state)
    return null;
}

const unselected = [0, 1, 2, 3, 4, 5, 6, 7]

const guideElements = [
    (
        <div className="upload__guide__card__item">
            <TbCameraSelfie size={17} className="upload__guide__card__item__icon" />
            <span>Passport Size Photograph</span>

        </div>),
    (
        <div className="upload__guide__card__item">
            <FaPassport size={17} className="upload__guide__card__item__icon" />
            <span>Digital Passport</span>

        </div>),
    (

        <div className="upload__guide__card__item">
            <TbVaccine size={17} className="upload__guide__card__item__icon" />
            <span>Yellow Fever Vaccination Card</span>
        </div>),
    (
        <div className="upload__guide__card__item">
            <IoIosPaper size={17} className="upload__guide__card__item__icon" />
            <span>Reference Letter from Inviting Party</span>
        </div>
    ),
    (

        <div className="upload__guide__card__item">
            <RiHotelFill size={17} className="upload__guide__card__item__icon" />
            <span>Booking Reservation</span>
        </div>
    ),
    (

        <div className="upload__guide__card__item">
            <TbSignature size={17} className="upload__guide__card__item__icon" />
            <span>Signed Copy of Registration Form</span>
        </div>
    ),
    (

        <div className="upload__guide__card__item">
            <GiMoneyStack size={17} className="upload__guide__card__item__icon" />
            <span>Evidence of Visa Payment</span>
        </div>
    ),
    (

        <div className="upload__guide__card__item">
            <BsFilePdf size={17} className="upload__guide__card__item__icon" />
            <span>Other Documents Not Listed</span>
        </div>
    )
]

export class UploadDocs extends Component {

    constructor(props) {

        super(props)
        this.PassportPicRef = React.createRef(null)
        this.PassportRef = React.createRef(null)
        this.VaccinationRef = React.createRef(null)
        this.ReferenceRef = React.createRef(null)
        this.BookingRef = React.createRef(null)
        this.RegformRef = React.createRef(null)
        this.VisapayRef = React.createRef(null)
        this.OthersRef = React.createRef(null)
    }

    componentDidMount() {
        // console.log('Inside DID MOUNT')
        this.setState({ showSpinner: false })

        // console.log(location.state.selected.id)
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.passportPic !== this.state.passportPic) {
            if (this.state.passportPic !== null) {
                // console.log(this.state.passportPic)
                const fileType = this.state.passportPic.type;
                // console.log(fileType)
                const type = fileType === undefined ? '' : fileType.split('/')
                // console.log(type)

                if (type[0] === 'image') {
                    passportPicURL = URL.createObjectURL(this.state.passportPic);
                    this.setState({ passportPicPreview: passportPicURL });

                    if (!this.state.selected.includes(0)) {
                        // console.log('Inlucde guide')
                        this.setState({ selected: [...this.state.selected, 0] })

                    }

                } else {

                    this.setState({ passportPic: '', passportPicPreview: '', errorModalmessage: 'IMAGE' }, () =>
                        this.setState({ errorModalVisible: true })
                    )

                }

                return;
            }

        }

        if (prevState.passport !== this.state.passport) {
            if (this.state.passport !== null) {
                passportURL = URL.createObjectURL(this.state.passport);
                this.setState({ passportPreview: passportURL });

                if (!this.state.selected.includes(1)) {
                    // console.log('Inlucde guide')
                    this.setState({ selected: [...this.state.selected, 1] })

                }
                return;
            }

        }

        if (prevState.vaccinationcard !== this.state.vaccinationcard) {
            if (this.state.vaccinationcard != null) {
                vaccinationCardURL = URL.createObjectURL(this.state.vaccinationcard);
                this.setState({ vaccinationcardPreview: vaccinationCardURL })

                if (!this.state.selected.includes(2)) {
                    // console.log('Inlucde guide')
                    this.setState({ selected: [...this.state.selected, 2] })

                }
                return;
            }
        }

        if (prevState.referenceletter !== this.state.referenceletter) {
            if (this.state.referenceletter !== null) {
                referenceURL = URL.createObjectURL(this.state.referenceletter);
                this.setState({ referenceletterPreview: referenceURL })

                if (!this.state.selected.includes(3)) {
                    // console.log('Inlucde guide')
                    this.setState({ selected: [...this.state.selected, 3] })

                }
                return;
            }
        }

        if (prevState.booking !== this.state.booking) {
            if (this.state.booking !== null) {
                bookingURL = URL.createObjectURL(this.state.booking);
                this.setState({ bookingPreview: bookingURL })

                if (!this.state.selected.includes(4)) {
                    // console.log('Inlucde guide')
                    this.setState({ selected: [...this.state.selected, 4] })

                }
                return;
            }
        }

        if (prevState.registrationform !== this.state.registrationform) {
            if (this.state.registrationform !== null) {
                const fileType = this.state.registrationform.type;
                // console.log(fileType)
                const type = fileType === undefined ? '' : fileType.split('/')
                // console.log(type)
                if (type[1] === 'pdf') {
                    regformURL = URL.createObjectURL(this.state.registrationform);
                    this.setState({ registrationformPreview: regformURL })

                    if (!this.state.selected.includes(5)) {
                        // console.log('Inlucde guide')
                        this.setState({ selected: [...this.state.selected, 5] })

                    }

                } else {
                    this.setState({ registrationform: '', registrationformPreview: '', errorModalmessage: 'PDF' }, () =>
                        this.setState({ errorModalVisible: true }))

                }



                return;
            }
        }

        if (prevState.visapay !== this.state.visapay) {
            if (this.state.visapay !== null) {
                visapayURL = URL.createObjectURL(this.state.visapay);
                this.setState({ visapayPreview: visapayURL })

                if (!this.state.selected.includes(6)) {
                    // console.log('Inlucde guide')
                    this.setState({ selected: [...this.state.selected, 6] })

                }
                return;
            }
        }

        if (prevState.others !== this.state.others) {
            if (this.state.others !== null) {
                const fileType = this.state.others.type;
                // console.log(fileType)
                const type = fileType === undefined ? '' : fileType.split('/')
                // console.log(type)
                if (type[1] === 'pdf') {
                    othersURL = URL.createObjectURL(this.state.others);
                    this.setState({ othersPreview: othersURL })

                    if (!this.state.selected.includes(7)) {
                        // console.log('Inlucde guide')
                        this.setState({ selected: [...this.state.selected, 7] })

                    }

                } else {
                    this.setState({ others: '', othersPreview: '', errorModalmessage: 'PDF' }, () =>
                        this.setState({ errorModalVisible: true }))

                }



                return;
            }
        }
    }

    componentWillUnmount() {
        URL.revokeObjectURL(passportPicURL)
        URL.revokeObjectURL(passportURL)
        URL.revokeObjectURL(vaccinationCardURL)
        URL.revokeObjectURL(referenceURL)
        URL.revokeObjectURL(bookingURL)
        URL.revokeObjectURL(regformURL)
        URL.revokeObjectURL(visapayURL)
        URL.revokeObjectURL(othersURL)
    }

    state = {
        passportPic: '',
        passport: '',
        vaccinationcard: '',
        referenceletter: '',
        booking: '',
        registrationform: '',
        visapay: '',
        others: '',

        passportPicPreview: '',
        passportPreview: '',
        vaccinationcardPreview: '',
        referenceletterPreview: '',
        bookingPreview: '',
        registrationformPreview: '',
        visapayPreview: '',
        othersPreview: '',


        selected: [],
        uploadedStatus: false,
        modalVisible: false,
        errorModalVisible: false,
        errorModalmessage: '',
        backPressed: false,

        showSpinner: true,
        navigate: false,
    }

    handleChange = (e) => {
        e.preventDefault()
        const value = e.target.files[0]
        // // console.log(value)

        if (value === undefined) {
            return
        } else {
            this.setState({
                ...this.state,
                [e.target.id]: value,

            }, () => {
                // console.log(this.state)
            })
        }

    }

    fireBaseTime = (x) => _.isEmpty(x) ? '' : new Date(
        x.seconds * 1000 + x.nanoseconds / 1000000,
    ).toDateString();

    _handleSubmit = async () => {

        this.setState({
            showSpinner: true,
        })

        linkArray = JSON.parse(location.state.selected.data.Link_To_Files);
        const startIndex = 0;

        // console.log(data)
        const { passportPic, passport, vaccinationcard, referenceletter, booking, registrationform, visapay, others } = this.state;

        // const compulsoryCheck = passport !== '' && passportPic !== '' && vaccinationcard !== '' && registrationform !== '';

        const uploadArray = [
            {
                path: 'passportsizepic',
                file: passportPic,
            },
            {
                path: 'passport',
                file: passport

            },
            {
                path: 'vaccine',
                file: vaccinationcard

            },
            {
                path: 'referenceletter',
                file: referenceletter

            },
            {
                path: 'booking',
                file: booking

            },
            {
                path: 'signedform',
                file: registrationform

            },
            {
                path: 'visapayment',
                file: visapay

            },
            {
                path: 'otherdocs',
                file: others

            },


        ]
       // console.log(linkArray)
        this.uploadDoc(uploadArray, startIndex);
        /*

        */

    }



    uploadDoc = (uploadData, index) => {
        // console.log(index);

        if (index < uploadData.length) {

            if (uploadData[index].file === '') {
                // linkArray.push('NONE');
                this.uploadDoc(uploadData, ++index)

            } else {
                // console.log('uploading')
                const data = location.state.selected;
                let percent;

                let storageRef = ref(storage, `${uploadData[index].path}/${data.data.ID}${uploadData[index].file.name}`)
                let uploadTask = uploadBytesResumable(storageRef, uploadData[index].file);



                uploadTask.on(
                    'state_changed', (snapshot) => {
                        percent = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );

                        // console.log(percent)
                    }, (error) => console.log(error),
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            // console.log('link -> ' + url)
                            linkArray.splice(index, 1, url)

                            // UPLOAD PASSPORT

                            this.uploadDoc(uploadData, ++index)


                        })
                    }
                )

            }



        } else {
            //  ADD LINKS OF FILES TO DATABASE
            this.setLinks(linkArray)
        }



    }

    setLinks = async () => {
        const applicantRef = doc(db, 'applications', location.state.selected.id)
        try {
            await updateDoc(applicantRef, {
                Link_To_Files: JSON.stringify(linkArray),
            });

            // console.log(linkArray)

            this.updateLocalDb()


        } catch (e) {
            // console.log(e)
        }

    }


    updateLocalDb = () => {

        // TAKE OUT APPROVED APPLICATION FROM  LIST OF APPROVED APPLICATIONS THEN PASS IT BACK TO THE FULL APPLICATION PAGE TO GET UPDATED LIST
        newList = location.state.userList.filter(x => x.id !== location.state.selected.id)
        allApplications = location.state.fullList;

        const selectedIndex = location.state.fullList.findIndex(x => x.id === location.state.selected.id)
        // console.log('ID Here ' + selectedIndex)
        // console.log(location.state.fullList[selectedIndex])

        const modifiedApplication = {
            id: location.state.fullList[selectedIndex].id,
            data: {
                ...location.state.fullList[selectedIndex].data,
                Link_To_Files: JSON.stringify(linkArray)
            }
        }
        newList = [...newList, modifiedApplication]
        // console.log(modifiedApplication)
        allApplications.splice(selectedIndex, 1, modifiedApplication)
        // console.log(allApplications)




        this.setState({
            uploadedStatus: true,


            /*
            passportPreview: '',
            vaccinationcardPreview: '',
            referenceletterPreview: '',
            bookingPreview: '',
            registrationformPreview: '',
            visapayPreview: '', passport: '',
            vaccinationcard: '',
            referenceletter: '',
            booking: '',
            registrationform: '',
            visapay: '',
            */


            showSpinner: false,

        }, () => {

            this.setState({ modalVisible: true })

        })
    }



    checkLinkArrayLength() {
        if (linkArray.length < 8) {
            // console.log('Check ongoing')
            return false
        } else {
            // console.log('Check passed')
            return true
        }

    }



    getFileExtension(fileName) {
        let extensionEnd;
        extensionEnd = fileName.replace(/^.*\./, '');
        const [fileExtension, fileEnd] = extensionEnd.split('?')
        return fileExtension;
    }

    isIMage(fileName) {
        var fileExt = this.getFileExtension(fileName);
        var imagesExtension = ["png", "jpg", "jpeg", "PNG", "JPG", "JPEG"];
        if (imagesExtension.indexOf(fileExt) !== -1) {
            return true;
        } else {
            return false;
        }
    }


    handleRodalClose() {
        this.setState({ modalVisible: false, navigate: true },)
    }

    anyUpdated = () => {
        const { passportPic, passport, vaccinationcard, referenceletter, booking, registrationform, visapay, others, showSpinner, selected, navigate, backPressed } = this.state;
        return passport !== '' || passportPic !== "" || vaccinationcard !== "" || referenceletter !== "" ||
        booking !== "" || visapay !== "" || others !== "" || registrationform !== ""
    }


    render() {
        const { passportPic, passport, vaccinationcard, referenceletter, booking, registrationform, visapay, others, showSpinner, selected, navigate, backPressed } = this.state;
        // console.log(!_.isEmpty(location))
        return (
            <div className='upload'>
                <UseLocation />
                {navigate &&
                    <Navigate
                        to={'/fullapp'}
                        state={{
                            userList: newList,
                            fullList: allApplications,
                            allFees: location.state.allFees,
                            officerData: location.state.officerData,

                            allOfficers: location.state.allOfficers,
                            updateID: location.state.updateID,

                            showNotif: location.state.showNotif,
                            notifBody: location.state.notifBody,
                        }}
                    />
                }

                {backPressed &&
                    <Navigate
                        to={-1}

                    />
                }
                <Header
                    officerData={!_.isEmpty(location) ? location.state.officerData : {}}
                    headerText="Upload Documents"
                />

                <Spinner showSpinner={showSpinner} />

                <Rodal
                    visible={this.state.modalVisible}
                    width={isMobile ? window.innerWidth * 0.8 : window.innerWidth * 0.4}
                    height={440}
                >
                    <div className='myrodal'>
                        <div className="myrodal__iconbox">
                            <img src={UploadSuccess} alt="File upload sucesss" className='myrodal__icon' />
                        </div>
                        <div className="myrodal__message">

                            You have updated some user documents and upload is complete

                        </div>

                        <div className="myrodal__btnContainer" onClick={() => this.handleRodalClose()}>
                            <button className="btn btn--normal">Ok</button>
                        </div>

                    </div>
                </Rodal>

                <Rodal
                    visible={this.state.errorModalVisible}
                    width={isMobile ? window.innerWidth * 0.8 : window.innerWidth * 0.4}
                    height={350}
                >
                    <div className='myrodal'>
                        <div className="myrodal__iconbox">
                            <img src={errorImg} alt="File upload sucesss" className='myrodal__icon' />
                        </div>
                        <div className="myrodal__message" style={{ fontWeight: 'bold' }}>

                            kindly Select File of type <span style={{ color: 'red' }}>{this.state.errorModalmessage}</span><br />
                            Thank You

                        </div>

                        <div className="myrodal__btnContainer" onClick={() => this.setState({ errorModalVisible: false })}>
                            <button className="btn btn--normal">Ok</button>
                        </div>

                    </div>
                </Rodal>

                <div className="upload__container" style={showSpinner ? { display: 'none', width: '0' } : {}}>
                    <div className='upload__container__box'>
                        <div className="upload__guide">

                            <div className="upload__guide__card">
                                <p className="upload__guide__card__head">Uploaded</p>
                                {
                                    !this.state.uploadedStatus ?
                                        <p className='upload__guide__card__nofile'>No Files Uploaded</p>
                                        :
                                        unselected.map((item) => (
                                            guideElements[item]
                                        ))

                                }


                            </div>

                            <div className="upload__guide__card">
                                <p className="upload__guide__card__head">Selected</p>

                                {selected.length === 0 ? (
                                    <p className='upload__guide__card__nofile'>No Files Selected</p>

                                ) : (
                                    selected.map((item) => (
                                        guideElements[item]

                                    )
                                    ))}

                            </div>

                            <div className="upload__guide__card">
                                <p className="upload__guide__card__head">Not Selected</p>

                                {selected.length === 8 ?
                                    (<p className='upload__guide__card__nofile'>All files Selected</p>)
                                    : unselected.filter(x => !selected.includes(x)).map((item) => (
                                        guideElements[item]
                                    ))
                                }

                            </div>

                        </div>

                        <div className="upload__main">


                            <div className="upload__main__card">
                                <div className="upload__main__card__imgtextbox">
                                    <div className="upload__main__card__imgbox">
                                        <img
                                            // src={this.state.passportPicPreview || DefaultPreview} 
                                            src={passportPic !== "" ?
                                                (this.isIMage(passportPic.name) ? this.state.passportPicPreview : PDF ) :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[0].toUpperCase() !== 'NONE' ?
                                                    (this.isIMage(JSON.parse(location.state.selected.data.Link_To_Files)[0]) ?
                                                        JSON.parse(location.state.selected.data.Link_To_Files)[0] : PDF) :
                                                    DefaultPreview

                                            }
                                            alt="Preview of uploaded file"
                                            className="upload__main__card__img"
                                        />
                                    </div>
                                    <div className="upload__main__card__textbox">
                                        <p className="upload__main__card__textbox__item--name">

                                            {passportPic !== "" ?
                                                (passportPic.name.split('.')[0]).slice(0, 35) + '...' :
                                                (!_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[0].toUpperCase() !== 'NONE' ? `${location.state.selected.data.Firstnames}'s Passport Size Photo` : 'Passport Size Photo')
                                            }
                                        </p>
                                        <p className="upload__main__card__textbox__item--type">
                                            {passportPic !== "" ?
                                                passportPic.type :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[0].toUpperCase() !== 'NONE' ? this.getFileExtension(JSON.parse(location.state.selected.data.Link_To_Files)[0]).toUpperCase() : 'No Type'
                                            }
                                        </p>

                                        <p className="upload__main__card__textbox__item--size">
                                            {passportPic !== "" ?
                                                `${Math.round(passportPic.size / 1000)} kb` :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[0].toUpperCase() !== 'NONE' ? 'No metadata' : 'No Size'
                                            }
                                        </p>
                                        <p className="upload__main__card__textbox__item--dimensions">Passport Size Photo</p>
                                        <p className="upload__main__card__textbox__item--priority">
                                            {
                                                passportPic !== "" ?
                                                    'Admin Updated' :
                                                    !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[0].toUpperCase() !== 'NONE' ?
                                                        `User Uploaded` : 'NOT Uploaded by User'
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="upload__main__card__btncontainer">
                                    <input

                                        id="passportPic"
                                        name="passportPic"
                                        ref={this.PassportPicRef}
                                        type="file"
                                        multiple={false}
                                        onChange={this.handleChange.bind(this)}
                                        className="upload__main__card__input"
                                    />

                                    <button
                                        className='btn upload__main__card__btn'
                                        onClick={() => {
                                            // console.log('heldj')
                                            this.PassportPicRef.current.click()
                                        }}
                                    >
                                        {this.state.passportPic === '' ? 'Upload' : 'Update'}
                                    </button>

                                </div>

                            </div>

                            <div className="upload__main__card">
                                <div className="upload__main__card__imgtextbox">
                                    <div className="upload__main__card__imgbox">
                                        <img
                                            src={passport !== "" ?
                                           (this.isIMage(passport.name) ? this.state.passportPreview : PDF )  :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[1].toUpperCase() !== 'NONE' ?
                                                    (this.isIMage(JSON.parse(location.state.selected.data.Link_To_Files)[1]) ?
                                                        JSON.parse(location.state.selected.data.Link_To_Files)[1] : PDF) :
                                                    DefaultPreview
                                            }
                                            alt="Preview of uploaded file"
                                            className="upload__main__card__img"
                                        />
                                    </div>
                                    <div className="upload__main__card__textbox">
                                        <p className="upload__main__card__textbox__item--name">
                                            {passport !== "" ?
                                                passport.name.split('.')[0].slice(0, 35) + '...' :
                                                (!_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[1].toUpperCase() !== 'NONE' ? `${location.state.selected.data.Firstnames}'s Passport Biodata` : 'Passport Biodata')
                                            }
                                        </p>
                                        <p className="upload__main__card__textbox__item--type">
                                            {passport !== "" ?
                                                passport.type :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[1].toUpperCase() !== 'NONE' ? this.getFileExtension(JSON.parse(location.state.selected.data.Link_To_Files)[1]).toUpperCase() : 'No Type'
                                            }
                                        </p>

                                        <p className="upload__main__card__textbox__item--size">
                                            {passport !== "" ?
                                                `${Math.round(passport.size / 1000)} kb` :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[1].toUpperCase() !== 'NONE' ? 'No metadata' : 'No Size'
                                            }
                                        </p>
                                        <p className="upload__main__card__textbox__item--dimensions">Passport Biodata</p>
                                        <p className="upload__main__card__textbox__item--priority">
                                            {
                                                passport !== "" ?
                                                    'Admin Updated' :
                                                    !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[1].toUpperCase() !== 'NONE' ?
                                                        `User Uploaded` : 'NOT Uploaded by User'
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="upload__main__card__btncontainer">
                                    <input

                                        id="passport"
                                        name="passport"
                                        ref={this.PassportRef}
                                        type="file"
                                        multiple={false}
                                        onChange={this.handleChange.bind(this)}
                                        className="upload__main__card__input"
                                    />

                                    <button
                                        className='btn upload__main__card__btn'
                                        onClick={() => {
                                            // console.log('heldj')
                                            this.PassportRef.current.click()
                                        }}
                                    >
                                        {this.state.passport === '' ? 'Upload' : 'Update'}
                                    </button>

                                </div>

                            </div>

                            <div className="upload__main__card">

                                <div className="upload__main__card__imgtextbox">
                                    <div className="upload__main__card__imgbox">


                                        <img
                                            src={vaccinationcard !== "" ?
                                            (this.isIMage(vaccinationcard.name) ? this.state.vaccinationcardPreview : PDF ) :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[2].toUpperCase() !== 'NONE' ?
                                                    (this.isIMage(JSON.parse(location.state.selected.data.Link_To_Files)[2]) ?
                                                        JSON.parse(location.state.selected.data.Link_To_Files)[2] : PDF) :
                                                    DefaultPreview
                                            }
                                            alt="Preview of uploaded file"
                                            className="upload__main__card__img"
                                        />

                                    </div>
                                    <div className="upload__main__card__textbox">
                                        <p className="upload__main__card__textbox__item--name">
                                            {vaccinationcard !== "" ?
                                                vaccinationcard.name.split('.')[0].slice(0, 35) + '...' :
                                                (!_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[2].toUpperCase() !== 'NONE' ? `${location.state.selected.data.Firstnames}'s Vaccination Card` : 'Vaccination Card')
                                            }
                                        </p>
                                        <p className="upload__main__card__textbox__item--type">
                                            {vaccinationcard !== "" ?
                                                vaccinationcard.type :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[2].toUpperCase() !== 'NONE' ? this.getFileExtension(JSON.parse(location.state.selected.data.Link_To_Files)[2]).toUpperCase() : 'No Type'
                                            }
                                        </p>

                                        <p className="upload__main__card__textbox__item--size">
                                            {vaccinationcard !== "" ?
                                                `${Math.round(vaccinationcard.size / 1000)} kb` :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[2].toUpperCase() !== 'NONE' ? 'No metadata' : 'No Size'
                                            }
                                        </p>
                                        <p className="upload__main__card__textbox__item--dimensions">Vaccination Card</p>
                                        <p className="upload__main__card__textbox__item--priority">
                                            {
                                                vaccinationcard !== "" ?
                                                    'Admin Updated' :
                                                    !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[2].toUpperCase() !== 'NONE' ?
                                                        `User Uploaded` : 'NOT Uploaded by User'
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="upload__main__card__btncontainer">
                                    <input

                                        id="vaccinationcard"
                                        name="vaccinationcard"
                                        ref={this.VaccinationRef}
                                        type="file"

                                        multiple={false}
                                        onChange={this.handleChange.bind(this)}
                                        className="upload__main__card__input"
                                    />

                                    <button
                                        className='btn upload__main__card__btn'
                                        onClick={() => {

                                            this.VaccinationRef.current.click()
                                        }}
                                    >
                                        {this.state.vaccinationcard === '' ? 'Upload' : 'Update'}
                                    </button>

                                </div>

                            </div>


                            <div className="upload__main__card">

                                <div className="upload__main__card__imgtextbox">
                                    <div className="upload__main__card__imgbox">
                                        <img
                                            src={referenceletter !== "" ?
                                                 (this.isIMage(referenceletter.name) ? this.state.referenceletterPreview : PDF) :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[3].toUpperCase() !== 'NONE' ?
                                                    (this.isIMage(JSON.parse(location.state.selected.data.Link_To_Files)[3]) ?
                                                        JSON.parse(location.state.selected.data.Link_To_Files)[3] : PDF) :
                                                    DefaultPreview
                                            }
                                            alt="Preview of uploaded file"
                                            className="upload__main__card__img"
                                        />
                                    </div>
                                    <div className="upload__main__card__textbox">
                                        <p className="upload__main__card__textbox__item--name">
                                            {referenceletter !== "" ?
                                                referenceletter.name.split('.')[0].slice(0, 35) + '...' :
                                                (!_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[3].toUpperCase() !== 'NONE' ? `${location.state.selected.data.Firstnames}'s Letter` : 'Reference Letter')
                                            }
                                        </p>
                                        <p className="upload__main__card__textbox__item--type">
                                            {vaccinationcard !== "" ?
                                                vaccinationcard.type :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[3].toUpperCase() !== 'NONE' ? this.getFileExtension(JSON.parse(location.state.selected.data.Link_To_Files)[3]).toUpperCase() : 'No Type'
                                            }
                                        </p>

                                        <p className="upload__main__card__textbox__item--size">
                                            {referenceletter !== "" ?
                                                `${Math.round(referenceletter.size / 1000)} kb` :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[3].toUpperCase() !== 'NONE' ? 'No metadata' : 'No Size'
                                            }
                                        </p>
                                        <p className="upload__main__card__textbox__item--dimensions">Reference / Inviting Letter</p>
                                        <p className="upload__main__card__textbox__item--priority">
                                            {
                                                referenceletter !== "" ?
                                                    'Admin Updated' :
                                                    !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[3].toUpperCase() !== 'NONE' ?
                                                        `User Uploaded` : 'NOT Uploaded by User'
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="upload__main__card__btncontainer">
                                    <input

                                        id="referenceletter"
                                        name="referenceletter"
                                        ref={this.ReferenceRef}
                                        type="file"

                                        multiple={false}
                                        onChange={this.handleChange.bind(this)}
                                        className="upload__main__card__input"
                                    />

                                    <button
                                        className='btn upload__main__card__btn'
                                        onClick={() => {
                                            this.ReferenceRef.current.click()
                                        }}
                                    >
                                        {this.state.referenceletter === '' ? 'Upload' : 'Update'}
                                    </button>

                                </div>

                            </div>


                            <div className="upload__main__card">

                                <div className="upload__main__card__imgtextbox">
                                    <div className="upload__main__card__imgbox">
                                        <img
                                            src={booking !== "" ?
                                            (this.isIMage(booking.name) ? this.state.bookingPreview : PDF) :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[4].toUpperCase() !== 'NONE' ?
                                                    (this.isIMage(JSON.parse(location.state.selected.data.Link_To_Files)[4]) ?
                                                        JSON.parse(location.state.selected.data.Link_To_Files)[4] : PDF) :
                                                    DefaultPreview
                                            }
                                            alt="Preview of uploaded file"
                                            className="upload__main__card__img"
                                        />
                                    </div>
                                    <div className="upload__main__card__textbox">
                                        <p className="upload__main__card__textbox__item--name">
                                            {booking !== "" ?
                                                booking.name.split('.')[0].slice(0, 35) + '...' :
                                                (!_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[4].toUpperCase() !== 'NONE' ? `${location.state.selected.data.Firstnames}'s Booking` : 'Hotel Booking')
                                            }
                                        </p>
                                        <p className="upload__main__card__textbox__item--type">
                                            {booking !== "" ?
                                                booking.type :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[4].toUpperCase() !== 'NONE' ? this.getFileExtension(JSON.parse(location.state.selected.data.Link_To_Files)[4]).toUpperCase() : 'No Type'
                                            }
                                        </p>

                                        <p className="upload__main__card__textbox__item--size">
                                            {booking !== "" ?
                                                `${Math.round(booking.size / 1000)} kb` :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[4].toUpperCase() !== 'NONE' ? 'No metadata' : 'No Size'
                                            }

                                        </p>
                                        <p className="upload__main__card__textbox__item--dimensions">Hotel Reservation</p>
                                        <p className="upload__main__card__textbox__item--priority">
                                            {
                                                booking !== "" ?
                                                    'Admin Updated' :
                                                    !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[4].toUpperCase() !== 'NONE' ?
                                                        `User Uploaded` : 'NOT Uploaded by User'
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="upload__main__card__btncontainer">
                                    <input

                                        id="booking"
                                        name="booking"
                                        ref={this.BookingRef}
                                        type="file"

                                        multiple={false}
                                        onChange={this.handleChange.bind(this)}
                                        className="upload__main__card__input"
                                    />

                                    <button
                                        className='btn upload__main__card__btn'
                                        onClick={() => {

                                            this.BookingRef.current.click()
                                        }}
                                    >
                                        {this.state.booking === '' ? 'Upload' : 'Update'}
                                    </button>

                                </div>

                            </div>


                            <div className="upload__main__card">

                                <div className="upload__main__card__imgtextbox">
                                    <div className="upload__main__card__imgbox">
                                        <img src={PDF} alt="Preview of uploaded file" className="upload__main__card__img" />
                                    </div>
                                    <div className="upload__main__card__textbox">
                                        <p className="upload__main__card__textbox__item--name">
                                            {registrationform !== "" ?
                                                registrationform.name.split('.')[0].slice(0, 35) + '...' :
                                                (!_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[5].toUpperCase() !== 'NONE' ? `${location.state.selected.data.Firstnames}'s Application` : 'Signed Application Form')
                                            }

                                        </p>
                                        <p className="upload__main__card__textbox__item--type">
                                            {registrationform !== "" ?
                                                registrationform.type :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[5].toUpperCase() !== 'NONE' ? this.getFileExtension(JSON.parse(location.state.selected.data.Link_To_Files)[5]).toUpperCase() : 'No Type'
                                            }
                                        </p>

                                        <p className="upload__main__card__textbox__item--size">
                                            {registrationform !== "" ?
                                                `${Math.round(registrationform.size / 1000)} kb` :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[5].toUpperCase() !== 'NONE' ? 'No metadata' : 'No Size'
                                            }
                                        </p>
                                        <p className="upload__main__card__textbox__item--dimensions">Signed Application Form</p>
                                        <p className="upload__main__card__textbox__item--priority">
                                            {
                                                registrationform !== "" ?
                                                    'Admin Updated' :
                                                    !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[5].toUpperCase() !== 'NONE' ?
                                                        `User Uploaded` : 'NOT Uploaded by User'
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="upload__main__card__btncontainer">
                                    <input

                                        id="registrationform"
                                        name="registrationform"
                                        ref={this.RegformRef}
                                        type="file"

                                        multiple={false}
                                        onChange={this.handleChange.bind(this)}
                                        className="upload__main__card__input"
                                    />

                                    <button
                                        className='btn upload__main__card__btn'
                                        onClick={() => {

                                            this.RegformRef.current.click()
                                        }}
                                    >
                                        {this.state.registrationform === '' ? 'Upload' : 'Update'}
                                    </button>

                                </div>
                            </div>


                            <div className="upload__main__card">

                                <div className="upload__main__card__imgtextbox">
                                    <div className="upload__main__card__imgbox">
                                        <img
                                            src={visapay !== "" ?
                                            (this.isIMage(visapay.name) ? this.state.visapayPreview : PDF) :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[6].toUpperCase() !== 'NONE' ?
                                                    (this.isIMage(JSON.parse(location.state.selected.data.Link_To_Files)[6]) ?
                                                        JSON.parse(location.state.selected.data.Link_To_Files)[6] : PDF) :
                                                    DefaultPreview
                                            }
                                            alt="Preview of uploaded file"
                                            className="upload__main__card__img"
                                        />
                                    </div>
                                    <div className="upload__main__card__textbox">
                                        <p className="upload__main__card__textbox__item--name">
                                            {visapay !== "" ?
                                                visapay.name.split('.')[0].slice(0, 35) + '...' :
                                                (!_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[6].toUpperCase() !== 'NONE' ? `${location.state.selected.data.Firstnames}'s Visa Payment` : 'Visa Payment')
                                            }
                                        </p>
                                        <p className="upload__main__card__textbox__item--type">
                                            {visapay !== "" ?
                                                visapay.type :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[6].toUpperCase() !== 'NONE' ? this.getFileExtension(JSON.parse(location.state.selected.data.Link_To_Files)[6]).toUpperCase() : 'No Type'
                                            }
                                        </p>
                                        <p className="upload__main__card__textbox__item--size">
                                            {visapay !== "" ?
                                                `${Math.round(visapay.size / 1000)} kb` :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[6].toUpperCase() !== 'NONE' ? 'No metadata' : 'No Size'
                                            }
                                        </p>
                                        <p className="upload__main__card__textbox__item--dimensions">Visa payment Evidence</p>
                                        <p className="upload__main__card__textbox__item--priority">
                                            {
                                                visapay !== "" ?
                                                    'Admin Updated' :
                                                    !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[6].toUpperCase() !== 'NONE' ?
                                                        `User Uploaded` : 'NOT Uploaded by User'
                                            }
                                        </p>

                                    </div>
                                </div>
                                <div className="upload__main__card__btncontainer">
                                    <input

                                        id="visapay"
                                        name="visapay"
                                        ref={this.VisapayRef}
                                        type="file"

                                        multiple={false}
                                        onChange={this.handleChange.bind(this)}
                                        className="upload__main__card__input"
                                    />

                                    <button
                                        className='btn upload__main__card__btn'
                                        onClick={() => {

                                            this.VisapayRef.current.click()
                                        }}
                                    >
                                        {this.state.visapay === '' ? 'Upload' : 'Update'}
                                    </button>

                                </div>

                            </div>

                            <div className="upload__main__card">

                                <div className="upload__main__card__imgtextbox">
                                    <div className="upload__main__card__imgbox">
                                        <img src={PDF} alt="Preview of uploaded file" className="upload__main__card__img" />
                                    </div>
                                    <div className="upload__main__card__textbox">
                                        <p className="upload__main__card__textbox__item--name">
                                            {others !== "" ?
                                                others.name.split('.')[0].slice(0, 35) + '...' :
                                                (!_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[7].toUpperCase() !== 'NONE' ? `${location.state.selected.data.Firstnames}'s Others` : 'Other Documents')
                                            }
                                        </p>
                                        <p className="upload__main__card__textbox__item--type">
                                            {others !== "" ?
                                                others.type :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[7].toUpperCase() !== 'NONE' ? this.getFileExtension(JSON.parse(location.state.selected.data.Link_To_Files)[7]).toUpperCase() : 'No Type'
                                            }

                                        </p>

                                        <p className="upload__main__card__textbox__item--size">
                                            {others !== "" ?
                                                `${Math.round(others.size / 1000)} kb` :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[7].toUpperCase() !== 'NONE' ? 'No metadata' : 'No Size'
                                            }
                                        </p>
                                        <p className="upload__main__card__textbox__item--dimensions">Other Documents Not Listed</p>
                                        <p className="upload__main__card__textbox__item--priority">{
                                            others !== "" ?
                                                'Admin Updated' :
                                                !_.isEmpty(location) && JSON.parse(location.state.selected.data.Link_To_Files)[7].toUpperCase() !== 'NONE' ?
                                                    `User Uploaded` : 'NOT Uploaded by User'
                                        }</p>
                                    </div>
                                </div>
                                <div className="upload__main__card__btncontainer">
                                    <input

                                        id="others"
                                        name="others"
                                        ref={this.OthersRef}
                                        type="file"

                                        multiple={false}
                                        onChange={this.handleChange.bind(this)}
                                        className="upload__main__card__input"
                                    />

                                    <button
                                        className='btn upload__main__card__btn'
                                        onClick={() => {

                                            this.OthersRef.current.click()
                                        }}
                                    >
                                        {this.state.others === '' ? 'Upload' : 'Update'}
                                    </button>

                                </div>

                            </div>

                        </div>


                    </div>
                    <div
                        className="upload__submitcontainer"
                        onClick={
                            this.anyUpdated()  ?
                                () => this._handleSubmit() :
                                () => console.log('')

                        }

                    >
                        <button
                            className={`btn upload__btn ${this.anyUpdated() ? '': 'upload__btn__disabled'}`}
                        >
                            Submit
                        </button>

                        <button
                            className='btn btn--normal'
                            onClick={() => this.setState({ backPressed: true })}
                        >
                            Back
                        </button>
                    </div>


                </div>

                <Footer />
            </div>
        )
    }
}

export default UploadDocs