import React, { Component } from 'react'
import Header from './shared/Header';
import Footer from './shared/Footer';
import { useLocation } from 'react-router-dom'
import { Navigate } from 'react-router-dom';
import _ from 'lodash';
import Rodal from 'rodal';
import { ImFilter } from 'react-icons/im';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Label, LineChart, Line } from 'recharts';
import Sidebar from './shared/Sidebar';

let location;

const ALL_FILTERS = [
  {
    name: 'Application Status',
    categories: ['Received', 'Full', 'Pending', 'Refused', 'Processing', 'Archived', 'Deleted'],
    values: [0, 1, 2, 3, 4, 5, 6],
  },

  {
    name: 'Purpose Of Travel',
    categories: ['Family/Friend Visit', 'Foreign Press', 'Holiday/Tourism', 'Transit', 'Other'],
    values: ['Family/Friend Visit', 'Foreign Press', 'Holiday/Tourism', 'Transit', 'Other'],
  },

  {
    name: 'Passport Type',
    categories: ['Alien', 'Diplomatic', 'Official', 'Ordinary', 'Service', 'Travel Doc'],
    values: ['Alien Passport', 'Diplomatic Passports', 'Official Passport', 'Ordinary Passport', 'Service Passport', 'Travel Document'],
  },

  {
    name: 'Student Status',
    categories: ['Yes', 'No'],
    values: ['Yes', 'No'],
  },

  {
    name: 'Type Of Payment',
    categories: ['BNTB Counter Payment', 'BNTB Online Transfer'],
    values: ['BNTB Counter Payment', 'BNTB Online Transfer']
  },

  {
    name: 'Vaccination Status',
    categories: ['Yes', 'No'],
    values: ['Yes', 'No'],
  }

]


const UseLocation = () => {
  location = useLocation();
  return null;
}

export class Dashboard extends Component {

  constructor(props) {
    super(props)
    this.formatData = this.formatData.bind(this)
    this.setModalVisible = this.setModalVisible.bind(this)
  }

  state = {
    data: [],
    countArray: [],
    chartData: [],
    modalVisible: false,
    // CHART TYPES 'table'|| 'bar' || 'line'
    chartType: 0,

    /*
    1 -- BY APPLICATION STATUS
     */
    currentFilter: 0,
    navigate: false,
  }

  componentDidMount() {
    this.setState({ data: location.state.fullList }, () => {
      this.formatData(0)

    })
  }

  componentDidUpdate() {
    window.onpopstate = () => {
      this.setState({ navigate: true })
      // console.log('Back button pressed');
    }
  }


  formatData(selectedFilter) {
    // console.log('herehere')
    // console.log(this.state.data)
    this.setState({ currentFilter: selectedFilter }, () => {
      let countArrayTemp = []

      switch (this.state.currentFilter) {
        case 0:
          for (let y = 0; y < ALL_FILTERS[0].values.length; y++) {

            let filteredData = this.state.data.filter(x => x.data.STATUS === ALL_FILTERS[0].values[y])
            // console.log(filteredData)

            countArrayTemp.push(filteredData.length)
          }

          this.setState({ countArray: countArrayTemp, modalVisible: false })

          break;

        case 1:
          for (let y = 0; y < ALL_FILTERS[1].values.length; y++) {
            let filteredData = this.state.data.filter(x => x.data.Purpose === ALL_FILTERS[1].values[y])
            // console.log(filteredData)

            countArrayTemp.push(filteredData.length)

          }
          this.setState({ countArray: countArrayTemp, modalVisible: false })

          break;

        case 2:
          for (let y = 0; y < ALL_FILTERS[2].values.length; y++) {
            let filteredData = this.state.data.filter(x => x.data.Passport_Type === ALL_FILTERS[2].values[y])
            // console.log(filteredData)

            countArrayTemp.push(filteredData.length)

          }
          this.setState({ countArray: countArrayTemp, modalVisible: false })

          break;

        case 3:
          for (let y = 0; y < ALL_FILTERS[3].values.length; y++) {
            let filteredData = this.state.data.filter(x => x.data.Is_A_Student === ALL_FILTERS[3].values[y])
            // console.log(filteredData)

            countArrayTemp.push(filteredData.length)

          }
          this.setState({ countArray: countArrayTemp, modalVisible: false })

          break;

        case 4:
          for (let y = 0; y < ALL_FILTERS[4].values.length; y++) {
            let filteredData = this.state.data.filter(x => x.data.Pay_Method === ALL_FILTERS[4].values[y])
            // console.log(filteredData)

            countArrayTemp.push(filteredData.length)

          }
          this.setState({ countArray: countArrayTemp, modalVisible: false })

          break;

        case 5:
          for (let y = 0; y < ALL_FILTERS[5].values.length; y++) {
            let filteredData = this.state.data.filter(x => x.data.Yellow_Fever_Status === ALL_FILTERS[5].values[y])
            // console.log(filteredData)

            countArrayTemp.push(filteredData.length)

          }
          this.setState({ countArray: countArrayTemp, modalVisible: false })

          break;




        default:
          break;
      }

    })

  }

  formartChartData = (data, filterIndex) => {
    let chartArray = []
    for (let y = 0; y < data.length; y++) {
      chartArray.push({
        'category': ALL_FILTERS[filterIndex].categories[y],
        'value': data[y],
      })

    }
    // console.log(chartArray)

    return chartArray
  }

  setModalVisible(val) {
    this.setState({ modalVisible: val })
  }

  setCurrentFilter(val) {
    this.setState({ currentFilter: val })
  }

  handleChangeView() {
    const { chartType } = this.state;
    if (chartType === 2) {
      this.setState({ chartType: 0 })
    } else {
      this.setState({ chartType: chartType + 1 })
    }
  }

  render() {
    return (
      <div className='dashboard'>
        <UseLocation />
        <FilterRodal
          visible={this.state.modalVisible}
          setVisible={this.setModalVisible}
          formatData={this.formatData}
          currentFilter={this.state.currentFilter}
        // setCurrentFilter={this.setCurrentFilter}
        />
        {this.state.navigate &&
          <Navigate
            to="/task"
            state={{
              feesList: location.state.allFees,
              updateID: location.state.key,
              officersList: location.state.allOfficers,
              userList: location.state.fullList,
              officerData: location.state.officerData,
              
              showNotif: location.state.showNotif,
              notifBody: location.state.notifBody,
            }}
          />

        }

        <Header
          headerText="Dashboard"
          officerData={!_.isEmpty(location) ? location.state.officerData : []}
        />
        <div className="dashboard__main">
          <div className="dashboard__sidebar">

            <Sidebar
              users={!_.isEmpty(location) ? location.state.fullList : []}
              fees={!_.isEmpty(location) ? location.state.allFees : []}
              officerData={!_.isEmpty(location) ? location.state.officerData : []}

              officers={!_.isEmpty(location) ? location.state.allOfficers : []}
              updateID={!_.isEmpty(location) ? location.state.updateID : ''}

              showNotif={!_.isEmpty(location) ? location.state.showNotif : false}
              notifBody={!_.isEmpty(location) ? location.state.notifBody : ''}

            />
          </div>


          <div className="dashboard__container">
            <div className="dashboard__container__chart">
              {this.state.chartType === 0 ? (
                <TableView
                  countArray={this.state.countArray}
                  currentFilter={this.state.currentFilter}
                />
              ) : (
                this.state.chartType === 1 ? (
                  <MyBarChart data={this.formartChartData(this.state.countArray, this.state.currentFilter)} />
                ) : (
                  <MyLineChart data={this.formartChartData(this.state.countArray, this.state.currentFilter)} />
                )
              )

              }
            </div>


            <div className="dashboard__btnContainer">
              <button className="btn" onClick={() => this.handleChangeView()}>Change View</button>
              <button className="btn" onClick={() => this.setState({ modalVisible: true })}>Filter By</button>
            </div>

          </div>
        </div>


        <Footer />

      </div>
    )
  }
}




const TableView = (props) => {
  return (
    <div className='table__container'>


      <div class="tbl-header">
        <table cellpadding="0" cellspacing="0" border="0">

          <thead>
            <tr>
              <th>Category</th>
              <th>{`Number of Applications (${_.sum(props.countArray)})`}</th>

            </tr>
          </thead>

        </table>
      </div>
      <div class="tbl-content">
        <table cellpadding="0" cellspacing="0" border="0">
          <tbody>

            {props.countArray ? (
              props.countArray.map((countValue, index) => (
                // // console.log(applicant.contacts.length)


                <tr className="table_row" style={{ cursor: 'pointer' }}>
                  <td>{ALL_FILTERS[props.currentFilter].categories[index]}</td>

                  <td>{countValue}</td>

                </tr>

              )
              )
            ) : (
              <div />
            )
            }

          </tbody>
        </table>
      </div>
    </div>
  )
}


const MyBarChart = (props) => {
  // console.log(props);
  return (
    <div style={{ backgroundColor: 'black', padding: '2rem' }}>
      <BarChart width={750} height={400} data={props.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="category" tick={{ fill: '#fff', fontWeight: 'bold' }} />

        <YAxis tick={{ fill: '#fff', fontWeight: 'bold' }} />

        <Tooltip />

        <Bar dataKey="value" fill="#4166f5" />
      </BarChart>
    </div>
  )
}

const MyLineChart = (props) => {
  return (
    <div style={{ backgroundColor: 'black', padding: '2rem' }}>
      <LineChart width={780} height={400} data={props.data}
        margin={{ top: 5, right: 50, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="category" tick={{ fill: '#fff', fontWeight: 'bold' }} />
        <YAxis tick={{ fill: '#fff', fontWeight: 'bold' }} />
        <Tooltip />

        <Line type="monotone" dataKey="value" stroke="#4166f5" dot />

      </LineChart>
    </div>
  )
}

const FilterRodal = (props) => {
  let selectedFilter;
  return (
    <Rodal visible={props.visible} onClose={() => props.setVisible(false)} width={400} height={360}>
      <div className="filterrodal">

        <h2 className='filterrodal__header'> Select Filter</h2>

        <div className="filterrodal__form">

          <div className="filterrodal__item" onClick={() => props.formatData(0)}>
            <ImFilter className='filterrodal__item__icon' color={props.currentFilter === 0 ? 'red' : '#333'} />
            <p style={props.currentFilter === 0 ? { color: 'red', fontWeight: 'bold' } : {}}>By Application Status</p>
          </div>

          <div className="filterrodal__item" onClick={() => props.formatData(1)}>
            <ImFilter className='filterrodal__item__icon' color={props.currentFilter === 1 ? 'red' : '#333'} />
            <p style={props.currentFilter === 1 ? { color: 'red', fontWeight: 'bold' } : {}}>By Purpose of Travel</p>
          </div>

          <div className="filterrodal__item" onClick={() => props.formatData(2)}>
            <ImFilter className='filterrodal__item__icon' color={props.currentFilter === 2 ? 'red' : '#333'} />
            <p style={props.currentFilter === 2 ? { color: 'red', fontWeight: 'bold' } : {}}>By Passport Type</p>
          </div>

          <div className="filterrodal__item" onClick={() => props.formatData(3)}>
            <ImFilter className='filterrodal__item__icon' color={props.currentFilter === 3 ? 'red' : '#333'} />
            <p style={props.currentFilter === 3 ? { color: 'red', fontWeight: 'bold' } : {}}>By Student Status</p>
          </div>

          <div className="filterrodal__item" onClick={() => props.formatData(4)}>
            <ImFilter className='filterrodal__item__icon' color={props.currentFilter === 4 ? 'red' : '#333'} />
            <p style={props.currentFilter === 3 ? { color: 'red', fontWeight: 'bold' } : {}}>By Method Of Payment</p>
          </div>

          <div className="filterrodal__item" onClick={() => props.formatData(5)}>
            <ImFilter className='filterrodal__item__icon' color={props.currentFilter === 5 ? 'red' : '#333'} />
            <p style={props.currentFilter === 3 ? { color: 'red', fontWeight: 'bold' } : {}}>By Vaccination Status</p>
          </div>

        </div>

        <div className="filterrodal__btnbox" style={{ display: 'none', width: '0' }}>

          <button
            className='filterrodal__btn'
            onClick={() => props.formatData(selectedFilter)}
          >
            Set Filter
          </button>
        </div>
      </div>
    </Rodal>
  )
}



export default Dashboard