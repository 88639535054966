import React from 'react'
import { FcApprove, FcDisapprove } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom'
import { MdOutlineResetTv, MdArrowBack, MdEmail } from 'react-icons/md'
import { FaCloudUploadAlt } from 'react-icons/fa'
import { GiFiles } from 'react-icons/gi'
import { BsCalendar3 } from 'react-icons/bs'
import { doc, updateDoc } from "firebase/firestore";
import { db } from '../../utils/firebase'
import { useLocation } from 'react-router-dom';
import { MdDelete } from 'react-icons/md'

const resetPassword = (id, setShowSpinner) => {
    setShowSpinner(true)
    const docRef = doc(db, "applications", id);
    const data = {
        passwordUsed: false,
    }
    updateDoc(docRef, data).then(() => {
        setShowSpinner(false)
        // console.log('done')

    }).catch((err) => {
        // console.log(err)
    })

}


const handleStatusChange = (location, setShowSpinner, navigate) => {
    setShowSpinner(true)
    const docRef = doc(db, 'applications', location.state.selected.id)


    const data = { STATUS: 6 }

    updateDoc(docRef, data).then(() => {
        updateLocalDb(location, setShowSpinner, navigate, data)
        // console.log('done')

    }).catch((err) => {
        // console.log(err)
    })
    
}

const updateLocalDb = (location, setShowSpinner, navigate, data) => {

    // TAKE OUT APPROVED APPLICATION FROM  LIST OF APPROVED APPLICATIONS THEN PASS IT BACK TO THE FULL APPLICATION PAGE TO GET UPDATED LIST
    const newList = location.state.userList.filter(x => x.id !== location.state.selected.id)
    const allApplications = location.state.fullList;

    const selectedIndex = location.state.fullList.findIndex(x => x.id === location.state.selected.id)
    // console.log('ID Here ' + selectedIndex)
    // console.log(location.state.fullList[selectedIndex])

    const modifiedApplication = {
        id: location.state.fullList[selectedIndex].id,
        data: {
            ...location.state.fullList[selectedIndex].data,
            ...data
        }
    }
    // console.log(modifiedApplication)
    allApplications.splice(selectedIndex, 1, modifiedApplication)
    // console.log(allApplications)

    setShowSpinner(false);

    navigate('/fullapp', { state: { 
        userList: newList, 
        fullList: allApplications, 
        allFees: location.state.allFees, 
        officerData: location.state.officerData,

        allOfficers: location.state.allOfficers,
        updateID: location.state.updateID,

        showNotif: location.state.showNotif,
        notifBody: location.state.notifBody,
    } })
}





function Taskbar(props) {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <div className='taskbar'>

            <div
                className="taskbar__item__box"
                onClick={() => props.setShowRodal(true)}
            >
                <FcApprove className='taskbar__item__icon' />
                <span>Approve</span>
            </div>

            <div className="taskbar__item__box"
                onClick={() => props.setShowRefuseRodal(true)}
            >
                <FcDisapprove className='taskbar__item__icon' />
                <span>Refuse</span>
            </div>

            <div
                className="taskbar__item__box"
                onClick={() => resetPassword(props.location.state.selected.id, props.setShowSpinner)}
            >
                <MdOutlineResetTv className='taskbar__item__icon' color="white" />
                <span>Reset Password</span>
            </div>

            <div
                className="taskbar__item__box"
                onClick={() => props.setShowDocuments(!props.showDocuments)}
            >
                <GiFiles className='taskbar__item__icon' color='white' />
                <span>{props.showDocuments === true ? 'Hide Documents' : 'Show Documents'}</span>
            </div>

            <div
                className="taskbar__item__box"
                onClick={() => props.setShowMailRodal(true)}
                // onClick={() => window.open(`mailto:${props.location.state.selected.data.Email}`)}
            >
                <MdEmail className='taskbar__item__icon' color='white' />
                <span>Send Email</span>
            </div>

            <div
                className="taskbar__item__box"
                onClick={() => navigate('/bookappointments', { state: {...props.location.state}})}
            >
                <BsCalendar3 className='taskbar__item__icon' color='white' />
                <span>Book Appointment</span>
            </div>

            <div
                className="taskbar__item__box"
                onClick={() => navigate('/upload', { state: {...props.location.state}})}
            >
                <FaCloudUploadAlt className='taskbar__item__icon' color='white' />
                <span>Upload Docs</span>
            </div>

            <div
                className="taskbar__item__box"
                onClick={() => handleStatusChange(location, props.setShowSpinner, navigate)}

            >
                <MdDelete className='taskbar__item__icon' color='white' />
                <span>Delete</span>
            </div>

            <div
                className="taskbar__item__box"
                onClick={() => navigate(-1)}
            >
                <MdArrowBack className='taskbar__item__icon' color='white' />
                <span>Back</span>
            </div>

        </div>
    )
}

export default Taskbar