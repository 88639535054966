import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getStorage } from 'firebase/storage';

const firebaseConfig = JSON.parse(process.env.REACT_APP_API_SETUP)
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)

const storage = getStorage(app);


export {db, storage};