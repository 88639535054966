import React from 'react';
import PDF from '../img/pdf.png';
import Confidential from '../img/confidential.png';
import NoFile from '../img/nofile.png';

function ViewDocuments(props) {
    
    return (
        <div className='viewdocs'>

            <div class="row">
                <div class="col-1-of-3">
                    <div class="card">
                        <div class="card__side card__side--front">
                            <div class="card__picture" style={{ backgroundImage: `url(${props.fileSources[0]})` }}>
                                &nbsp;
                            </div>

                            <div class="card__details">
                                <ul>
                                    <li class="card__details__title">Passport Size Photo</li>
                                    <li class="card__details__type">File type:
                                        <span>{isIMage(props.fileSources[0]) ? 'Image' : 'PDF'}</span>
                                    </li>
                                    <li class="card__details__download">
                                        <button
                                            class="btn card__btn"
                                            onClick={() => window.open(props.fileSources[0])}
                                        >
                                            Download
                                        </button>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-1-of-3">
                    <div class="card">
                        <div class="card__side card__side--front">
                            <div 
                                class={isIMage(props.fileSources[1]) ? "card__picture": "card__picture card__picture__pdf"}
                                style={isIMage(props.fileSources[1]) ? { backgroundImage: `url(${props.fileSources[1]})` } : { backgroundImage: `url(${PDF})` }}
                            >
                                &nbsp;
                            </div>

                            <div class="card__details">
                                <ul>
                                    <li class="card__details__title">Passport Biodata</li>
                                    <li class="card__details__type">File type:
                                        <span>{isIMage(props.fileSources[1]) ? 'Image' : 'PDF'}</span>
                                    </li>
                                    <li class="card__details__download">
                                        <button
                                            class="btn card__btn"
                                            onClick={() => window.open(props.fileSources[1])}
                                        >
                                            Download
                                        </button>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-1-of-3">
                    <div class="card">
                        <div class="card__side card__side--front">
                            <div
                                class={isIMage(props.fileSources[2]) ? "card__picture": "card__picture card__picture__pdf"}
                                style={isIMage(props.fileSources[2]) ? { backgroundImage: `url(${props.fileSources[2]})` } : { backgroundImage: `url(${PDF})` }}
                            >
                                &nbsp;
                            </div>

                            <div class="card__details">
                                <ul>
                                    <li class="card__details__title">Vaccination Card</li>
                                    <li class="card__details__type">File type:
                                        <span>{isIMage(props.fileSources[2]) ? 'Image' : 'PDF'}</span>
                                    </li>
                                    <li class="card__details__download">
                                        <button
                                            class="btn card__btn"
                                            onClick={() => window.open(props.fileSources[2])}
                                        >
                                            Download
                                        </button>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>



            </div>

            <div class="row">

                <div class="col-1-of-3">
                    <div class="card">
                        <div class="card__side card__side--front">
                            <div
                                class={props.fileSources[3] === 'NONE' ? 'card__picture card__picture--noback' : isIMage(props.fileSources[3]) ? 'card__picture' : 'card__picture card__picture__pdf'}
                                style={props.fileSources[3] === 'NONE' ? {} : isIMage(props.fileSources[3]) ? { backgroundImage: `url(${props.fileSources[3]})`}: {backgroundImage: `url(${PDF})`}}

                            >
                                &nbsp;
                            </div>

                            <div class="card__details">
                                <ul>
                                    <li class="card__details__title">Reference Letter</li>
                                    <li class="card__details__type">File type:
                                        <span style={{ textTransform: 'uppercase' }}>{getFileExtension(props.fileSources[3])}</span>
                                    </li>
                                    <li class="card__details__download">
                                        <button
                                            class="btn card__btn"
                                            style={props.fileSources[3] === 'NONE' ? { pointerEvents: 'none' } : {}}
                                            onClick={() => window.open(props.fileSources[3])}
                                        >
                                            {props.fileSources[3] === 'NONE' ? 'No File' : 'Download'}
                                        </button>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-1-of-3">
                    <div class="card">
                        <div class="card__side card__side--front">
                            <div
                                class={props.fileSources[4] === 'NONE' ? 'card__picture card__picture--noback' : isIMage(props.fileSources[4]) ? 'card__picture' : 'card__picture card__picture__pdf'}
                                style={props.fileSources[4] === 'NONE' ? {} : isIMage(props.fileSources[4]) ? { backgroundImage: `url(${props.fileSources[4]})`}: {backgroundImage: `url(${PDF})`}}

                            >
                                &nbsp;
                            </div>

                            <div class="card__details">
                                <ul>
                                    <li class="card__details__title">Hotel Booking</li>
                                    <li class="card__details__type">File type:
                                        <span style={{ textTransform: 'uppercase' }}>{getFileExtension(props.fileSources[4])}</span>
                                    </li>
                                    <li class="card__details__download">
                                        <button
                                            class="btn card__btn"
                                            onClick={() => window.open(props.fileSources[4])}
                                            style={props.fileSources[4] === 'NONE' ? { pointerEvents: 'none' } : {}}
                                        >
                                            {props.fileSources[4] === 'NONE' ? 'No File' : 'Download'}
                                        </button>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-1-of-3">
                    <div class="card">
                        <div class="card__side card__side--front">
                            <div class="card__picture card__picture__pdf" style={{ backgroundImage: `url(${PDF})` }}>
                                &nbsp;
                            </div>

                            <div class="card__details">
                                <ul>
                                    <li class="card__details__title">Signed Form</li>
                                    <li class="card__details__type">File type: <span>PDF</span></li>
                                    <li class="card__details__download">
                                        <button
                                            class="btn card__btn"
                                            onClick={() => window.open(props.fileSources[5])}
                                        >
                                            Download
                                        </button>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div class="row">

                <div class="col-1-of-3">
                    <div class="card">
                        <div class="card__side card__side--front">
                            <div
                                class={props.fileSources[6] === 'NONE' ? 'card__picture card__picture--noback' : isIMage(props.fileSources[6]) ? 'card__picture' : 'card__picture card__picture__pdf'}
                                style={props.fileSources[6] === 'NONE' ? {} : isIMage(props.fileSources[6]) ? { backgroundImage: `url(${props.fileSources[6]})`}: {backgroundImage: `url(${PDF})`}}

                            >
                                &nbsp;
                            </div>

                            <div class="card__details">
                                <ul>
                                    <li class="card__details__title">Visa Payment</li>
                                    <li class="card__details__type">File type:
                                        <span style={{ textTransform: 'uppercase' }}>{getFileExtension(props.fileSources[6])}</span>
                                    </li>
                                    <li class="card__details__download">
                                        <button
                                            class="btn card__btn"
                                            onClick={() => window.open(props.fileSources[6])}
                                            style={props.fileSources[6] === 'NONE' ? { pointerEvents: 'none' } : {}}
                                        >
                                            {props.fileSources[6] === 'NONE' ? 'No File' : 'Download'}
                                        </button>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-1-of-3">
                    <div class="card">
                        <div class="card__side card__side--front">
                            <div class="card__picture card__picture__pdf" style={{ backgroundImage: `url(${PDF})` }}>
                                &nbsp;
                            </div>

                            <div class="card__details">
                                <ul>
                                    <li class="card__details__title">Other Docs</li>
                                    <li class="card__details__type">File type: <span>PDF</span></li>
                                    <li class="card__details__download">
                                        <button
                                            class="btn card__btn"
                                            style={props.fileSources[7] === 'NONE' ? { pointerEvents: 'none' } : {}}
                                            onClick={() => window.open(props.fileSources[7])}
                                        >
                                            {props.fileSources[7] === 'NONE' ? 'No File' : 'Download'}
                                        </button>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            </div>




            {props.approvedSources && props.approvedSources.length > 0 ? (

                <div className="custom_row__box">


                    <div class="custom_row">
                        {
                            props.approvedSources.map((x, index) => (

                                <div class="col-1-of-3">
                                    <div class="card">
                                        <div class="card__side card__side--front">
                                            <div class="card__picture card__picture--approval">
                                                &nbsp;
                                            </div>

                                            <div class="card__details">
                                                <ul>
                                                    <li class="card__details__title">Approval Document</li>
                                                    <li class="card__details__type">Uploaded By: <span>Admin</span></li>
                                                    <li class="card__details__download">
                                                        <button
                                                            class="btn card__btn"
                                                            onClick={() => window.open(props.approvedSources[index])}
                                                        >
                                                            View
                                                        </button>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            ))
                        }

                    </div>
                </div>

            ) : (
                <div />
            )


            }



        </div>
    )
}

function getFileExtension(fileName) {
    let extensionEnd;
    extensionEnd = fileName.replace(/^.*\./, '');
    const [fileExtension, fileEnd] = extensionEnd.split('?')
    return fileExtension;
}

function isIMage(fileName) {
    var fileExt = getFileExtension(fileName);
    var imagesExtension = ["png", "jpg", "jpeg", "PNG", "JPG", "JPEG"];
    if (imagesExtension.indexOf(fileExt) !== -1) {
        return true;
    } else {
        return false;
    }
}

export default ViewDocuments;