import React from 'react'
import Rodal from 'rodal';
import Caution from '../../img/caution.png';

function SafeModal(props) {
    return (
        <Rodal visible={props.visible} onClose={() => props.setVisible(false)} width={500} height={300}>
            <div className='safemodal'>
                <div className="safemodal__iconbox">
                    <img src={Caution} alt="Caution for action" className="safemodal__icon" />
                </div>
                <div className="safemodal__content">
                    Do you want this Appointment Email to be sent to the applicant
                </div>

                <div className="safemodal__btnsection">
                    <button 
                        className="btn safemodal__btn"
                        onClick={() => props.exec()}
                    >
                        &#10003; Yes
                    </button>

                    <button 
                        className="btn safemodal__btn"
                        onClick={() => props.setVisible(false)}
                    >
                        &#128473; No
                    </button>
                </div>
            </div>
        </Rodal>
    )
}

export default SafeModal