import React, { Component } from 'react'
import { useLocation } from 'react-router-dom'
import { Navigate } from 'react-router-dom';
import Sidebar from './shared/Sidebar';
import Header from './shared/Header'
import { BsFillMenuButtonWideFill } from 'react-icons/bs'
import { TbArrowsSort } from 'react-icons/tb'
import _ from 'lodash';

import { collection, getDocs } from "firebase/firestore";
import { db } from '../utils/firebase'
import { AiOutlineCloudSync } from 'react-icons/ai'

let location;

const UseLocation = () => {
    location = useLocation();
    // console.log(location.state)
    return null;
}

const fireBaseTime = (x) => _.isEmpty(x) ? '' : new Date(
    x.seconds * 1000 + x.nanoseconds / 1000000,
).toDateString();

export class Refusal extends Component {

    constructor(props) {
        super(props)
        this.handleDataSet = this.handleDataSet.bind(this)
    }

    componentDidMount() {
        this.setState({ data: location.state.userList, fullList: location.state.fullList }, () => {
            this.sortByArrivalDate()
        })
    }

    state = {
        data: [],
        selected: {},
        navigate: false,

        loading: false,
        fullList: [],
    }

    handleDataSet(filteredData) {
        this.setState({ data: filteredData })
    }

    handleSortByDate() {
        const { data } = this.state;
        let sortData = data;
        sortData.sort((a, b) => b.data.created.seconds - a.data.created.seconds)
        // console.log(sortData);
        this.setState({ data: sortData })
    }

    sortByArrivalDate() {
        const { data } = this.state;
        // console.log(data)
        let sortData = data;
        sortData.sort((a, b) => a.data.Expected_Arrival_Date.seconds - b.data.Expected_Arrival_Date.seconds)
        // console.log(sortData);
        this.setState({ data: sortData })
    }


    render() {

        return (
            <div
                className='fullapp__box'
                style={this.state.loading ? { opacity: '0.6', pointerEvents: 'none' } : {}}
            >
                <UseLocation />
                {this.state.navigate &&
                    <Navigate
                        to="/viewrefusal"
                        state={{

                            selected: this.state.selected,
                            userList: this.state.data,
                            fullList: this.state.fullList,
                            officerData: location.state.officerData,

                            allFees: location.state.allFees,
                            allOfficers: location.state.allOfficers,
                            updateID: location.state.updateID,

                            showNotif: location.state.showNotif,
                            notifBody: location.state.notifBody,

                        }}
                    />}

                <Header
                    showSearch={true}
                    dataSet={this.state.data}
                    setData={this.handleDataSet}
                    officerData={!_.isEmpty(location) ? location.state.officerData : []}
                />
                <Sidebar
                    users={this.state.fullList}
                    fees={!_.isEmpty(location) ? location.state.allFees : []}
                    officerData={!_.isEmpty(location) ? location.state.officerData : []}

                    officers={!_.isEmpty(location) ? location.state.allOfficers : []}
                    updateID={!_.isEmpty(location) ? location.state.updateID : ''}

                    showNotif={!_.isEmpty(location) ? location.state.showNotif : false}
                    notifBody={!_.isEmpty(location) ? location.state.notifBody : ''}
                />
                <div className="fullapp">

                    <section>

                        <h1>REFUSED APPLICATIONS</h1>
                        <div class="tbl-header">
                            <table cellpadding="0" cellspacing="0" border="0">
                                <thead>
                                    <tr className='fullapp__headbox'>
                                        <th colSpan={3} className='received__head' onClick={() => this.handleSync()}>
                                            <p>Sync Database</p>
                                            <AiOutlineCloudSync className='received__head__icon' size={16} />
                                        </th>
                                        <th colSpan={3} className='fullapp__head' onClick={() => this.handleSortByDate()}>
                                            <p>Sort By Date</p>
                                            <TbArrowsSort className='fullapp__head__icon' size={20} />
                                        </th>


                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th>Application ID</th>
                                        <th>Date</th>
                                        <th>First Name</th>
                                        <th>Surname</th>
                                        <th>Arrival Date</th>


                                        <th>Nationality</th>

                                    </tr>
                                </thead>

                            </table>
                        </div>
                        <div class="tbl-content">
                            <table cellpadding="0" cellspacing="0" border="0">
                                <tbody>

                                    {this.state.data ? (
                                        this.state.data.map((applicant, index) => (
                                            // // console.log(applicant.contacts.length)


                                            <tr className="table_row" style={{ cursor: 'pointer' }} onClick={() => this.setSelectedData(index)}>
                                                <td>{applicant.data.ID}</td>
                                                <td>{fireBaseTime(applicant.data.created)}</td>
                                                <td>{applicant.data.Firstnames}</td>
                                                <td>{applicant.data.Surname}</td>
                                                <td>{fireBaseTime(applicant.data.Expected_Arrival_Date)}</td>



                                                <td>{applicant.data.Nationality}</td>

                                            </tr>

                                        ))) : (
                                        <div />
                                    )
                                    }

                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
            </div>
        )
    }

    //  HELPERS
    setSelectedData(index) {
        this.setState({ selected: this.state.data[index] }, () => {
            this.setState({ navigate: true })
            // // console.log('yetyet')
        })

    }

    handleSync() {

        let fullList = [];
        this.setState({ loading: true }, async () => {
            const applicationsQuerySnapshot = await getDocs(collection(db, "applications"));
            applicationsQuerySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                fullList.push({ id: doc.id, data: doc.data() })
            });

            const filteredData = fullList.filter(x => x.data.STATUS === 3)
            // console.log('received applications array')
            // console.log(filteredData)

            this.setState({ fullList: fullList, data: filteredData.reverse() }, () => this.setState({ loading: false }))

        })


    }

}

export default Refusal;