import React, { useState } from 'react'
import Header from './shared/Header';
import Spinner from './shared/Spinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from '../utils/firebase';
import { doc, updateDoc } from "firebase/firestore";
import Template from './shared/Template';
import ViewDocuments from './ViewDocuments';
// import Taskbar from './shared/Taskbar';
import Footer from './shared/Footer';
import { FcApprove, FcDisapprove } from 'react-icons/fc'
import { MdArrowBack, MdEmail } from 'react-icons/md'
import { GiFiles } from 'react-icons/gi'
import {AiOutlineComment} from 'react-icons/ai'
import Rodal from 'rodal';


function ViewAccepted(props) {

    const navigate = useNavigate();
    const location = useLocation();
    // console.log(location.state.selected.data)
    const [showSpinner, setShowSpinner] = useState(false)
    const [showDocuments, setShowDocuments] = useState(false)
    const [showMessageRodal, setShowMessageRodal] = useState(false)

    const imageLinks = JSON.parse(location.state.selected.data.Link_To_Files)
    const isApproved = location.state.selected.data.Approved_Notes !== undefined
    // console.log(isApproved)
    const docsLink = isApproved ?
        location.state.selected.data.Approved_File_Link === "" ? [] : JSON.parse(location.state.selected.data.Approved_File_Link) :
        location.state.selected.data.Refused_File_Link === "" ? [] : JSON.parse(location.state.selected.data.Refused_File_Link)
    // console.log(imageLinks)
    // console.log(docsLink)

    return (
        <div className='viewaccepted'>
            <Spinner showSpinner={showSpinner} />
            <MessageRodal
                visible={showMessageRodal}
                setVisible={setShowMessageRodal}
                headerText="OFFICER REMARKS"
                message={isApproved ? location.state.selected.data.Approved_Notes : location.state.selected.data.Refused_Notes}

            />
            <Header
                officerData={location.state.officerData}
            />


            <div className='viewaccepted__mainview' style={showSpinner ? { display: 'none', width: '0' } : {}}>
                <div className="viewaccepted__mainview__template">
                    <Template location={location.state.selected} showSpinner={showSpinner} />
                </div>
                <div className="viewaccepted__mainview__taskbar">
                    <Taskbar
                        isApproved={isApproved}
                        setVisible={setShowMessageRodal}
                        showDocuments={showDocuments}
                        setShowDocuments={setShowDocuments}
                        showSpinner={showSpinner}
                        setShowSpinner={setShowSpinner}

                    />
                </div>
            </div>

            {showDocuments && !showSpinner &&
                <ViewDocuments
                    fileSources={imageLinks}
                    approvedSources={docsLink}
                />
            }
            <Footer />

        </div>
    )
}

const handleStatusChange = (isApprove, location, setShowSpinner, navigate) => {
    setShowSpinner(true)
    const docRef = doc(db, 'applications', location.state.selected.id)

    let data;
    data = isApprove ? { STATUS: 4 } : { STATUS: 3 }
    

    updateDoc(docRef, data).then(() => {
        updateLocalDb(location, setShowSpinner, navigate, data)
        setShowSpinner(false)
        // console.log('done')

    }).catch((err) => {
        // console.log(err)
    })

    
}

const updateLocalDb = (location, setShowSpinner, navigate, data) => {

    // TAKE OUT APPROVED APPLICATION FROM  LIST OF APPROVED APPLICATIONS THEN PASS IT BACK TO THE FULL APPLICATION PAGE TO GET UPDATED LIST
    const newList = location.state.userList.filter(x => x.id !== location.state.selected.id)
    const allApplications = location.state.fullList;

    const selectedIndex = location.state.fullList.findIndex(x => x.id === location.state.selected.id)
    // console.log('ID Here ' + selectedIndex)
    // console.log(location.state.fullList[selectedIndex])

    const modifiedApplication = {
        id: location.state.fullList[selectedIndex].id,
        data: {
            ...location.state.fullList[selectedIndex].data,
            ...data
        }
    }
    // console.log(modifiedApplication)
    allApplications.splice(selectedIndex, 1, modifiedApplication)
    // console.log(allApplications)

    setShowSpinner(false);

    navigate('/pending', {
        state: {
            userList: newList,
            fullList: allApplications,
            allFees: location.state.allFees,
            officerData: location.state.officerData,

            allOfficers: location.state.allOfficers,
            updateID: location.state.updateID,

            showNotif: location.state.showNotif,
            notifBody: location.state.notifBody,
        }
    })
}


const MessageRodal = (props) => {
    const location = useLocation();
    const navigate = useNavigate()
    return (
        <Rodal visible={props.visible} onClose={() => props.setVisible(false)} width={500} height={350}>
            <div className="visaform">

                <h2 className='visaform__header'> {props.headerText}</h2>

                <div className="visaform__form">

                    <textarea
                        name="note"
                        id="note"
                        cols="30"
                        rows="10"
                        className="visaform__form__input"
                        value={props.message}
                        disabled
                    // onChange={(e) => props.setDenialReason(e.target.value)}
                    />

                </div>

                <div className="visaform__btnbox">

                    <button
                        className='visaform__btn'
                        onClick={() => props.setVisible(false)}
                    >
                        Close
                    </button>
                </div>
            </div>
        </Rodal>
    )
}


const Taskbar = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isNotAdmin = location.state.officerData.role === 2

    return (
        <div className='taskbar'>

            <div
                className="taskbar__item__box"
                onClick={() => props.setVisible(true)}
            >
                <AiOutlineComment className='taskbar__item__icon' color='white' />
                <span>View Remarks</span>
            </div>


            <div
                className="taskbar__item__box"
                onClick={() => {
                    isNotAdmin ? 
                    console.log('') :
                    handleStatusChange(true, location, props.setShowSpinner, navigate)}
                }

                style={ isNotAdmin ? {cursor: 'not-allowed'} : {}}
            >
                <FcApprove className='taskbar__item__icon' />
                <span>Approve</span>
            </div>

            <div
                className="taskbar__item__box"
                onClick={() => {
                    isNotAdmin ? 
                    console.log('') :
                    handleStatusChange(false, location, props.setShowSpinner, navigate)}
                }
                style={ isNotAdmin ? {cursor: 'not-allowed'} : {}}
            >
                <FcDisapprove className='taskbar__item__icon' />
                <span>Refuse</span>
            </div>


            <div
                className="taskbar__item__box"
                onClick={() => props.setShowDocuments(!props.showDocuments)}

            >
                <GiFiles className='taskbar__item__icon' color='white' />
                <span>{props.showDocuments === true ? 'Hide Documents' : 'Show Documents'}</span>
            </div>

            <div
                className="taskbar__item__box"
                onClick={() => window.open(`mailto:${location.state.selected.data.Email}`)}
            >
                <MdEmail className='taskbar__item__icon' color='white' />
                <span>Send Email</span>
            </div>

            <div
                className="taskbar__item__box"
                onClick={() => navigate(-2)}
            >
                <MdArrowBack className='taskbar__item__icon' color='white' />
                <span>Back</span>
            </div>

        </div>
    )
}

export default ViewAccepted;