import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Header from './shared/Header';
import Footer from './shared/Footer';
import task1 from '../img/avatar.png';
import task2 from '../img/fees.png';
import task3 from '../img/officers.png';
import task4 from '../img/internet.png';
import task5 from '../img/letter.png';
import task55 from '../img/letterr.png';
import _ from 'lodash';
import { useState } from 'react';
import Sidebar from './shared/Sidebar';



function Settings(props) {
  const location = useLocation();
  // console.log(location.state)
  const navigate = useNavigate();
  // INCREASE FIRST WHEN COMPONENTS MOUNTS SO WE CATCH WHEN THE USER NAVIGATED BACK TO THIS PAGE, AT THAT TIME IT WOULD BE ONE.
  // THE USEEFFECT FUNCTION RUNS WHENEVER WE PRESS THE BACK ON A DIFFERENT PAGE AND IT LANDS HERE.
  // WE HAVE TO TELL IT, HEY THIS BACK IS NOT FROM YOUR PAGE
  // const [popState, setPopState] = useState(0);

  /*
    useEffect(() => {
      // { userList: userList, feesList: utils[0].data.ALL_FEES, officersList: utils[0].data.ALL_OFFICERS, updateID: utils[0].id }
    
  
     window.onpopstate = () => {
        if (popState > 0) {
          navigate('/task', {state: {
              
            officersList: location.state.allOfficers,
            
            updateID: location.state.updateID,
  
            feesList: location.state.allFees,
            userList: location.state.fullList,
        }})
        
       // console.log(location.state.feesList)
        // console.log('Back button pressed');
  
        } else {
          setPopState(popState+1)
        }
          
          
      }
  })
  */

  // // console.log(this.props)

  const navigateTo = (index) => {
    switch (index) {
      case 0:
        navigate('/usersettings', {
          state: {
            data: location.state.allOfficers, key: location.state.updateID, allFees: location.state.allFees,
            fullList: location.state.fullList, officerData: location.state.officerData,
            showNotif: location.state.showNotif, notifBody: location.state.notifBody
          }
        }
        )

        break;

      case 1:
        navigate('/adminsettings', {
          state: {
            data: location.state.allOfficers, key: location.state.updateID, allFees: location.state.allFees,
            fullList: location.state.fullList, officerData: location.state.officerData,
            showNotif: location.state.showNotif, notifBody: location.state.notifBody
          }
        }
        )
        break;

      case 2:
        navigate('/feesettings', {
          state: {
            data: location.state.allFees, key: location.state.updateID, allOfficers: location.state.allOfficers,
            fullList: location.state.fullList, officerData: location.state.officerData,
            showNotif: location.state.showNotif, notifBody: location.state.notifBody
          }
        }
        )

        break;

      case 3:
        navigate('/notifications', {
          state: {
            allFees: location.state.allFees, key: location.state.updateID, allOfficers: location.state.allOfficers,
            fullList: location.state.fullList, officerData: location.state.officerData,
            showNotif: location.state.showNotif, notifBody: location.state.notifBody
          }
        }
        )
        break;

      default:
        break;
    }
  }

  return (
    <div className='settings'>

      <Header headerText="SETTINGS" officerData={!_.isEmpty(location) ? location.state.officerData : []} />
      <div className="settings__mainbox">
        <Sidebar
          users={!_.isEmpty(location) ? location.state.fullList : []}
          fees={!_.isEmpty(location) ? location.state.allFees : []}
          officerData={!_.isEmpty(location) ? location.state.officerData : []}

          officers={!_.isEmpty(location) ? location.state.allOfficers : []}
          updateID={!_.isEmpty(location) ? location.state.updateID : ''}

          showNotif={!_.isEmpty(location) ? location.state.showNotif : false}
          notifBody={!_.isEmpty(location) ? location.state.notifBody : ''}
        />
        <div className="settings__container">

          <div
            to='/usersettings'
            onClick={() => navigateTo(0)}
            className='settings__item'
          >
            <img src={task3} alt="" className="settings__item__icon" />
            <p>Officer Management</p>
          </div>

          <div
            to='/adminsettings'
            onClick={() => navigateTo(1)}
            className='settings__item'
            style={location.state.officerData.role === 2 ? { display: 'none', width: '0' } : {}}
          >
            <img src={task1} alt="" className="settings__item__icon" />
            <p>Admin Management</p>
          </div>


          <div
            to='/feesettings'
            onClick={() => navigateTo(2)}
            className='settings__item'
          >

            <img src={task2} alt="" className="settings__item__icon" />
            <p>Fees Management</p>

          </div>

          <div
            to='/appnotifs'
            onClick={() => navigateTo(3)}
            className='settings__item'
          >

            <img src={task4} alt="" className="settings__item__icon" />
            <p>App Notifications</p>

          </div>

          <div
            
            onClick={() => window.open('https://dashboard.emailjs.com/sign-in')}
            className='settings__item'
          >

            <img src={task5} alt="" className="settings__item__icon" />
            <p>Emailjs</p>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  )

}

export default Settings;