import './sass/main.scss';
import WebFont from 'webfontloader'
import Navigator from './Navigator';

function App() {
  WebFont.load({
    google: {
      families: ['Nunito', 'Sora']
    }
  });
  return (
    <Navigator />
  );
}

export default App;
