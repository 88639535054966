import React from 'react'
import Logo from '../../img/logo.png';
import _ from 'lodash';


const fireBaseTime = (x) => _.isEmpty(x) ? '' : new Date(
    x.seconds * 1000 + x.nanoseconds / 1000000,
).toDateString();


function Template(props) {
    // console.log(props)
    return (
        <div className='viewapp__container'>

            <div className="viewapp__card" style={props.showSpinner ? { display: 'none', width: '0' } : {}}>

                <div className="viewapp__head">
                    <div className="viewapp__head__logobox">
                        <img src={Logo} alt="Logo of the company" className="viewapp__head__img" />
                        <div className="viewapp__head__sidebox">
                            <p className="viewapp__head__text">Honorary Consulate of Ghana</p>
                            <p className="viewapp__head__text">Hamilton Bremuda</p>
                        </div>
                    </div>
                    {
                        <p className="viewapp__head__price">{fireBaseTime(props.location.data.created)}</p>
                    }
                </div>

                {/*
                        <div className="viewapp__barcode">
                        <img src={Barcode} alt="" className="viewapp__barcode__img" />
                        <p className="viewapp__barcode__id">{props.location.data.ID}</p>
                        </div>
                    */}

                <div className="viewapp__top">
                    VISA APPLICATION
                </div>
                {
                    /*
                    <div className="viewapp__top">
          
                    <div className="viewapp__top__labels">
                      <p className="viewapp__top__labels__item">Visa Number:</p>
                      <p className="viewapp__top__labels__item">Visa Type:</p>
                      <p className="viewapp__top__labels__item">Visa Fee($):</p>
                      <p className="viewapp__top__labels__item">Receipt No:</p>
                      <p className="viewapp__top__labels__item">Date of Issue:</p>
                      <p className="viewapp__top__labels__item">Issuing Officer:</p>
                    </div>
          
                    <div className="viewapp__top__heading">
                      <p className="viewapp__top__heading__main">Application For Ghana Visa</p>
                      <p className="viewapp__top__heading__sub">
                        Please read the guidance notes carefully before completing this form.
                        This form must be completed electronically.All fields marked asterisk &#10059;
                        are mandatory.
                        All information must be typed in English and all dates in format dd/mm/yyyy
                      </p>
                    </div>
          
                    <div className="viewapp__top__passport">
                      Passport Photo
                    </div>
                  </div>
                    */
                }



                <div className="viewapp__section__container">

                    <div className="viewapp__section">
                        <p className="viewapp__section__head">Part 1: Personal Information</p>

                        <div className="p viewapp__section__box">

                            <div className="viewapp__col14 viewapp__col12 u-no-border-right">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(a) First names</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Firstnames}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(b) Prev./Other Name(s)</span>
                                    <span className="viewapp__col14__required">&nbsp;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Othername}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12 u-no-border-right">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(c) Surname</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Surname}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(d) Date of Birth </span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {fireBaseTime(props.location.data.Date_Of_Birth)}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12 u-no-border-right">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(e) Place of birth</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Place_Of_Birth}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(f) Country of Birth</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Country_Of_Birth}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12 u-no-border-right">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(g) Contact Telephone</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Contact}
                                </div>
                            </div>

                            

                            <div className="viewapp__col14 viewapp__col12">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(h) Occupation</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Occupation}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12 u-no-border-right">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(i) Full Residential Address</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.ResidentialAddress}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12 u-margin-bottom">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(j) Are You a Student</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Is_A_Student}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12 u-margin-bottom u-no-border-right">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(k) Name Of Academic Instituition</span>
                                    <span className="viewapp__col14__required">{props.location.data.isastudent === "Yes" ? `*` : ''}</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Academic}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(l) Name of Employer / Previous Employer</span>
                                    <span className="viewapp__col14__required">&nbsp;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Employer}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12 u-no-border-right">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(m) Contact Email</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Email}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(n) Confirm Contact Email</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Email}
                                </div>
                            </div>



                        </div>

                    </div>

                    <div className="viewapp__section">
                        <p className="viewapp__section__head">Part 2: Travel Information</p>

                        <div className="viewapp__section__box">

                            <div className="viewapp__col14 viewapp__col12 u-no-border-right">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(a) Visa Required</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Visatype}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(b) Purpose of Trip</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Purpose}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col11">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(c) If Other (Please State)</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Other_Purpose}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12  u-no-border-right">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(d) Travel Passport Nationality</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Nationality}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(e) Type of Passport </span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Passport_Type}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12 u-no-border-right">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(f) Passport Number</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Passport_Number}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(g) Passport Issued At</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Passport_Issued_At}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col11">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(h) Passport Expiry</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">

                                    {fireBaseTime(props.location.data.Passport_Expiry)}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12 u-no-border-right">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(i) Other/Prev. Nationality (1)</span>
                                    <span className="viewapp__col14__required">&nbsp;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Other_Nationality}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(j) Other/Prev. Nationality (2)</span>
                                    <span className="viewapp__col14__required">&nbsp;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Other_Nationality2}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12 u-no-border-right">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(k) Method Of Payment</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Pay_Method}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(l) Source of funding</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Funding_Source}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12 u-no-border-right">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(m) Previously Visited Ghana</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Previous_Visit}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(n) Date of last Visit</span>
                                    <span className="viewapp__col14__required">&nbsp;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {fireBaseTime(props.location.data.Date_Of_Previous_Visit)}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12 u-no-border-right">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(o) Mode Of Travel</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Mode_Of_Travel}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(p) Expected Arrival Date</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {fireBaseTime(props.location.data.Expected_Arrival_Date)}
                                </div>
                            </div>

                            <div className="viewapp__col13__container">

                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(q) Full Name, Phone Number And Address of Host in Ghana</span>
                                    <span className="viewapp__col14__required">&nbsp;</span>
                                </div>

                                <div className="viewapp__col13__box u-no-border">

                                    <div className="viewapp__col13__info">
                                        {props.location.data.Host_FullName}
                                    </div>


                                    <div className="viewapp__col13__info u-no-border">
                                        {props.location.data.Host_Address}
                                    </div>

                                    <div className="viewapp__col13__info u-no-border">
                                        {props.location.data.Host_Contact}
                                    </div>

                                </div>

                            </div>


                            <div className="viewapp__col13__container">

                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(r) Address/Hotel Where Applicant Will Be Staying</span>
                                    <span className="viewapp__col14__required">&nbsp;</span>
                                </div>

                                <div className="viewapp__col13__box u-no-border">

                                    <div className="viewapp__col13__info u-no-border">
                                        {props.location.data.Hotel_FullName}
                                    </div>


                                    <div className="viewapp__col13__info u-no-border">

                                        {props.location.data.Hotel_Address}

                                    </div>

                                    <div className="viewapp__col13__info u-no-border">


                                        {props.location.data.Hotel_Contact}

                                    </div>

                                </div>
                            </div>

                            <div className="viewapp__col13__container">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(s) Name & Contact of Next-of-Kin</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>


                                <div className="viewapp__col13__box u-no-border">

                                    <div className="viewapp__col13__info u-no-border">
                                        {props.location.data.NextOfKin_FullName}
                                    </div>


                                    <div className="viewapp__col13__info u-no-border">

                                        {props.location.data.NextOfKin_Address}

                                    </div>

                                    <div className="viewapp__col13__info u-no-border">


                                        {props.location.data.NextOfkin_Contact}

                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>



                    <div className="viewapp__section">
                        <p className="viewapp__section__head">Part 3: Declarations</p>

                        <div className="viewapp__section__box">

                            <div className="viewapp__col14 viewapp__col12 u-no-border-right">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(a) Ever been refused entry to / deported from Ghana ?</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Deported_From_GH_Status}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(c) Date deported from Ghana</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {fireBaseTime(props.location.data.Deported_From_GH_Date)}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col11">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(e) Reason for Being from deported from Ghana</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Deported_From_GH_Reason}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12 u-no-border-right">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(b) Ever been refused entry to / deported any Country ?</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Deported_From_Country_Status}
                                </div>
                            </div>




                            <div className="viewapp__col14 viewapp__col12">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(d) Date deported from Other Country</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {fireBaseTime(props.location.data.Deported_From_Country_Date)}
                                </div>
                            </div>




                            <div className="viewapp__col14 viewapp__col11">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(f) Reason for Being from deported from Other Country</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Deported_From_Country_Reason}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12 u-no-border-right">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(b) Which Country were you refused entry to / deported from ?</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Deported_Country}
                                </div>
                            </div>




                            <div className="viewapp__col14 viewapp__col12">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(g) Do you have a criminal record in any country ?</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Criminal_Status}
                                </div>
                            </div>





                            <div className="viewapp__col14 viewapp__col12 u-no-border-right">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(h) Do you suffer from any mental Disorders ?</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Mental_Status}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(i Do you suffer from any communicable diseases ?</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Communicable_Disease_Status}
                                </div>
                            </div>

                            <div className="viewapp__col14 viewapp__col12 u-no-border-right">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head">(j) Have you had Yellow Fever Vaccination ?</span>
                                    <span className="viewapp__col14__required" style={{ display: 'none' }}>&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {props.location.data.Yellow_Fever_Status}
                                </div>
                            </div>



                            <div className="viewapp__col14 viewapp__col12">
                                <div className="viewapp__col14__headbox">
                                    <span className="viewapp__col14__head" >(k) Date of Yellow Fever Vaccination</span>
                                    <span className="viewapp__col14__required">&#10059;</span>
                                </div>
                                <div className="viewapp__col14__info">
                                    {fireBaseTime(props.location.data.Yellow_Fever_Date)}
                                </div>
                            </div>



                        </div>

                    </div>







                    <div className="viewapp__terms">

                        <div className='viewapp__terms__item'>

                            I believe and declare that the information given in this application by me or on my behalf are true and that
                            any documents provided in relation to this application are genuine to the best of my knowledge. I do
                            consent to the processing of the information and documents according to Ghanaian law for the purposes of
                            obtaining a Visa and to be held liable according to that law if any information or documents are found to be
                            untruthful.
                        </div>

                    </div>
                    <div className='viewapp__terms__sign'>

                        <div className="viewapp__col14 viewapp__col12">
                            <div className="viewapp__col14__headbox">
                                <span className="viewapp__col14__head">Date</span>
                                <span className="viewapp__col14__required" style={{ display: 'none' }}>&#10059;</span>
                            </div>
                            <div className="viewapp__col14__info">
                                &nbsp;
                            </div>
                        </div>

                        <div className="viewapp__col14 viewapp__col12">
                            <div className="viewapp__col14__headbox">
                                <span className="viewapp__col14__head">Signature</span>
                                <span className="viewapp__col14__required" style={{ display: 'none' }}>&#10059;</span>
                            </div>
                            <div className="viewapp__col14__info">
                                &nbsp;
                            </div>
                        </div>
                    </div>

                    {/*
          
                        <div className="viewapp__declare">
                            <div className="viewapp__declare__text">
                            I declare on this date <span className='viewapp__declare__date'>{`${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear()}`}</span> that the information given on this form whether entered by myself
                            or on my behalf by a third party is true, complete and accurate, and all documents attached to this application are to the best of my knowledge and belief,
                            genuine; and do consent to the processing of information in accordance with my application for a Visa/Entry Permit.
                            </div>
                            <div className='viewapp__declare__sign' />
                        </div>

                        */}

                    <div className="viewapp__official">
                        <h2 className='viewapp__official__head'>Official Use Only</h2>
                        <div className='viewapp__official__box'>

                            <div className="viewapp__official__list">
                                <div className="viewapp__official__list__item">
                                    <div className="viewapp__official__list__item__label">
                                        Visa Application Number
                                    </div>
                                    <div className="viewapp__official__list__item__box" />
                                </div>

                                <div className="viewapp__official__list__item">
                                    <div className="viewapp__official__list__item__label">
                                        Type of Visa
                                    </div>
                                    <div className="viewapp__official__list__item__box" />
                                </div>

                                <div className="viewapp__official__list__item">
                                    <div className="viewapp__official__list__item__label">
                                        Visa Fee
                                    </div>
                                    <div className="viewapp__official__list__item__box" />
                                </div>

                                <div className="viewapp__official__list__item">
                                    <div className="viewapp__official__list__item__label">
                                        Payment Reference Number
                                    </div>
                                    <div className="viewapp__official__list__item__box" />
                                </div>

                                <div className="viewapp__official__list__item">
                                    <div className="viewapp__official__list__item__label">
                                        Visa Label Number
                                    </div>
                                    <div className="viewapp__official__list__item__box" />
                                </div>

                                <div className="viewapp__official__list__item">
                                    <div className="viewapp__official__list__item__label">
                                        Receipt Number
                                    </div>
                                    <div className="viewapp__official__list__item__box" />
                                </div>

                                <div className="viewapp__official__list__item">
                                    <div className="viewapp__official__list__item__label">
                                        Date of Issue
                                    </div>
                                    <div className="viewapp__official__list__item__box" />
                                </div>

                                <div className="viewapp__official__list__item">
                                    <div className="viewapp__official__list__item__label">
                                        Issuing Officer
                                    </div>
                                    <div className="viewapp__official__list__item__box" />
                                </div>
                            </div>

                            <div className="viewapp__official__passportbox" >
                            {props.location.data.Link_To_Files === "" ? 
                                <div className='viewapp__official__passportitem'>Passport Pic</div> :
                                <img src={`${JSON.parse(props.location.data.Link_To_Files)[0]}`} alt="Passport pic of applicant" className='viewapp__official__passportitem'/>
                            }
                                
                               
                            </div>
                        </div>



                    </div>

                    <div className="viewapp__footer">
                        <p>Visa Application Form</p>
                        <p>Page 2/2</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Template