import React, { useEffect, useState } from 'react'
import Rodal from 'rodal';
import Add from '../../img/add.png'
import { MdClose } from 'react-icons/md'
import emailjs from '@emailjs/browser';

function SendMail(props) {
    const [currentInput, setCurrentInput] = useState('')
    const [allInputs, setAllInputs] = useState([])

    const handleClose = () => {
        setCurrentInput('')
        setAllInputs([])
        props.setVisible(false)
    }

    

    const handleRemoveItem = (index) => {
        
        let entered = allInputs;
        entered.splice(index, 1)
        setAllInputs([...entered])
    }

    
const sendMail = () => {
    props.setVisible(false)
    props.setShowSpinner(true)
    
    
    const mailParams = {
        to_email: props.location.state.selected.data.Email,
        to_name: `${props.location.state.selected.data.Firstnames} ${props.location.state.selected.data.Surname}`,
        to_ref: props.location.state.selected.data.ID,
        reasons: `${allInputs.join('\n')}` 
      }

      
      

    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, 'template_o7elgi9', mailParams, process.env.REACT_APP_EMAILJS_USER_ID).then(response => {
        // console.log(result.text);
        props.setShowSpinner(false)
        
        
    }, (error) => {
        alert(error.text)
        // props.setShowSpinner(false)
        // console.log(error.text);
    });
    
    

}

    return (
        <Rodal visible={props.visible} onClose={() => handleClose()} width={700} height={500}>
            <div className='sendmail'>
                <div className="sendmail__inputbox">
                    <input
                        type="text"
                        className="sendmail__input"
                        value={currentInput}
                        placeholder="Type Reason and click Add"
                        onChange={(e) => setCurrentInput(e.target.value)}
                    />
                    <img
                        src={Add}
                        alt="Add Icon"
                        className="sendmail__input__icon"
                        onClick={() => {
                            setAllInputs([...allInputs, currentInput])
                            setCurrentInput('')
                        }}
                    />
                </div>

                <div className="sendmail__content">
                    {   
                        allInputs.map((x, i) => (
                            <div className='sendmail__content__item'>
                                <p>{x}</p>
                                <MdClose
                                    className='sendmail__content__close'
                                    onClick={() => handleRemoveItem(i)}
                                />
                            </div>
                        ))
                    }
                </div>

                <div className="sendmail__btnsection">
                    <button 
                        className="btn sendmail__btn"
                        onClick={() => sendMail()}
                    >
                        Send Mail
                    </button>
                </div>

            </div>
        </Rodal>
    )
}

export default SendMail