import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Visa1 from '../img/visa.png';
import Visa2 from '../img/visa2.png';
import Add from '../img/add.png';
import Rodal from 'rodal';
import { db, storage } from '../utils/firebase';
import Spinner from './shared/Spinner';
import { doc, updateDoc } from "firebase/firestore";
import Header from './shared/Header';
import Footer from './shared/Footer';
import _ from 'lodash';
import Back from '../img/previous.png';

const VisaTypeRodal = (props) => {
    const location = useLocation();
    const navigate = useNavigate();


    return (
        <Rodal
            visible={props.visible}
            onClose={() => {
                props.functionStatus === 1 ? props.setVisible(false) :
                props.setVisaTitle('')
                props.setVisaPrice('')
                props.setVisible(false)
            }}
            width={650}
            height={400}
        >
            <div className="visatypeform">

                <h2 className='visatypeform__header'> Visa Type Data</h2>

                <div className="visatypeform__form">

                    <div className="visatypeform__form__inputbox">
                        <label htmlFor="applicationno">Visa Type Name</label>
                        <input
                            type="text"
                            className="visatypeform__form__input"
                            value={props.visaTitle}
                            placeholder={props.functionStatus === 1 ? '' : props.feesArray[props.selectedFee].title}
                            onChange={(e) => props.setVisaTitle(e.target.value)}

                        />
                    </div>

                    <div className="visatypeform__form__inputbox">
                        <label htmlFor="applicationno">Visa Type Price</label>
                        <input
                            type="text"
                            className="visatypeform__form__input"
                            value={props.visaPrice}
                            placeholder={props.functionStatus === 1 ? '' : props.feesArray[props.selectedFee].price}
                            onChange={(e) => props.setVisaPrice(e.target.value)}

                        />
                    </div>

                </div>

                <div className="visatypeform__btnbox">

                    <button
                        className='visatypeform__btn'
                        onClick={props.functionStatus === 1 ? () => handleAddFee(props, location) : () => handleEditFee(props, location)}
                    >
                        Save
                    </button>

                    <button
                        className='visatypeform__btn'
                        onClick={() => handleDeleteFee(props, location)}
                        style={props.functionStatus === 1 ? { display: 'none', width: '0' } : {}}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </Rodal>
    )
}


function FeeSettings() {

    const location = useLocation();
    const navigate = useNavigate();

    // console.log(JSON.parse(location.state.data))
    // console.log(location.state)
    const [feesArray, setFeesArray] = useState(JSON.parse(location.state.data))
    // console.log(feesArray)
    const [rodalVisible, setRodalVisible] = useState(false)
    const [visaTitle, setVisaTitle] = useState('');
    const [visaPrice, setVisaPrice] = useState('');
    const [showSpinner, setShowSpinner] = useState(false)
    const [selectedFee, setSelectedFee] = useState(0)
    const [functionStatus, setFunctionStatus] = useState(1)
    /*
    1 --- ADD
    2,3 --- EDIT, DELETE
    
    */

    /*
    useEffect(() => {

        window.onpopstate = () => {

            navigate('/settings', {
                state: {

                    allFees: JSON.stringify(feesArray),

                    updateID: location.state.key,

                    allOfficers: location.state.allOfficers,
                    fullList: location.state.fullList,
                    userList: location.state.fullList,
                    officerData: location.state.officerData,
                }
            })

            // console.log(location.state.feesList)
            // console.log('Back button pressed');
        }
    })
    */
    return (
        <div className='fees'>
            <Header headerText="FEE SETTINGS " officerData={!_.isEmpty(location) ? location.state.officerData : []} />
            <Spinner showSpinner={showSpinner} />
            <VisaTypeRodal
                visible={rodalVisible}
                setVisible={setRodalVisible}
                feesArray={feesArray}
                setFeesArray={setFeesArray}
                visaTitle={visaTitle}
                setVisaTitle={setVisaTitle}
                visaPrice={visaPrice}
                setVisaPrice={setVisaPrice}
                showSpinner={showSpinner}
                setShowSpinner={setShowSpinner}
                functionStatus={functionStatus}
                setFunctionStatus={setFunctionStatus}
                selectedFee={selectedFee}
            />



            <div
                className="adduser__container"
                style={showSpinner ? { display: 'none', width: '0' } : {}}
            >

                <div
                    className='adduser__addbox'
                    style={location.state.officerData.role === 2 ? { display: 'none', width: '0' } : {}}
                    onClick={() => {
                        setFunctionStatus(1)
                        setRodalVisible(true)
                    }}
                    
                >
                    <img src={Add} alt="Add New Visa Type" className="adduser__add" />
                </div>


                <div
                    className='adduser__addbox'
                    onClick={() => {
                        navigate(-1)
                    }}
                    style={showSpinner ? { display: 'none', width: '0' } : {}}
                >
                    <img src={Back} alt="Add New Visa Type" className="adduser__add" />
                </div>

            </div>



            <h2 className='settings__title' style={{ display: 'none', width: 0 }}>FEES MANAGEMENT</h2>

            <div
                className="fees__wrap"
                style={showSpinner ? { display: 'none', width: '0' } : {}}
            >

                {feesArray.map((fee, index) => (
                    <div className="fees__item">
                        <div className="fees__item__container">

                            <div className="fees__item__maindiv">
                                <div className="fees__item__maindiv__imgdiv">
                                    {index % 2 === 0 ? (
                                        <img src={Visa1} alt="" className="fees__item__maindiv__img" />
                                    ) : (
                                        <img src={Visa2} alt="" className="fees__item__maindiv__img" />
                                    )

                                    }

                                </div>
                                <p className="fees__item__maindiv__name">
                                    {fee.title}
                                </p>
                            </div>

                            <div className="fees__item__price">
                                {fee.price}
                            </div>


                        </div>

                        <div
                            className="fees__item__edit"
                            style={location.state.officerData.role === 2 ? { display: 'none', width: '0' } : {}}
                            onClick={() => {
                                setSelectedFee(index)
                                setVisaPrice(feesArray[index].price)
                                setVisaTitle(feesArray[index].title)
                                setFunctionStatus(2)
                                setRodalVisible(true)

                            }}
                        >
                            Edit Fees
                        </div>
                    </div>

                ))}



            </div>
            <Footer />

        </div>
    )
}


const handleAddFee = (props, location) => {
    props.setVisible(false)
    props.setShowSpinner(true)
    const docRef = doc(db, 'utils', location.state.key);

    const feeData = {
        title: props.visaTitle,
        price: props.visaPrice,
    }

    const allFees = props.feesArray;
    allFees.push(feeData);

    const updateData = {
        ALL_FEES: JSON.stringify(allFees)
    }



    updateDoc(docRef, updateData).then(() => {
        props.setVisaTitle('')
        props.setVisaPrice('')
        props.setShowSpinner(false)
        props.setFeesArray(allFees)

        // updateLocalFeesDb or refetch and update...

    }).catch((err) => {
        props.setShowSpinner(false)
        alert('Error when adding')
        // console.log(err)
    })
}

const handleEditFee = (props, location) => {
    props.setVisible(false)
    props.setShowSpinner(true)
    const docRef = doc(db, 'utils', location.state.key);

    const feeData = {
        title: props.visaTitle,
        price: props.visaPrice,
    }

    const allFees = props.feesArray;
    allFees[props.selectedFee] = feeData;

    const updateData = {
        ALL_FEES: JSON.stringify(allFees)
    }

    updateDoc(docRef, updateData).then(() => {
        props.setVisaTitle('')
        props.setVisaPrice('')
        props.setShowSpinner(false)
        props.setFeesArray(allFees)

        // updateLocalFeesDb or refetch and update...

    }).catch((err) => {
        props.setShowSpinner(false)
        alert('Error when adding')
        // console.log(err)
    })

}

const handleDeleteFee = (props, location) => {
    props.setVisible(false)
    props.setShowSpinner(true)
    const docRef = doc(db, 'utils', location.state.key);
    const allFees = props.feesArray;
    const newFeesArray = allFees.filter((x, index) => index !== props.selectedFee)
    // console.log(newFeesArray)

    const updateData = {
        ALL_FEES: JSON.stringify(newFeesArray)
    }



    updateDoc(docRef, updateData).then(() => {
        // console.log('all array done made gban gboss')
        props.setFunctionStatus(1)
        props.setFeesArray(newFeesArray)
        props.setShowSpinner(false)


        // updateLocalFeesDb or refetch and update...

    }).catch((err) => {
        props.setShowSpinner(false)
        alert('Error when adding')
        // console.log(err)
    })

}

export default FeeSettings