import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import avatar1 from '../img/avatar1.png';
import avatar2 from '../img/avatar2.png';
import Add from '../img/add.png';
import Back from '../img/previous.png';
import Account from '../img/user-profile.png';
import Rodal from 'rodal';
import { db, storage } from '../utils/firebase';
import Spinner from './shared/Spinner';
import { doc, updateDoc } from "firebase/firestore";
import Header from './shared/Header';
import Footer from './shared/Footer';
import _ from 'lodash';
import emailjs from '@emailjs/browser';
/*
const officers = [

    {
        username: 'seffah999',
        email: 'seffah999@gmail.com',
        fullName: 'Eric Amankona',
        password: 'admin@1234',
        role: 2,
    },
]
*/

const UserRodal = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    // // console.log(JSON.stringify(officers));

    return (
        <Rodal
            visible={props.visible}
            onClose={() => {
                props.functionStatus === 1 ? props.setVisible(false) :
                    props.setName('')
                props.setPassword('')
                props.setEmail('')
                props.setFullName('')
                props.setVisible(false)
            }
            }

            width={650}
            height={550}
        >
            <div className="visatypeform">

                <h2 className='visatypeform__header'>
                    {props.functionStatus === 1 ? 'Add Admin' :
                        props.functionStatus === 2 ? 'Edit Admin' : 'Edit My Account'
                    }
                </h2>

                <div className="visatypeform__form">

                    <div className="visatypeform__form__inputbox">
                        <label htmlFor="applicationno">Admin User Name</label>
                        <input
                            type="text"
                            className="visatypeform__form__input"
                            value={props.name}

                            // placeholder={props.functionStatus === 1 ? '' : props.userArray[props.selectedIndex].username}
                            onChange={(e) => props.setName(e.target.value)}

                        />
                    </div>

                    <div className="visatypeform__form__inputbox">
                        <label htmlFor="applicationno">Admin Email</label>
                        <input
                            type="email"
                            className="visatypeform__form__input"
                            value={props.email}
                            // placeholder={props.functionStatus === 1 ? '' : props.userArray[props.selectedIndex].email}
                            onChange={(e) => props.setEmail(e.target.value)}

                        />
                    </div>

                    <div className="visatypeform__form__inputbox">
                        <label htmlFor="applicationno">Admin Password</label>
                        <input
                            type="password"
                            className="visatypeform__form__input"
                            value={props.password}
                            // placeholder={props.functionStatus === 1 ? '' : props.userArray[props.selectedIndex].password}
                            onChange={(e) => props.setPassword(e.target.value)}

                        />
                    </div>

                    <div className="visatypeform__form__inputbox">
                        <label htmlFor="applicationno">Admin Full Name</label>
                        <input
                            type="text"
                            className="visatypeform__form__input"
                            value={props.fullName}
                            placeholder={props.functionStatus === 1 ? '' : props.userArray[props.selectedIndex].fullName}
                            onChange={(e) => props.setFullName(e.target.value)}

                        />
                    </div>

                    {/*

                    <div className="visatypeform__form__inputbox">
                        <label htmlFor="applicationno">User Role</label>
                        <select
                            // value={this.state.passporttype}
                            // onChange={this.handleChange.bind(this)}
                            className="visatypeform__form__input"
                            
                        >
                            <option value="" disabled selected>Select User Type</option>
                            <option value="Alien Passport">Super Admin</option>
                            <option value="Diplomatic Passports">Admin</option>
                            <option value="Official Passport">officer</option>
                        </select>
                    </div>

                    */}


                </div>

                <div className="visatypeform__btnbox">

                    <button
                        className='visatypeform__btn'
                        onClick={props.functionStatus === 1 ?
                            () => handleAddAdmin(props, location) :
                            props.functionStatus === 2 ?
                                () => handleEditAdmin(props, location) :
                                () => handleEditAccount(props, location, navigate)
                        }
                    >
                        Save
                    </button>

                    <button
                        className='visatypeform__btn'
                        onClick={() => handleDeleteAdmin(props, location)}
                        style={props.functionStatus !== 2 ?
                            { display: 'none', width: '0' } : {}}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </Rodal>
    )
}

const goBack = (location, navigate, userArray) => {
    navigate('/settings', {
        state: {

            allFees: location.state.allFees,

            updateID: location.state.key,

            allOfficers: JSON.stringify(userArray),
            userList: location.state.fullList,
            fullList: location.state.fullList,
            officerData: location.state.officerData,

            showNotif: location.state.showNotif,
            notifBody: location.state.notifBody,
        }
    })
}


function UserAdminSettings() {

    const location = useLocation();
    const navigate = useNavigate();

    // console.log(JSON.parse(location.state.data))
    // console.log(location.state.officerData.role)
    const [userArray, setUserArray] = useState(JSON.parse(location.state.data))
    const [rodalVisible, setRodalVisible] = useState(false)
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [showSpinner, setShowSpinner] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [functionStatus, setFunctionStatus] = useState(1)
    /*
    1 --- ADD
    2, --- EDIT
    3, --- EDIT USER ACCOUNT DETAILS
    */

    useEffect(() => {
        /*

        window.onpopstate = () => {

            navigate('/settings', {
                state: {

                    allFees: location.state.allFees,

                    updateID: location.state.key,

                    allOfficers: JSON.stringify(userArray),
                    userList: location.state.fullList,
                    fullList: location.state.fullList,
                    officerData: location.state.officerData,
                }
            })

            // console.log(location.state.feesList)
            // console.log('Back button pressed');
        }
        */
    })

    return (
        <div className='fees'>
            <Header headerText="ADMIN SETTINGS " officerData={!_.isEmpty(location) ? location.state.officerData : []} />
            <Spinner showSpinner={showSpinner} />
            <UserRodal
                visible={rodalVisible}
                setVisible={setRodalVisible}
                userArray={userArray}
                setUserArray={setUserArray}
                name={name}
                setName={setName}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                fullName={fullName}
                setFullName={setFullName}
                showSpinner={showSpinner}
                setShowSpinner={setShowSpinner}
                functionStatus={functionStatus}
                setFunctionStatus={setFunctionStatus}
                selectedIndex={selectedIndex}
            />

            <div
                className="adduser__container"
                style={showSpinner ? { display: 'none', width: '0' } : {}}
            >

                <div
                    className='adduser__addbox'
                    onClick={() => {
                        setFunctionStatus(1)
                        setRodalVisible(true)
                    }}
                    style={location.state.officerData.role !== 0 ? { display: 'none', width: '0' } : {}}
                >
                    <img src={Add} alt="Add New Visa Type" className="adduser__add" />
                </div>

                <div
                    className='adduser__addbox'
                    onClick={() => {
                        const userIndex = userArray.findIndex(x => x.email === location.state.officerData.email)
                        // console.log(userIndex)
                        setSelectedIndex(userIndex)
                        // console.log(userArray[userIndex])
                        setName(userArray[userIndex].username)
                        setPassword(userArray[userIndex].password)
                        setEmail(userArray[userIndex].email)
                        setFullName(userArray[userIndex].fullName)
                        setFunctionStatus(3)
                        setRodalVisible(true)
                    }}
                    style={location.state.officerData.role === 2 ? { display: 'none', width: '0' } : {}}
                >
                    <img src={Account} alt="Add New Visa Type" className="adduser__add" />
                </div>

                <div
                    className='adduser__addbox'
                    onClick={() => {
                        goBack(location, navigate, userArray)
                    }}
                >
                    <img src={Back} alt="Add New Visa Type" className="adduser__add" />
                </div>

            </div>




            <h2 className='settings__title' style={{ display: 'none', width: 0 }}>USER MANAGEMENT</h2>

            <div
                className="adduser__wrap"
                style={showSpinner ? { display: 'none', width: '0' } : {}}
            >

                {userArray.filter(x => x.role !== 2).map((user, index) => (
                    <div className="adduser__item">
                        <div className="adduser__item__container">

                            <div className="adduser__item__maindiv">
                                <div className="adduser__item__maindiv__imgdiv">
                                    {index % 2 === 0 ? (
                                        <img src={avatar1} alt="" className="adduser__item__maindiv__img" />
                                    ) : (
                                        <img src={avatar2} alt="" className="adduser__item__maindiv__img" />
                                    )

                                    }

                                </div>

                            </div>
                            <div className="adduser__item__maindiv__name">
                                {user.username}
                            </div>

                            <div className="adduser__item__maindiv__name">
                                {user.fullName}
                            </div>

                            <div className="adduser__item__price">
                                {user.email}
                            </div>


                        </div>

                        <div
                            className="adduser__item__edit"
                            style={location.state.officerData.role === 0 ? {} : { display: 'none', width: 0 }}
                            onClick={() => {
                                const indexSelected = userArray.findIndex(x => x.email === user.email)
                                // console.log(indexSelected)
                                // console.log(userArray[indexSelected])
                                setSelectedIndex(indexSelected)
                                setEmail(userArray[indexSelected].email)
                                setName(userArray[indexSelected].username)
                                setPassword(userArray[indexSelected].password)
                                setFullName(userArray[indexSelected].fullName)
                                setFunctionStatus(2)
                                setRodalVisible(true)
                            }}
                        >
                            Edit User
                        </div>
                    </div>

                ))}

            </div>
            <Footer />

        </div>
    )
}


const notifyAdmin = (props, location, allUsers) => {
    // emailjs.init('OA-bVDW950X7KHwjT')
    props.setShowSpinner(true);
    const mailParams = {
        to_email: props.email,
        to_name: props.fullName,
        to_code: `Your account is created with details username: ${props.name} password: ${props.password}
            Login at honghanaconsulatexp.netlify.app 
        `,
    }
    // emailjs.send('service_svcc7f9', 'template_tre9xnf', mailParams, 'OA-bVDW950X7KHwjT').then((result) => {
    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, 'template_wttk045', mailParams, process.env.REACT_APP_EMAILJS_USER_ID).then(response => {
        // console.log(response.text);
        alert('Email Sent')

        props.setUserArray(allUsers)
        props.setShowSpinner(false)

    }, (error) => {
        alert(error.text)
        // console.log(error.text);
    });

}

const handleAddAdmin = (props, location) => {
    props.setVisible(false)
    props.setShowSpinner(true)
    const docRef = doc(db, 'utils', location.state.key);

    const userData = {
        username: props.name,
        password: props.password,
        email: props.email,
        fullName: props.fullName,
        role: 1,
    }

    const allUsers = props.userArray;
    allUsers.push(userData);

    const updateData = {
        ALL_OFFICERS: JSON.stringify(allUsers)
    }



    updateDoc(docRef, updateData).then(() => {
        props.setName('')
        props.setPassword('')
        props.setEmail('')
        props.setFullName('')
        notifyAdmin(props, location, allUsers)

        // updateLocalFeesDb or refetch and update...

    }).catch((err) => {
        props.setShowSpinner(false)
        alert('Error when adding')
        // console.log(err)
    })


}

const handleEditAdmin = (props, location) => {
    props.setVisible(false)
    props.setShowSpinner(true)
    const docRef = doc(db, 'utils', location.state.key);

    const userData = {
        username: props.name,
        password: props.password,
        email: props.email,
        fullName: props.fullName,
        role: 1,
    }

    const allUsers = props.userArray;
    allUsers[props.selectedIndex] = userData;

    const updateData = {
        ALL_OFFICERS: JSON.stringify(allUsers)
    }

    updateDoc(docRef, updateData).then(() => {
        props.setName('')
        props.setPassword('')
        props.setEmail('')
        props.setFullName('')
        props.setShowSpinner(false)
        props.setUserArray(allUsers)

        // updateLocalFeesDb or refetch and update...

    }).catch((err) => {
        props.setShowSpinner(false)
        alert('Error when adding')
        // console.log(err)
    })

}

const handleEditAccount = (props, location, navigate) => {
    props.setVisible(false)
    props.setShowSpinner(true)

    const docRef = doc(db, 'utils', location.state.key);

    const userData = {
        username: props.name,
        password: props.password,
        email: props.email,
        fullName: props.fullName,
        role: 1,
    }

    const allUsers = props.userArray;
    allUsers[props.selectedIndex] = userData;

    const updateData = {
        ALL_OFFICERS: JSON.stringify(allUsers)
    }

    updateDoc(docRef, updateData).then(() => {
        props.setName('')
        props.setPassword('')
        props.setEmail('')
        props.setFullName('')
        props.setShowSpinner(false)
        props.setUserArray(allUsers)

        //  LOG OUT AND REFETCH DATA..
        localStorage.setItem('LOGGED_IN', '0')
        navigate('/')

    }).catch((err) => {
        props.setShowSpinner(false)
        alert('Error when updating account')
        // console.log(err)
    })

}

const handleDeleteAdmin = (props, location) => {
    props.setVisible(false)
    props.setShowSpinner(true)
    const docRef = doc(db, 'utils', location.state.key);
    const allUsers = props.userArray;
    const newUsersArray = allUsers.filter((x, index) => index !== props.selectedIndex)
    // console.log(newUsersArray)

    const updateData = {
        ALL_OFFICERS: JSON.stringify(newUsersArray)
    }



    updateDoc(docRef, updateData).then(() => {
        // console.log('all array done made gban gboss')
        props.setFunctionStatus(1)
        props.setUserArray(newUsersArray)
        props.setShowSpinner(false)

        // updateLocalFeesDb or refetch and update...

    }).catch((err) => {
        props.setShowSpinner(false)
        alert('Error when adding')
        // console.log(err)
    })

}

export default UserAdminSettings;