import React, { useState } from 'react'
import Header from './shared/Header';
import Spinner from './shared/Spinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from '../utils/firebase';
import { doc, updateDoc } from "firebase/firestore";
import Template from './shared/Template';
import ViewDocuments from './ViewDocuments';
import Taskbar from './shared/Taskbar';
import Footer from './shared/Footer';
import ApprovedRodal from './shared/ApprovedRodal';
import RefuseRodal from './shared/RefuseRodal';
import SendMail from './shared/SendMail';

function ViewFullApplications(props) {
    const [showSpinner, setShowSpinner] = useState(false)
    const [showDocuments, setShowDocuments] = useState(false)
    const [showRodal, setShowRodal] = useState(false)
    const [showRefuseRodal, setShowRefuseRodal] = useState(false)
    const [showMailRodal, setShowMailRodal ] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    // console.log('Here is the selected Data')
    // console.log(location.state.fullList[location.state.selectedIndex])
    // console.log(location.state)


    // SET IMAGE LINK TO ARRAY
    const imageLinks = JSON.parse(location.state.selected.data.Link_To_Files)

    // console.log(imageLinks)

    return (
        <div className='viewapp'>
            <Header officerData={location.state.officerData} />
            <Spinner showSpinner={showSpinner} />

            <ApprovedRodal
                location={location}
                setShowSpinner={setShowSpinner}
                visible={showRodal}
                setVisible={setShowRodal}

            />

            <RefuseRodal
                location={location}
                setShowSpinner={setShowSpinner}
                visible={showRefuseRodal}
                setVisible={setShowRefuseRodal}
            />

            <SendMail
                location={location}
                visible={showMailRodal}
                setVisible={setShowMailRodal}
                setShowSpinner={setShowSpinner}
            />



            <div className='viewapp__mainview'>
                <div className='viewapp__mainview__template'>
                    <Template location={location.state.selected} showSpinner={showSpinner} />
                </div>
                <div
                    className='viewapp__mainview__taskbar'
                    style={showSpinner ? { display: 'none', width: '0' } : {}}
                >
                    <Taskbar
                        location={location}
                        showDocuments={showDocuments}
                        setShowDocuments={setShowDocuments}
                        setShowSpinner={setShowSpinner}
                        setShowRodal={setShowRodal}
                        setShowRefuseRodal={setShowRefuseRodal}
                        setShowMailRodal={setShowMailRodal}
                        navigate={navigate}
                    />
                </div>

            </div>



            {!showSpinner && showDocuments && <ViewDocuments fileSources={imageLinks} />}

            <div class="viewapp__btns" style={{ display: 'none', width: '0' }}>
                <button
                    class="btn btn--normal"
                    onClick={() => navigate(-1)}
                >
                    &larr;Back
                </button>

                <button
                    class="btn btn--normal"

                >
                    Approve
                </button>

            </div>

            <Footer />

        </div>

    )
}

export default ViewFullApplications