import React, { useState } from 'react'
import Header from './shared/Header';
import Footer from './shared/Footer';
import Spinner from './shared/Spinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { FiSend } from 'react-icons/fi'
import { MdDelete, MdArrowBack } from 'react-icons/md'
import Sidebar from './shared/Sidebar';
import { doc, updateDoc } from "firebase/firestore";
import Template from './shared/Template';
import { db } from '../utils/firebase';
import emailjs from '@emailjs/browser'

function ViewApplication() {
    const [showSpinner, setShowSpinner] = useState(false)
    const location = useLocation()
    const navigate = useNavigate();
    // console.log(location.state)
    // console.log((location.state.selected.data.Date_Of_Birth))
    return (
        <div className='viewapp'>
            <Header officerData={location.state.officerData} />
            <Spinner showSpinner={showSpinner} />

            <div className='viewapp__mainview'>
                <div className='viewapp__mainview__template'>
                    <Template location={location.state.selected} showSpinner={showSpinner} />
                </div>
                <div
                    className='viewapp__mainview__taskbar'
                    style={showSpinner ? { display: 'none', width: '0' } : {}}
                >
                    <Taskbar
                        setShowSpinner={setShowSpinner}
                        selected={location.state.selected}

                    />
                </div>
            </div>



            <div class="viewapp__btns" style={{ display: 'none', width: '0' }}>
                <button
                    class="btn btn--normal"
                    onClick={() => navigate(-1)}
                >
                    &larr;Back
                </button>

            </div>
            <Footer />

        </div>

    )

}

const handleStatusChange = (location, setShowSpinner, navigate) => {
    setShowSpinner(true)
    const docRef = doc(db, 'applications', location.state.selected.id)


    const data = { STATUS: 6 }

    updateDoc(docRef, data).then(() => {
        updateLocalDb(location, setShowSpinner, navigate, data)
        // console.log('done')

    }).catch((err) => {
        // console.log(err)
    })
}

const updateLocalDb = (location, setShowSpinner, navigate, data) => {

    // TAKE OUT APPROVED APPLICATION FROM  LIST OF APPROVED APPLICATIONS THEN PASS IT BACK TO THE FULL APPLICATION PAGE TO GET UPDATED LIST
    const newList = location.state.userList.filter(x => x.id !== location.state.selected.id)
    const allApplications = location.state.fullList;

    const selectedIndex = location.state.fullList.findIndex(x => x.id === location.state.selected.id)
    // console.log('ID Here ' + selectedIndex)
    // console.log(location.state.fullList[selectedIndex])

    const modifiedApplication = {
        id: location.state.fullList[selectedIndex].id,
        data: {
            ...location.state.fullList[selectedIndex].data,
            ...data
        }
    }
    // console.log(modifiedApplication)
    allApplications.splice(selectedIndex, 1, modifiedApplication)
    // console.log(allApplications)

    setShowSpinner(false);

    navigate('/received', { state: { 
        userList: newList, 
        fullList: allApplications, 
        allFees: location.state.allFees, 
        officerData: location.state.officerData,

        allOfficers: location.state.allOfficers,
        updateID: location.state.updateID,

        showNotif: location.state.showNotif,
        notifBody: location.state.notifBody,
    } })
}


const handleResend = (location, setShowSpinner) => {
    // emailjs.init('OA-bVDW950X7KHwjT')
    setShowSpinner(true);
    const mailParams = {
        to_email: location.state.selected.data.Email,
        to_name: `${location.state.selected.data.Firstnames} ${location.state.selected.data.Surname}`,
        to_code: ` The Access code to login and upload your documents is  ${location.state.selected.data.accesscode}`,
      }

      emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, 'template_wttk045', mailParams, process.env.REACT_APP_EMAILJS_USER_ID).then(response => {  
        // console.log(result.text);
        alert('Email Sent')
        setShowSpinner(false)
    }, (error) => {
        alert(error.text)
        // console.log(error.text);
    });

}

const Taskbar = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className='taskbar'>


            <div
                className="taskbar__item__box"
                onClick={() => handleResend(location, props.setShowSpinner)}

            >
                <FiSend className='taskbar__item__icon' color='white' />
                <span>Resend Code</span>
            </div>


            <div
                className="taskbar__item__box"
                onClick={() => handleStatusChange(location, props.setShowSpinner, navigate)}

            >
                <MdDelete className='taskbar__item__icon' color='white' />
                <span>Delete</span>
            </div>

            <div
                className="taskbar__item__box"
                onClick={() => navigate(-1)}
            >
                <MdArrowBack className='taskbar__item__icon' color='white' />
                <span>Back</span>
            </div>

        </div>
    )
}

export default ViewApplication
