import React, { useState, createRef } from 'react'
import Header from './shared/Header';
import Spinner from './shared/Spinner';
import { useLocation, useNavigate } from 'react-router-dom';
import Template from './shared/Template';
import ViewDocuments from './ViewDocuments';
// import Taskbar from './shared/Taskbar';
import Footer from './shared/Footer';
import { BsPencilSquare } from 'react-icons/bs'
import { MdArrowBack, MdEmail } from 'react-icons/md'
import { GiFiles } from 'react-icons/gi'
import { RiUploadCloudFill } from 'react-icons/ri';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { db, storage } from '../utils/firebase';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const fileInput = createRef();

const handleFileInput = (e, attachment, setAttachment) => {
    if (e.target.files.length > 0) {
        // console.log(e.target.files[0])
        setAttachment(e.target.files[0])
    }

}

function ViewProcessing(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const [showSpinner, setShowSpinner] = useState(false)
    const [showDocuments, setShowDocuments] = useState(false)
    const [showVisaRodal, setShowVisaRodal] = useState(false)
    const [attachment, setAttachment] = useState('');
    const [visaLabel, setVisaLabel] = useState('')
    const [receiptNo, setReceiptNo] = useState('')
    const [dateOfIssue, setDateOfIssue] = useState('')
    const [issuingOfficer, setIssuingOfficer] = useState(location.state.officerData.fullName)

    const imageLinks = JSON.parse(location.state.selected.data.Link_To_Files)
    const approvedDocsLink = location.state.selected.data.Approved_File_Link === "" ? [] : JSON.parse(location.state.selected.data.Approved_File_Link)
    // console.log(imageLinks)
    // console.log(approvedDocsLink)



    return (
        <div className='viewaccepted'>
            <Spinner showSpinner={showSpinner} />
            <VisaRodal

                visible={showVisaRodal}
                setVisible={setShowVisaRodal}
                attachment={attachment}
                setAttachment={setAttachment}
                showSpinner={showSpinner}
                setShowSpinner={setShowSpinner}

                visaLabel={visaLabel}
                setVisaLabel={setVisaLabel}
                receiptNo={receiptNo}
                setReceiptNo={setReceiptNo}
                dateOfIssue={dateOfIssue}
                setDateOfIssue={setDateOfIssue}
                issuingOfficer={issuingOfficer}
                setIssuingOfficer={setIssuingOfficer}
            />
            <Header officerData={location.state.officerData} />

            <div className='viewaccepted__mainview' style={showSpinner ? { display: 'none', width: '0' } : {}}>
                <div className="viewaccepted__mainview__template">
                    <Template location={location.state.selected} showSpinner={showSpinner} />
                </div>
                <div className="viewaccepted__mainview__taskbar">
                    <Taskbar
                        showDocuments={showDocuments}
                        setShowDocuments={setShowDocuments}
                        showSpinner={showSpinner}
                        setShowSpinner={setShowSpinner}
                        setShowVisaRodal={setShowVisaRodal}

                    />
                </div>
            </div>

            {showDocuments && !showSpinner && <ViewDocuments fileSources={imageLinks} approvedSources={approvedDocsLink} />}
            <Footer />

        </div>
    )
}


const updateLocalDb = (location, setShowSpinner, navigate, data) => {

    // TAKE OUT APPROVED APPLICATION FROM  LIST OF APPROVED APPLICATIONS THEN PASS IT BACK TO THE FULL APPLICATION PAGE TO GET UPDATED LIST
    const newList = location.state.userList.filter(x => x.id !== location.state.selected.id)
    const allApplications = location.state.fullList;

    const selectedIndex = location.state.fullList.findIndex(x => x.id === location.state.selected.id)
    // console.log('ID Here ' + selectedIndex)
    // console.log(location.state.fullList[selectedIndex])

    const modifiedApplication = {
        id: location.state.fullList[selectedIndex].id,
        data: {
            ...location.state.fullList[selectedIndex].data,
            ...data
        }
    }
    // console.log(modifiedApplication)
    allApplications.splice(selectedIndex, 1, modifiedApplication)
    // console.log(allApplications)

    setShowSpinner(false);
    navigate('/processing', { state: { 
       
        userList: newList, 
        fullList: allApplications,  
        allFees: location.state.allFees, 
        officerData: location.state.officerData,

        allOfficers: location.state.allOfficers,
        updateID: location.state.updateID,

        showNotif: location.state.showNotif,
        notifBody: location.state.notifBody,
    } })
}


const openFileInput = () => {
    fileInput.current.click();
}

const handleProcessedVisa = (allProps, location, navigate) => {

    // console.log(allProps.visaLabel)
    // console.log(allProps.dateOfIssue)
    // console.log(allProps.receiptNo)



    allProps.setVisible(false)
    allProps.setShowSpinner(true);

    if (allProps.visaLabel === '' ||
        allProps.attachment === '' ||
        allProps.receiptNo === '' ||
        allProps.dateOfIssue === ""
    ) {

        alert('Please Complete Form Before Proceeding')
        allProps.setShowSpinner(false);
        return

    } else {
        // console.log('ready')


        const fileRef = ref(storage, `visa/${allProps.attachment.name}`)
        uploadBytes(fileRef, allProps.attachment).then(() => {
            getDownloadURL(fileRef).then((url) => {
                // console.log(url);
                const docRef = doc(db, 'applications', location.state.selected.id);

                const data = {
                    STATUS: 5,
                    Visa_File_Link: url,
                    Visa_Label: allProps.visaLabel,
                    Visa_Receipt_No: allProps.receiptNo,
                    Visa_Date_Of_Issue: allProps.dateOfIssue,
                    Visa_Issuing_Officer: allProps.issuingOfficer,
                }

                updateDoc(docRef, data).then(() => {
                    updateLocalDb(location, allProps.setShowSpinner, navigate, data)

                }).catch((err) => {
                    // console.log(err)
                })



            }).catch((err) => {
                // console.log(err)
            })

        }).catch((err) => {
            // console.log(err)
        })



    }


}

const VisaRodal = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <Rodal visible={props.visible} onClose={() => props.setVisible(false)} width={650} height={610}>
            <div className="visaform">

                <h2 className='visaform__header'> Complete Visa Information</h2>

                <div className="visaform__form">

                    <div className="visaform__form__inputbox">
                        <label htmlFor="applicationno">Visa Application Number</label>
                        <input
                            type="text"
                            className="visaform__form__input"
                            value={location.state.selected.data.ID}
                            disabled={true}
                        />
                    </div>

                    <div className="visaform__form__inputbox">
                        <label htmlFor="applicationno">Type Of Visa</label>
                        <input
                            type="text"
                            className="visaform__form__input"
                            value={location.state.selected.data.Visatype}
                            disabled={true}
                        />
                    </div>

                    <div className="visaform__form__inputbox">
                        <label htmlFor="applicationno">Visa Fee</label>
                        <input
                            type="text"
                            className="visaform__form__input"
                            value={location.state.selected.data.Visatype.substr(location.state.selected.data.Visatype.length - 4)}
                            disabled={true}
                        />
                    </div>

                    <div className="visaform__form__inputbox">
                        <label htmlFor="applicationno">Payment Reference Number</label>
                        <input
                            type="text"
                            className="visaform__form__input"
                            value={location.state.selected.data.ID}
                            disabled={true}
                        />
                    </div>

                    <div className="visaform__form__inputbox">
                        <label htmlFor="visalabel">Visa Label Number</label>
                        <input
                            name="visalabel"
                            type="text"
                            className="visaform__form__input"
                            value={props.visaLabel}
                            onChange={(e) => props.setVisaLabel(e.target.value)}
                        />
                    </div>

                    <div className="visaform__form__inputbox">
                        <label htmlFor="applicationno">Receipt Number</label>
                        <input
                            type="text"
                            className="visaform__form__input"
                            value={props.receiptNo}
                            onChange={(e) => props.setReceiptNo(e.target.value)}
                        />
                    </div>

                    <div className="visaform__form__inputbox">
                        <label htmlFor="applicationno">Date of Issue</label>
                        <DatePicker
                            className="visaform__form__input"
                            value={props.dateOfIssue ? props.dateOfIssue.toDateString() : props.dateOfIssue}
                            onChange={(date) => props.setDateOfIssue(date)}
                        />
                        {/*
                        <input
                            type="text"
                            className="visaform__form__input"
                            value={props.dateOfIssue}
                            onChange={(e) => props.setDateOfIssue(e.target.value)}
                        />
                        */}

                    </div>

                    <div className="visaform__form__inputbox">
                        <label htmlFor="applicationno">Issuing Officer</label>
                        <input
                            type="text"
                            className="visaform__form__input"
                            value={props.issuingOfficer}
                            disabled

                        />
                    </div>

                </div>

                <div className="visaform__attached">
                    <p className='visaform__attached__name'>
                        {props.attachment === "" ? 'No Visa Image' : props.attachment.name}
                    </p>
                    <p
                        className='visaform__attached__update'
                        onClick={() => openFileInput()}
                    >
                        {props.attachment === "" ? 'Upload' : 'Update'}
                    </p>
                </div>

                <div>
                    <input
                        type="file"
                        ref={fileInput}
                        onChange={(e) => handleFileInput(e, props.attachment, props.setAttachment)}
                        style={{ display: 'none', width: 0 }}
                    />
                </div>

                <div className="visaform__btnbox">

                    <button
                        className='visaform__btn'
                        onClick={() => handleProcessedVisa(props, location, navigate)}
                    >
                        Save & Archive
                    </button>
                </div>
            </div>
        </Rodal>
    )
}

const Taskbar = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className='taskbar'>


            <div
                className="taskbar__item__box"
                onClick={() => props.setShowVisaRodal(true)}

            >
                <BsPencilSquare className='taskbar__item__icon' color='white' />
                <span>Fill Visa Form</span>
            </div>


            <div
                className="taskbar__item__box"
                onClick={() => props.setShowDocuments(!props.showDocuments)}

            >
                <GiFiles className='taskbar__item__icon' color='white' />
                <span>{props.showDocuments === true ? 'Hide Documents' : 'Show Documents'}</span>
            </div>

            <div
                className="taskbar__item__box"
                onClick={() => window.open(`mailto:${location.state.selected.data.Email}`)}
            >
                <MdEmail className='taskbar__item__icon' color='white' />
                <span>Send Email</span>
            </div>

            <div
                className="taskbar__item__box"
                onClick={() => navigate(-1)}
            >
                <MdArrowBack className='taskbar__item__icon' color='white' />
                <span>Back</span>
            </div>

        </div>
    )
}

export default ViewProcessing;