import React, { useState } from 'react'
import Header from './shared/Header';
import Spinner from './shared/Spinner';
import { useLocation, useNavigate } from 'react-router-dom';

import Template from './shared/Template';


function ViewApplication() {
    const [showSpinner, setShowSpinner] = useState(false)
    const location = useLocation()
    const navigate = useNavigate();
    // console.log(location.state)
    console.log((location.state.selected))
    return (
        <div className='viewapp'>
            <Header officerData={location.state.officerData}/>
            <Spinner showSpinner={false} />

            <Template location={location.state.selected} showSpinner={showSpinner} />

            <div class="viewapp__btns" style={showSpinner ? { display: 'none', width: '0' } : {}}>
                <button
                    class="btn btn--normal"
                    onClick={() => navigate(-1)}
                >
                    &larr;Back
                </button>

            </div>

        </div>

    )

}


export default ViewApplication
