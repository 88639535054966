import React from 'react'
// import test from '../img/user.png';
import { Link, useLocation } from 'react-router-dom'
import task1 from '../img/membership.png';
import task2 from '../img/image-editing.png'
import task3 from '../img/file.png';
import task4 from '../img/stamp.png';
import task5 from '../img/rejected.png';
import task6 from '../img/archive.png';
import task7 from '../img/home.png';
import task8 from '../img/setting.png';

const setNextPageData = (pageIndex, users, allFees, allOfficers,officerData, updateID) => {
    
    let filteredData
    switch (pageIndex) {
        case 0:
            filteredData = users.filter(x => x.data.STATUS === 0)
            // console.log('received applications array')
            // console.log(filteredData)
            return { userList: filteredData, fullList: users, allFees: allFees, officerData, allOfficers, updateID}

        case 1:
            filteredData = users.filter(x => x.data.STATUS === 1)
            // console.log('full applications array')
            // console.log(filteredData)
            return { userList: filteredData, fullList: users, allFees: allFees, officerData, allOfficers, updateID}

        case 2:
            filteredData = users.filter(x => x.data.STATUS === 2)
            // console.log('Accepted applications array')
            // console.log(filteredData)
            return { userList: filteredData, fullList: users, allFees: allFees, officerData, allOfficers, updateID }

        case 3:
            filteredData = users.filter(x => x.data.STATUS === 3)
            // console.log('Accepted applications array')
            // console.log(filteredData)
            return { userList: filteredData, fullList: users, allFees: allFees, officerData, allOfficers, updateID }

        case 4:
            filteredData = users.filter(x => x.data.STATUS === 4)
            // console.log('Accepted applications array')
            // console.log(filteredData)
            return { userList: filteredData, fullList: users, allFees: allFees, officerData, allOfficers, updateID }

        case 5:
            filteredData = users.filter(x => x.data.STATUS === 5)
            // console.log('Accepted applications array')
            // console.log(filteredData)
            return { userList: filteredData, fullList: users, allFees: allFees, officerData, allOfficers, updateID }

        case 6:
            return { fullList: users, allFees: allFees, officerData }

        case 7:
            return { fullList: users, allFees: allFees, officerData }


        default:
            break;
    }
}

export default function Tasks() {
    const location = useLocation()
    // console.log(location.state)
    return (
        <div className='task'>
            <h2 className='task__title'>Menu Select</h2>
            <div className="task__container">

                <Link to='/received' className='task__item' state={setNextPageData(0, location.state.userList, location.state.feesList, location.state.officersList , location.state.officerData, location.state.updateID)}>
                    <img src={task1} alt="" className="task__item__icon" />
                    <p>Received Applications</p>
                </Link>


                <Link to='/fullapp' className='task__item' state={setNextPageData(1, location.state.userList, location.state.feesList, location.state.officersList , location.state.officerData, location.state.updateID)}>

                    <img src={task2} alt="" className="task__item__icon" />
                    <p>Full Applications</p>

                </Link>

                <Link to="/pending" className="task__item" state={setNextPageData(2, location.state.userList, location.state.feesList, location.state.officersList , location.state.officerData, location.state.updateID)}>
                    <img src={task3} alt="" className="task__item__icon" />
                    <p> Pending Approvals</p>
                </Link>

                <Link to="/processing" className="task__item" state={setNextPageData(4, location.state.userList, location.state.feesList, location.state.officersList , location.state.officerData, location.state.updateID)}>
                    <img src={task4} alt="" className="task__item__icon" />
                    <p>Passport Processing</p>
                </Link>

                <Link to="/refusal" className="task__item" state={setNextPageData(3, location.state.userList, location.state.feesList, location.state.officersList , location.state.officerData, location.state.updateID)}>
                    <img src={task5} alt="" className="task__item__icon" />
                    <p>Refusals</p>
                </Link>

                <Link to="/archives" className="task__item" state={setNextPageData(5, location.state.userList, location.state.feesList, location.state.officersList , location.state.officerData, location.state.updateID)}>
                    <img src={task6} alt="" className="task__item__icon" />
                    <p> Archive</p>
                </Link>

                <Link 
                to="/dashboard" 
                className="task__item"
                style={location.state.officerData.username === 'Administrator' ? {} : {display: 'none', width: '0'}}
                state={{ 
                    fullList: location.state.userList,
                     allFees: location.state.feesList, 
                     allOfficers: location.state.officersList,
                     updateID: location.state.updateID,
                     officerData: location.state.officerData,
                }}>
                    <img src={task7} alt="" className="task__item__icon" />
                    <p>Dashboard</p>
                </Link>

                <Link
                    to="/settings"
                    className="task__item"
                    style={location.state.officerData.username === 'Administrator' ? {} : {display: 'none', width: '0'}}
                    state={{ 
                    fullList: location.state.userList,
                     allFees: location.state.feesList, 
                     allOfficers: location.state.officersList,
                     updateID: location.state.updateID,
                     officerData: location.state.officerData,
                }}>
                    <img src={task8} alt="" className="task__item__icon" />
                    <p>Settings</p>
                </Link>
            </div>
        </div>
    )
}
