import React, { Component } from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import Sidebar from './shared/Sidebar'
import Header from './shared/Header'
import { doc, updateDoc } from "firebase/firestore";
import { db } from '../utils/firebase'
import _ from 'lodash'
import Test from '../img/airplane-2322303_1920.jpg';
import Group from '../img/man.png'
import Filter from '../img/selective.png';
import Active from '../img/approve.png';
import Empty from '../img/box.png';
import DeleteAppointmentModal from './shared/DeleteAppointmentModal';
import Spinner from './shared/Spinner';

let location;


const UseLocation = () => {
    location = useLocation();
    // console.log(location.state)
    return null;
}

const testArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
let allApplications = [];

export class ViewAppointments extends Component {

    state = {
        loading: false,
        data: [],
        navigate: false,
        selectedIndex: 0,
        filter: 1, // 0 for all appointments and 1 for active appointments
        showDelete: false,
    }

    componentDidMount() {
        // console.log(location.state.userList[0].data.AppointmentDate.slice(0, 15))
        const mydate = new Date(location.state.userList[0].data.AppointmentDate.slice(0, 15))
        // console.log(mydate)
        this.setState({ loading: true, data: location.state.userList }, () => {
            allApplications = location.state.fullList;
            this.setPageData()
            
        })
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(prevProps)
        // console.log(prevState)

        if (!prevState.filter === this.state.filter) {
            this.setPageData()
            // console.log('here')
        }




    }

    setPageData = () => {
        const today = new Date()

        let allAppointments = _.isEmpty(allApplications) ? location.state.userList : allApplications.filter(x => x.data.HasAppointment);
        if (this.state.filter === 1) {
            const data = allAppointments.filter((x, i) => (new Date(x.data.AppointmentDate.slice(0, 15)).getTime()) > today.getTime())
            // console.log(data)
            allAppointments = data
        }
        this.setState({ data: allAppointments, loading: false })
    }

    fireBaseTime = (x) => _.isEmpty(x) ? '' : new Date(
        x.seconds * 1000 + x.nanoseconds / 1000000,
    ).toDateString();

    formatName = (fName, sName, charsToDisplay) => {
        const combined = fName + ' ' + sName
        if (combined.length > charsToDisplay) {
            return combined.slice(0, charsToDisplay) + '...'
        }
        return combined

    }

    deleteAppointment = () => {
        // console.log('Delete FN INNIT')
        // console.log(this.state)

        this.setState({ showDelete: false, loading: true })
        // console.log('Inside Delete Apt ID is' + this.state.data[this.state.selectedIndex].id);


        const docRef = doc(db, "applications", this.state.data[this.state.selectedIndex].id);

        const data = {
            HasAppointment: false,
            AppointmentDate: '',
        }




        updateDoc(docRef, data).then(() => {

            this.updateLocalDb()


            // console.log('done')

        }).catch((err) => {
            // console.log(err)
        })



    }


    updateLocalDb = () => {

        // TAKE OUT APPROVED APPLICATION FROM  LIST OF APPROVED APPLICATIONS THEN PASS IT BACK TO THE FULL APPLICATION PAGE TO GET UPDATED LIST
        allApplications = location.state.fullList;

        const selectedIndex = location.state.fullList.findIndex(x => x.id === this.state.data[this.state.selectedIndex].id)
        // console.log('Local ID Here ' + selectedIndex)
        // console.log(location.state.fullList[selectedIndex].id)
        // console.log(location.state.fullList[selectedIndex])

        const modifiedApplication = {
            id: location.state.fullList[selectedIndex].id,
            data: {
                ...location.state.fullList[selectedIndex].data,
                HasAppointment: false,
                AppointmentDate: '',

            }
        }
        // console.log(modifiedApplication)
        allApplications.splice(selectedIndex, 1, modifiedApplication)
        // console.log(allApplications)

        const newList = allApplications.filter(x => x.data.HasAppointment)
        // console.log(newList)

        const today = new Date()

        let allAppointments = newList;
        
        if (this.state.filter === 1) {
            const data = allAppointments.filter((x, i) => (new Date(x.data.AppointmentDate.slice(0, 15)).getTime()) > today.getTime())
            // console.log(data)
            allAppointments = data;
        }

        this.setState({ data: allAppointments}, () => this.setState({ loading: false }));

    }

    findSelectedItemIndex = () => {
        const selectedIndex = location.state.fullList.findIndex(x => x.id === this.state.data[this.state.selectedIndex].id)
        // console.log(location.state.fullList[selectedIndex].Firstnames)
        return selectedIndex
    }


    _renderContent() {
        const { data } = this.state;
        if (!_.isEmpty(data)) {
            return (

                data.map((x, i) => (

                    <div className="viewappointments__content__table__itemcontainer" onClick={() => this.setState({ selectedIndex: i })}>

                        <div className="viewappointments__content__table__item">
                            <div className="viewappointments__content__table__item__rounded">
                                <img src={JSON.parse(x.data.Link_To_Files)[0]} alt="Customer photograph" className="viewappointments__content__table__item__img" />
                            </div>
                        </div>

                        <div className="viewappointments__content__table__item">
                            {this.formatName(x.data.Firstnames, x.data.Surname, 30)}
                        </div>

                        <div className="viewappointments__content__table__item">
                            {x.data.Contact}
                        </div>

                        <div className="viewappointments__content__table__item">
                            {this.fireBaseTime(x.data.Expected_Arrival_Date)}
                        </div>

                        <div
                            className="viewappointments__content__table__item viewappointments__content__table__item__schedule">
                            {x.data.AppointmentDate}
                        </div>

                        <div
                            className="viewappointments__content__table__item"
                            style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
                        >
                            <button
                                className='btn viewappointments__content__table__item__btn'
                                onClick={() => this.setState({ selectedIndex: i },
                                    () => this.setState({ navigate: true })
                                )}
                            >
                                Reschedule
                            </button>

                            <button
                                className="btn viewappointments__content__table__item__btn"
                                onClick={() => this.setState({ selectedIndex: i, showDelete: true })}
                            >
                                Delete
                            </button>

                        </div>


                    </div>

                ))


            )

        } else {
            return (
                <NoAppointment />
            )

        }
    }

    render() {
        // console.log(location)
        const { filter, data, loading } = this.state
        return (
            <div className='viewappointments'>
                <UseLocation />
                {
                    this.state.navigate && (
                        <Navigate
                            to="/bookappointments"
                            state={{
                                ...location.state,
                                selected: !_.isEmpty(location) ? location.state.fullList[this.findSelectedItemIndex()] : {},
                                userList: this.state.data,
                                fullList: allApplications,

                            }}
                        />
                    )
                }
                <Header
                    showSearch={false}
                    officerData={!_.isEmpty(location) ? location.state.officerData : []}
                    headerText="APPOINTMENTS"
                />

                <Sidebar
                    users={!_.isEmpty(allApplications) ?  allApplications : !_.isEmpty(location) ? location.state.fullList : []}
                    fees={!_.isEmpty(location) ? location.state.allFees : []}
                    officerData={!_.isEmpty(location) ? location.state.officerData : []}

                    officers={!_.isEmpty(location) ? location.state.allOfficers : []}
                    updateID={!_.isEmpty(location) ? location.state.updateID : ''}

                    showNotif={!_.isEmpty(location) ? location.state.showNotif : false}
                    notifBody={!_.isEmpty(location) ? location.state.notifBody : ''}

                />

                <DeleteAppointmentModal
                    visible={this.state.showDelete}
                    setVisible={() => this.setState({ showDelete: false })}
                    exec={this.deleteAppointment}

                />

                <Spinner showSpinner={loading} />
                <div
                    className="viewappointments__content"
                >
                    <div className="viewappointments__content__filter">
                        <div className="viewappointments__content__filter__imgbox">
                            <img
                                src={filter === 0 ? Group : Active}
                                alt="Icon for all appointments"
                                className="viewappointments__content__filter__img"
                            />
                        </div>
                        <div className="viewappointments__content__filter__textbox">
                            {filter === 0 ? 'All Appointments' : 'Active Appointments'}
                        </div>
                        <div
                            className="viewappointments__content__filter__itembox"
                            onClick={() => {
                                this.setState({ filter: filter === 0 ? 1 : 0 }, () => {
                                    this.setPageData()
                                })
                            }}
                        >
                            <img src={Filter} alt="Icon for Filter Item" className="viewappointments__content__filter__item" />
                        </div>

                    </div>
                    <div
                        className="viewappointments__content__table"
                        style={loading ? { display: 'none', width: '0' } : {}}
                    >
                        <div className='viewappointments__content__table__head'>
                            <p>Photograph</p>
                            <p>Applicant Name</p>
                            <p>Applicant Contact</p>
                            <p>Arrival Date</p>
                            <p>Appointment Date</p>
                            <p>Action</p>

                        </div>

                        {
                            this._renderContent()

                        }

                    </div>
                </div>

            </div>
        )
    }
}

const NoAppointment = (props) => {
    return (
        <div className="viewappointments__content__empty">
            <img src={Empty} alt="Empty Box" />
            <p>
                There are no Active Appointments at the moment.
                Click on the filter icon on the top to show all appointments
            </p>
        </div>
    )
}



export default ViewAppointments