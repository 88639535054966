import {Navigate} from 'react-router-dom';

const ProtectedRoute = ({ logged, children }) => {
    // console.log(logged)
    const isLoggedIn = localStorage.getItem('LOGGED_IN')
    if (parseInt(isLoggedIn) === 0) {
      return <Navigate to="/" replace />;
    }
  
    return children;
  };

  export default ProtectedRoute;
  