import React, { useState, createRef } from 'react';
import { useNavigate } from 'react-router-dom'
import Rodal from 'rodal';
import { db, storage } from '../../utils/firebase';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";

// include styles
import 'rodal/lib/rodal.css';

const fileInput = createRef();
let allAttachmentLinks = [];

const handleFileInput = (e, attachment, setAttachment) => {

    if (e.target.files.length > 0) {
        // console.log(e.target.files)
        setAttachment(e.target.files)
        // console.log(attachment.length)
    }
}

const openFileInput = () => {
    fileInput.current.click();
}

const updateDocData = (notes, url, location, setShowSpinner, navigate) => {

    // console.log(allAttachmentLinks);
    const docRef = doc(db, 'applications', location.state.selected.id);
    // console.log(JSON.stringify(allAttachmentLinks))
    

    const data = {
        STATUS: 2,
        Refused_Notes: notes,
        Refused_File_Link: JSON.stringify(allAttachmentLinks),
    }

    updateDoc(docRef, data).then(() => {
        updateLocalDb(notes, url, location, setShowSpinner, navigate)

    }).catch((err) => {
        // console.log(err)
    })
    
}


const uploadFile = (notes, attachment, location, setShowSpinner, navigate, index) => {
    const fileRef = ref(storage, `approved/${index}_${attachment[index].name}`)
    uploadBytes(fileRef, attachment[index]).then(() => {
        getDownloadURL(fileRef).then((url) => {
            // console.log(index)
            // console.log(url)

            allAttachmentLinks.push(url)
            
            index++;
            if (index < attachment.length) {
                uploadFile(notes, attachment, location, setShowSpinner, navigate, index)
            } else {
                // console.log(allAttachmentLinks)
                
                updateDocData(notes, JSON.stringify(allAttachmentLinks), location, setShowSpinner, navigate)
            }

        }).catch((err) => {
            // console.log(err)
        })

    }).catch((err) => {
        // console.log(err)
    })
}


const handleRefuse = (notes, attachment, location, setShowSpinner, navigate, setVisible) => {

    setVisible(false)
    setShowSpinner(true);

    if (notes === '') {

        alert('Please Type Some Notes Before Proceeding')
        setShowSpinner(false);
        return

    } else {

        if (attachment.length > 0) {
            allAttachmentLinks = [];
            uploadFile(notes, attachment,location, setShowSpinner, navigate, 0)
        } else {
            const docRef = doc(db, 'applications', location.state.selected.id);
            const url = '';

            const data = {
                STATUS: 2,
                Refused_Notes: notes,
                Refused_File_Link: url,
            }

            updateDoc(docRef, data).then(() => {
                updateLocalDb(notes, url, location, setShowSpinner, navigate)

            }).catch((err) => {
                // console.log(err)
            })

        }

    }

}


const updateLocalDb = (notes, url, location, setShowSpinner, navigate) => {

    // TAKE OUT APPROVED APPLICATION FROM  LIST OF APPROVED APPLICATIONS THEN PASS IT BACK TO THE FULL APPLICATION PAGE TO GET UPDATED LIST
    const newList = location.state.userList.filter(x => x.id !== location.state.selected.id)
    const allApplications = location.state.fullList;

    const selectedIndex = location.state.fullList.findIndex(x => x.id === location.state.selected.id)
    // console.log('ID Here ' + selectedIndex)
    // console.log(location.state.fullList[selectedIndex])

    const modifiedApplication = {
        id: location.state.fullList[selectedIndex].id,
        data: {
            ...location.state.fullList[selectedIndex].data,
            STATUS: 2,
            Refused_Notes: notes,
            Refused_File_Link: url,

        }
    }
    // console.log(modifiedApplication)
    allApplications.splice(selectedIndex, 1, modifiedApplication)
    // console.log(allApplications)

    setShowSpinner(false);
    // { userList: filteredData, fullList: users, allFees: fees, officerData }
    navigate('/fullapp', { state: { 
        userList: newList, 
        fullList: allApplications, 
        allFees: location.state.allFees, 
        officerData: location.state.officerData,

        allOfficers: location.state.allOfficers,
        updateID: location.state.updateID,

        showNotif: location.state.showNotif,
        notifBody: location.state.notifBody,

    } })


}


function RefuseRodal(props) {

    const [note, setNote] = useState('');
    const [attachment, setAttachment] = useState([])
    const navigate = useNavigate();
    

    return (
        <Rodal visible={props.visible} onClose={() => props.setVisible(false)} width={600} height={400}>
            <div className='addnote'>
                <h2 className='addnote__header'>ADD REFUSAL NOTE</h2>
                <div className="addnote__inputbox">
                    <textarea
                        name="note"
                        id="note"
                        cols="30"
                        rows="10"
                        className="addnote__input"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    />
                </div>

                <div className="addnote__attached">
                    <p className='addnote__attached__name'>
                        {attachment.length > 0 ? `Files Uploaded - ${attachment.length}` : 'No Attachment' }
                    </p>
                    <p
                        className='addnote__attached__update'
                        onClick={() => openFileInput()}
                    >
                        {attachment.length > 0 ? 'Update' : 'Upload'}
                    </p>
                </div>

                <div className="addnote__btnbox">

                    <button
                        className='addnote__btn'
                        onClick={() => handleRefuse(note, attachment, props.location, props.setShowSpinner, navigate, props.setVisible)}
                    >
                        Refuse
                    </button>
                </div>
                <div>
                    <input
                        type="file"
                        ref={fileInput}
                        multiple
                        onChange={(e) => handleFileInput(e, attachment, setAttachment)}
                        style={{ display: 'none', width: 0 }}
                    />
                </div>
            </div>
        </Rodal>
    )
}

export default RefuseRodal;