import React, { useEffect, useState } from 'react'
import Header from './shared/Header'
import Footer from './shared/Footer'
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import Created from '../img/created.png';
import Name from '../img/name.png';
import Mail from '../img/message.png';
import Arrival from '../img/arrivals.png';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'
import { doc, updateDoc } from "firebase/firestore";
import { db } from '../utils/firebase'
import Spinner from './shared/Spinner';
import SafeModal from './shared/SafeModal';
import emailjs from '@emailjs/browser'
import Booking from '../img/booking.png'

const WEEKDAYS = [
    'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'
]

const WORKHOURS = ['9:00AM', '10:00AM', '11:00AM', '12:00PM', '1:00PM', '2:00PM', '3:00PM', '4:00PM', '5:00PM']
const WORKMINS = ['00', '15', '30', '45']
const Month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


function Appointments() {

    const location = useLocation();
    const navigate = useNavigate();
    const hasAppointment = location.state.selected.data.HasAppointment;
    const appointmentDateArray = hasAppointment ? location.state.selected.data.AppointmentDate.split(' ') : [];
    // console.log(location.state.selected.data.AppointmentDate.split(' '))

    const today = new Date();

    const [currentMonth, setCurrentMonth] = useState(hasAppointment ? Month.findIndex((x, i) => x.slice(0, 3) === appointmentDateArray[1]) : today.getMonth())
    const [currentYear, setCurrentYear] = useState(hasAppointment ? parseInt(appointmentDateArray[3]) : today.getFullYear())
    const [selectedDay, setSelectedDay] = useState(hasAppointment ? parseInt(appointmentDateArray[2]) : today.getDate());
    const [numberOfDays, setNumberOfDays] = useState(0);
    const [allCalenderData, setAllCalenderData] = useState([])

    // GET THE HOUR TO BE IN THE FORMAT 9:00PM JUST LIKE INITIALLY.
    const [selectedTimeHr, setSelectedTimeHr] = useState(hasAppointment ? 
        `${appointmentDateArray[4].split(':')[0]}:00${appointmentDateArray[5]}`
        : WORKHOURS[0]);
    console.log(selectedTimeHr)

    const [selectedTimeMin, setSelectedTimeMin] = useState(hasAppointment ? appointmentDateArray[4].split(':')[1] : WORKMINS[0])
    const [showSpinner, setShowSpinner] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(
        () => {

            setNumberOfDays(getDays(currentYear, currentMonth))
            setCalenderData()

        }, [currentMonth, currentYear]
    )

    const getDays = (currentYear, currentMonth) => {
        return new Date(currentYear, currentMonth + 1, 0).getDate();
    };

    const setDay = (day) => {
        if (new Date().getTime() < new Date(currentYear, currentMonth, day)) {
            setSelectedDay(day)
        }
    }



    const fireBaseTime = (x) => _.isEmpty(x) ? '' : new Date(
        x.seconds * 1000 + x.nanoseconds / 1000000,
    ).toDateString();

    const setCalenderData = () => {
        let row = 2, column = (new Date(currentYear, currentMonth, 1).getDay());
        const pageData = Array.apply(null, Array(getDays(currentYear, currentMonth))).map((x, i) => { return i + 1 })
        const calenderData = pageData.map((x, i) => {
            // console.log(column, i)

            if ((column) % 7 === 0) {

                row = column === 0 ? row : row + 1;
                column = 1;

            } else {
                column++;
            }

            return {
                dayInMonth: x,
                dayInWeek: (new Date(currentYear, currentMonth, x).getDay() + 1),
                dayName: (WEEKDAYS[new Date(currentYear, currentMonth, x).getDay()]),
                row: row,
                column: column
            }

        })

        setAllCalenderData(calenderData)

        // console.log(calenderData)
    }

    const handleBooking = () => {
        setShowSpinner(true)
        const docRef = doc(db, "applications", location.state.selected.id);
        const data = {
            HasAppointment: true,
            AppointmentDate: `${new Date(currentYear, currentMonth, selectedDay).toDateString()} ${selectedTimeHr.split(':')[0]}:${selectedTimeMin} ${selectedTimeHr.slice(-2)}`,
        }
        // console.log(data)
        // console.log(location.state.selected.id)
        // console.log(data.AppointmentDate)
        setShowModal(false);

        updateDoc(docRef, data).then(() => {
            // send Email here
            // updateLocalDb()
            sendMail()

            // console.log('done')

        }).catch((err) => {
            // console.log(err)
        })

    }

    const updateLocalDb = () => {

        // TAKE OUT APPROVED APPLICATION FROM  LIST OF APPROVED APPLICATIONS THEN PASS IT BACK TO THE FULL APPLICATION PAGE TO GET UPDATED LIST
        const allApplications = location.state.fullList;

        const selectedIndex = location.state.fullList.findIndex(x => x.id === location.state.selected.id)
        // console.log('ID Here ' + selectedIndex)
        // console.log(location.state.fullList[selectedIndex])

        const modifiedApplication = {
            id: location.state.fullList[selectedIndex].id,
            data: {
                ...location.state.fullList[selectedIndex].data,
                HasAppointment: true,
                AppointmentDate: `${new Date(currentYear, currentMonth, selectedDay).toDateString()} ${selectedTimeHr.split(':')[0]}:${selectedTimeMin} ${selectedTimeHr.slice(-2)}`,

            }
        }
        // console.log(modifiedApplication)
        allApplications.splice(selectedIndex, 1, modifiedApplication)
        // console.log(allApplications)


        const newList = allApplications.filter(x => x.data.HasAppointment)
        // console.log(newList)



        setShowSpinner(false);
        // { userList: filteredData, fullList: users, allFees: fees, officerData }
        navigate('/viewappointments', {
            state: {
                userList: newList,
                fullList: allApplications,
                allFees: location.state.allFees,
                officerData: location.state.officerData,

                allOfficers: location.state.allOfficers,
                updateID: location.state.updateID,

            }
        })


    }

    const handleArrowClick = (direction) => {
        // DIRECTION 0 === LEFT
        // DIRECTION 1 === RIGHT

        if (direction === 1 && currentMonth === 11) {
            setCurrentYear(currentYear + 1)
            setSelectedDay(1)
            setCurrentMonth(0)

        } else if (direction === 0 && currentMonth === 0) {

            if (new Date(currentYear - 1, 11, getDays(currentYear - 1, 11)).getTime() > today.getTime()) {
                setSelectedDay(getDays(currentYear - 1, 11))
                setCurrentYear(currentYear - 1)

                setCurrentMonth(11)
            }


        } else if (direction === 1) {
            setSelectedDay(1)
            setCurrentMonth(currentMonth + 1)
        } else {
            if (new Date(currentYear, currentMonth - 1, getDays(currentYear, currentMonth - 1)).getTime() > today.getTime()) {
                setSelectedDay(getDays(currentYear, currentMonth - 1))
                setCurrentMonth(currentMonth - 1)
            }
        }

    }


    const sendMail = () => {

        // console.log(location.state.selected.data.HasAppointment)

        if (location.state.selected.data.HasAppointment) {

            const mailParams = {
                to_email: location.state.selected.data.Email,
                to_name: `${location.state.selected.data.Firstnames} ${location.state.selected.data.Surname}`,
                // to_ref: location.state.selected.data.ID,
                appointment_date: `${new Date(currentYear, currentMonth, selectedDay).toDateString()}`,
                appointment_time: `${selectedTimeHr.split(':')[0]}:${selectedTimeMin} ${selectedTimeHr.slice(-2)}`,
            }

            emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, 'template_26ckita', mailParams, process.env.REACT_APP_EMAILJS_USER_ID).then(response => {
                updateLocalDb()
            }, (error) => {
                alert(error.text)
                // console.log(error.text);
            });


        } else {

            const mailParams = {
                to_email: location.state.selected.data.Email,
                to_name: `${location.state.selected.data.Firstnames} ${location.state.selected.data.Surname}`,
                to_ref: location.state.selected.data.ID,
                appointment_date: `${new Date(currentYear, currentMonth, selectedDay).toDateString()}`,
                appointment_time: selectedTimeHr,
            }

            emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, 'template_9cp66p6', mailParams, process.env.REACT_APP_EMAILJS_USER_ID).then(response => {
                updateLocalDb()
            }, (error) => {
                alert(error.text)
                // console.log(error.text);
            });

        }

    }


    return (

        <div className='mk-appointments'>
            <Header

                showSearch={false}
                dataSet={[]}
                setData={() => null}
                officerData={!_.isEmpty(location) ? location.state.officerData : {}}
                headerText="BOOK APPOINTMENT"

            />

            <Spinner showSpinner={showSpinner} />

            <SafeModal
                visible={showModal}
                setVisible={setShowModal}
                exec={handleBooking}
            />


            <div
                className="mk-appointments__info"
                style={showSpinner ? { display: 'none', width: '0' } : {}}
            >

                <div className="mk-appointments__info__item">
                    <div className="mk-appointments__info__label">
                        <img src={Name} alt="Name icon" className='mk-appointments__info__label__img' />
                    </div>
                    <div className="mk-appointments__info__value">
                        <span>Name</span> : {`${location.state.selected.data.Firstnames} ${location.state.selected.data.Surname} ${location.state.selected.data.Othername}`}
                    </div>
                </div>

                <div className="mk-appointments__info__item">
                    <div className="mk-appointments__info__label">
                        <img src={Mail} alt="Name icon" className='mk-appointments__info__label__img' />
                    </div>
                    <div className="mk-appointments__info__value">
                        <span>Email</span> : {location.state.selected.data.Email}
                    </div>
                </div>

                <div className="mk-appointments__info__item">
                    <div className="mk-appointments__info__label">
                        <img src={Arrival} alt="Name icon" className='mk-appointments__info__label__img' />
                    </div>
                    <div className="mk-appointments__info__value">
                        <span>Arrival Date</span> : {fireBaseTime(location.state.selected.data.Expected_Arrival_Date)}
                    </div>
                </div>

                <div className="mk-appointments__info__item">
                    <div className="mk-appointments__info__label">
                        <img src={Created} alt="Name icon" className='mk-appointments__info__label__img' />
                    </div>
                    <div className="mk-appointments__info__value">
                        <span>Application Date</span> : {fireBaseTime(location.state.selected.data.created)}
                    </div>
                </div>

            </div>


            <div
                className="mk-appointments__content"
                style={showSpinner ? { display: 'none', width: '0' } : {}}
            >

                <div className="mk-appointments__content__tablecontainer">
                    <div className="mk-appointments__content__table__headerbox">
                        <p>Select Date</p>
                    </div>
                    <div className="mk-appointments__content__table__currentmonth">
                        <MdArrowBackIosNew
                            className='mk-appointments__content__table__currentmonth__icon'
                            onClick={() => handleArrowClick(0)}
                        />
                        <span>{`${Month[currentMonth]} ${currentYear}`}</span>
                        <MdArrowForwardIos
                            className='mk-appointments__content__table__currentmonth__icon'
                            onClick={() => handleArrowClick(1)}
                        />
                    </div>


                    <div className="mk-appointments__content__table">



                        { // This for the labels which would be part of the grid
                            WEEKDAYS.map((value, index) => (
                                <p
                                    className='mk-appointments__content__table__label'
                                    style={{ gridColumn: `${index + 1} / span 1` }}
                                >
                                    {value.slice(0, 1)}
                                </p>
                            ))
                        }
                        {
                            allCalenderData.map((x, i) => (
                                <div
                                    className='mk-appointments__content__table__item__container'
                                    style={{
                                        gridColumn: `${x.column} / span 1`,
                                        gridRow: `${x.row} / span 1`,
                                        backgroundColor: selectedDay === x.dayInMonth ? '#44949c' : '',
                                        color: selectedDay === x.dayInMonth ? 'white' : '',
                                    }}
                                    onClick={() => {
                                        setDay(x.dayInMonth)
                                        // console.log(x)
                                    }
                                    }
                                >
                                    <p
                                        className='mk-appointments__content__table__item'
                                    >
                                        {!_.isEmpty(x) ? `${x.dayInMonth}` : ''}

                                    </p>
                                    {
                                        selectedDay === x.dayInMonth && (

                                            <p className='mk-appointments__content__table__item__weekday'>
                                                {!_.isEmpty(x) ? `${WEEKDAYS[x.dayInWeek - 1]}` : ''}
                                            </p>

                                        )
                                    }



                                </div>
                            ))
                        }

                    </div>
                </div>

                <div className="mk-appointments__content__time">

                    <div className="mk-appointments__content__time__hr">
                        <div className="mk-appointments__content__time__hr__header">

                            <p>Select Hour</p>


                        </div>

                        <div className="mk-appointments__content__time__hr__container">
                            {
                                WORKHOURS.map((x, i) => (

                                    <div
                                        className="mk-appointments__content__time__hr__item"
                                        style={WORKHOURS[i] === selectedTimeHr ? {
                                            backgroundColor: '#44949c',
                                            color: 'white',
                                            fontWeight: 'bold',
                                        } : {}}
                                        onClick={() => setSelectedTimeHr(WORKHOURS[i])}
                                    >
                                        {x}
                                    </div>

                                ))
                            }
                        </div>


                    </div>


                    <div className="mk-appointments__content__time__min">
                        <div className="mk-appointments__content__time__min__header">

                            <p>Select Minute</p>


                        </div>

                        <div className="mk-appointments__content__time__min__container">
                            {
                                WORKMINS.map((x, i) => (

                                    <div
                                        className="mk-appointments__content__time__min__item"
                                        style={WORKMINS[i] === selectedTimeMin ? {
                                            backgroundColor: '#44949c',
                                            color: 'white',
                                            fontWeight: 'bold',
                                        } : {}}
                                        onClick={() => setSelectedTimeMin(WORKMINS[i])}
                                    >
                                        {x}
                                    </div>

                                ))
                            }
                        </div>



                    </div>

                </div>

                <div className="mk-appointments__content__view">
                    <div className="mk-appointments__content__view__caution">
                        <AiOutlineExclamationCircle className='mk-appointments__content__view__caution__icon' />
                        <span>Dates and Times Reflect Your Current Computer</span>
                    </div>

                    <div className="mk-appointments__content__view__selected">
                        <img src={Booking} alt="Selected Date Icon" className="mk-appointments__content__view__selected__icon" />
                        <span>
                            {new Date(currentYear, currentMonth, selectedDay).toDateString()} {selectedTimeHr.split(':')[0]}:{selectedTimeMin} {selectedTimeHr.slice(-2)}
                        </span>
                    </div>
                </div>

            </div>



            <div
                className="mk-appointments__content__btnsection"
                style={showSpinner ? { display: 'none', width: '0' } : {}}
            >
                <button
                    className='btn mk-appointments__content__btn'
                    onClick={() => setShowModal(true)}
                >
                    {location.state.selected.data.HasAppointment ? 'Reschedule Appointment' : 'Book Appointment'}
                </button>
                <button
                    className="btn mk-appointments__content__btn"
                    onClick={() => navigate(-1)}
                >
                    Back
                </button>
            </div>

            <Footer />
        </div>
    )
}

export default Appointments